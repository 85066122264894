import type { SurveyOptionType } from 'lib/surveyQuestions';

import { Dispatch, SetStateAction, useState, useMemo } from 'react';

import dynamic from 'next/dynamic';
import { Controller, useFormContext } from 'react-hook-form';

import { Icon, keyframes, styled } from '@parsec/components';

const Dropdown = dynamic(() => import('../../../components/src/Dropdown'), {
  ssr: false
});

//STYLES
const FadeInRightAnimation = keyframes({
  '0%': {
    transform: 'translateX(5rem)',
    opacity: 0
  },
  '100%': {
    transform: 'translateX(0)',
    opacity: 1
  }
});

const HighlightDropdownColorChange = keyframes({
  '0%': {
    color: '$consoleWhite',
    boxShadow: '0 0.1rem 0 rgba(255,255,255, 0.1)',
    border: '0.2rem solid transparent'
  },
  '100%': {
    color: '$warning500',
    boxShadow: '0 0.1rem 0 rgba(255,255,255, 0)',
    border: '0.2rem solid $colors$warning500'
  }
});

const HighlightIconColorChange = keyframes({
  '0%': {
    color: '$rhyhorn'
  },
  '100%': {
    color: '$warning500'
  }
});

const DropdownAnimation = styled('div', {
  animation: `${FadeInRightAnimation} 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`
});

const DropdownTrigger = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  height: '3.6rem',
  fontSize: '1.4rem',
  lineHeight: '$attribution',
  borderRadius: '$medium',
  color: '$consoleWhite',
  padding: '0.6rem 1rem',
  cursor: 'pointer',
  width: '40rem',
  background: '$cereza',
  boxShadow: '0 0.1rem 0 rgba(255,255,255, 0.1)',
  border: '0.2rem solid transparent',

  variants: {
    highlighted: {
      true: {
        color: '$warning500',
        boxShadow: 'inset 0 0 0 0.2rem $colors$warning500',
        animation: `${HighlightDropdownColorChange} 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  color: '$rhyhorn',
  variants: {
    highlighted: {
      true: {
        color: '$warning500',
        animation: `${HighlightIconColorChange} 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`
      }
    }
  }
});

// TYPES
interface SurveyDropdownProps {
  name: string;
  defaultValue: string;
  options: SurveyOptionType[];
  setBtnDisabled?: Dispatch<SetStateAction<boolean>>;
  boundaryElement: Element | null;
  onOptionSelect?: () => void;
  isHighlighted?: boolean;
}

// COMPONENTS
const SurveyDropdown = (props: SurveyDropdownProps) => {
  const {
    name,
    defaultValue,
    options,
    setBtnDisabled,
    boundaryElement,
    onOptionSelect,
    isHighlighted
  } = props;

  const [selected, setSelected] = useState(defaultValue);

  const { control, setValue } = useFormContext();

  const optionItems = useMemo(() => {
    return options.map(option => {
      const { text, value } = option;
      return {
        value,
        text,
        onSelect() {
          setSelected(text);
          setValue(name, value);
          setBtnDisabled?.(false);
          onOptionSelect?.();
        }
      };
    });
  }, [options, name, setValue, setBtnDisabled, onOptionSelect]);

  return (
    <DropdownAnimation>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Dropdown
            showSearchBar
            defaultValue={selected}
            items={optionItems}
            css={{ zIndex: 1, width: '40rem' }}
            boundaryElement={boundaryElement as Element}
            toggleOnScroll={false}
            {...field}
          >
            {({ props }) => {
              return (
                <DropdownTrigger {...props} highlighted={isHighlighted}>
                  {selected}
                  <StyledIcon name="caret" highlighted={isHighlighted} />
                </DropdownTrigger>
              );
            }}
          </Dropdown>
        )}
      />
    </DropdownAnimation>
  );
};

export default SurveyDropdown;
