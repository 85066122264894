import {
  JourneyName,
  JourneyGroup,
  JourneyType,
  StepName,
  StepNum,
  JourneyByStep,
  JourneyTriggerType
} from './journey-types';

type LoginEvent = {
  event: 'userEvent';
  event_name: 'login_action';
  properties: {
    login_action: 'login_web' | 'user_create';
    login_method: 'email' | 'sso' | 'google' | 'apple' | 'facebook';
  };
};

interface JourneyEvent<T extends JourneyGroup> {
  event: 'userEvent';
  event_name: 'journey';
  properties: {
    journey_type: JourneyType;
    journey_name: JourneyName;
    journey_group: T;
    journey_step_number: StepNum<T>;
    journey_step_name: StepName<T, StepNum<T>>;
    journey_conversion: boolean;
    journey_step_message?: string;
    journey_trigger?: JourneyTriggerType;
  };
}

export type UserEvent<T> = T extends JourneyGroup
  ? JourneyEvent<T>
  : LoginEvent;

interface GenJourneyProps<T extends JourneyGroup> {
  journeyName?: JourneyName;
  group: T;
  step: StepNum<T>;
  isConversion?: boolean; // only pass in if true
  stepMsg?: string; // error|failure events
  trigger?: JourneyTriggerType;
}

/**
 * Helper to generate journey dataLayer events
 * @param type
 */
export function genJourney<T extends JourneyGroup>({
  journeyName = 'signup 2.0',
  group,
  step,
  isConversion = false,
  stepMsg,
  trigger
}: GenJourneyProps<T>): JourneyEvent<T> {
  const journeyEvent: JourneyEvent<T> = {
    event: 'userEvent',
    event_name: 'journey',
    properties: {
      journey_type: 'signup',
      journey_name: journeyName,
      journey_group: group,
      journey_step_name: JourneyByStep[group][step],
      journey_step_number: step,
      journey_conversion: isConversion
    }
  };

  // So we don't get any `undefined` values in our datalayer
  if (stepMsg) {
    journeyEvent.properties['journey_step_message'] = stepMsg;
  }
  if (trigger) {
    journeyEvent.properties['journey_trigger'] = trigger;
  }

  return journeyEvent;
}
