import { ReactNode, createContext, useContext } from 'react';

import { CSS, styled } from '@parsec/styles';

import { priceFormatCents } from '../../utils';
import BaseIcon from '../Icon';

interface BillingPeriodCtxProps {
  term: 'month' | 'year';
  isDowngrading: boolean;
}

const BillingPeriodContext = createContext<BillingPeriodCtxProps | undefined>({
  term: 'month',
  isDowngrading: false
});

export const useBillingPeriodContext = () => {
  const context = useContext(BillingPeriodContext);
  if (!context) {
    throw new Error(
      'useBillingPeriodContext must be used within a <BillingPeriod.Provider>'
    );
  }
  return context;
};

const Container = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  gap: '$xxlarge',
  alignContent: 'center'
});

const BillingWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridRowGap: '$xxlarge',
  backgroundColor: '$carkol',
  padding: '$xlarge',
  borderRadius: '$large',
  maxWidth: '94rem'
});

interface BillingPeriodProps extends BillingPeriodCtxProps {
  children?: ReactNode;
}

export function BillingPeriod({
  children,
  term,
  isDowngrading
}: BillingPeriodProps) {
  return (
    <BillingPeriodContext.Provider value={{ term, isDowngrading }}>
      <BillingWrapper>{children}</BillingWrapper>
    </BillingPeriodContext.Provider>
  );
}

/** SEAT PRICE COMPONENT */

const Image = styled('img', { maxHeight: '6.4rem' });

const PriceDiv = styled('div', {
  color: '#FFF', // no token
  fontFamily: '$newDefault',
  fontSize: '4.2rem',

  fontWeight: '$bold',
  lineHeight: '$attribution'
});

const TermWrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  fontFamily: '$newDefault'
});

const PeriodSpan = styled('span', {
  fontFamily: '$newDefault',
  color: '$consoleWhite',
  fontSize: '1.4rem',
  fontWeight: '$bold',
  lineHeight: '$attribution'
});

const PerMonthSpan = styled('span', {
  fontFamily: '$newDefault',
  color: '$rhyhorn',
  fontSize: '1.4rem',
  fontWeight: '$normal',
  lineHeight: '$attribution'
});

const infoBoxStyles: CSS = {
  fontFamily: '$newDefault',
  backgroundColor: '$chandelure',
  borderRadius: '$large',
  fontSize: '1.4rem'
};

const InfoWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  width: '43.9rem',
  gap: '$large',
  alignItems: 'center',
  padding: '$xxlarge',
  ...infoBoxStyles
});

interface SeatPriceProps {
  priceCents: number;
  imgSrc: string;
}

function SeatPrice({ priceCents, imgSrc }: SeatPriceProps) {
  const { term } = useBillingPeriodContext();

  return (
    <InfoWrapper>
      <Image src={imgSrc} />
      <PriceDiv>{priceFormatCents(priceCents)}</PriceDiv>
      <TermWrapper>
        <PeriodSpan>{term === 'month' ? 'Monthly' : 'Annual'}</PeriodSpan>
        <PerMonthSpan>/seat per month</PerMonthSpan>
      </TermWrapper>
    </InfoWrapper>
  );
}

/** SAVE NOW COMPONENT */

const Icon = styled(BaseIcon, {
  color: '$warning500',
  height: '1.2rem',
  width: '1.2rem',
  variants: {
    red: {
      true: {
        color: '$error500'
      }
    }
  }
});

const SaveDiv = styled('div', {
  display: 'flex',
  gap: '$medium',
  alignItems: 'center',
  borderRadius: '$small',
  border: '1px solid  $pancham',
  padding: '$medium 1rem',
  // font styling
  color: '$consoleWhite',
  fontFamily: '$newDefault',
  lineHeight: '$info',
  fontSize: '1rem'
});

const BoldSpan = styled('span', {
  fontWeight: '$bold'
});

function SaveNow({ savings }: { savings: string }) {
  const { term, isDowngrading } = useBillingPeriodContext();

  if (isDowngrading && term === 'year') {
    return (
      <SaveDiv>
        <Icon name="warning" red />
        <BoldSpan>You’re switching to monthly soon</BoldSpan>
        <span>Cancel to keep your annual savings.</span>
      </SaveDiv>
    );
  }

  return term === 'year' ? (
    <SaveDiv>
      <Icon name="success" css={{ color: '$success500' }} />
      <BoldSpan>Nice!</BoldSpan>
      <span>You’re saving {savings} a year by paying annually.</span>
    </SaveDiv>
  ) : (
    <SaveDiv>
      <Icon name="tag" />
      <BoldSpan>Save 14%</BoldSpan>
      <span>Upgrade to annual today</span>
    </SaveDiv>
  );
}

/** SAVE FEATURES LIST */

const InfoFeatWrapper = styled('div', {
  padding: '$large',
  width: '44.3rem',

  ...infoBoxStyles
});

const FeatList = styled('ul', {
  padding: '0 $large',
  listStyleType: 'disc',
  lineHeight: '$attribution',
  marginLeft: '0.5rem' // so marker aligns with the blurb a little better
});

interface InfoFeatProps {
  children: ReactNode;
}

function InfoFeatures({ children }: InfoFeatProps) {
  return (
    <InfoFeatWrapper>
      <FeatList>{children}</FeatList>
    </InfoFeatWrapper>
  );
}

BillingPeriod.SeatPrice = SeatPrice;
BillingPeriod.SaveNow = SaveNow;
BillingPeriod.InfoFeatures = InfoFeatures;
BillingPeriod.Container = Container;
