import { useMemo } from 'react';

//Components
import { styled, Button, Time } from '@parsec/components';
import { ELOQUA_LINKS } from '@parsec/constants';
import { useModals, useTeamTrialDate } from '@parsec/hooks';
import {
  useGetAllRoles,
  useGetTeamMember,
  useGetTeamRolePermissionSummary
} from '@parsec/queries';

import { TEAMS_URL } from 'lib/config';

import LeaveTeamModal from './LeaveTeamModal';
//Kessel

interface Props {
  teamName: string;
  onLeaveTeam(): void;
  isEnterprise: boolean;
}

export default function YourTeam(props: Props) {
  const { teamName, onLeaveTeam, isEnterprise } = props;
  const trialData = useTeamTrialDate();
  const memberInfo = useGetTeamMember();
  const teamRoles = useGetAllRoles();

  const modals = useModals({
    leaveModal: LeaveTeamModal
  });

  const permissions = useGetTeamRolePermissionSummary();

  const isSuperAdmin = useMemo(() => {
    const roleIds = new Set(memberInfo.data?.role_ids);

    const superRole = 'Super Admin' || 'team owner';
    const roles = teamRoles.data?.data ?? [];
    for (const role of roles) {
      // Presumably, a user can leave their team as long as they don't have the Super Admin role
      if (role.system_role && role.name === superRole) {
        return roleIds.has(role.id);
      }
    }
    return false;
  }, [teamRoles.data?.data, memberInfo.data?.role_ids]);

  const isScimUser = memberInfo.data?.is_scim;

  const canLeaveTeam = useMemo(() => {
    if (teamRoles.status === 'loading' || memberInfo.status === 'loading') {
      return false;
    } else if (teamRoles.status === 'success' && isSuperAdmin) {
      return false;
    } else {
      // If SCIM user, disallow leaving the team
      return !isScimUser;
    }
  }, [isSuperAdmin, teamRoles.status, memberInfo.status, isScimUser]);

  const hasAdminPanelAccess = useMemo(() => {
    if (!permissions.data) return false;

    const hasAnyTeamPerms = Object.values(permissions.data.team ?? {}).some(
      Boolean
    );

    const hasAnyGroupPerms =
      Object.keys(permissions.data.groups ?? {}).length > 0;

    return hasAnyTeamPerms || hasAnyGroupPerms;
  }, [permissions.data]);

  return (
    <HasTeamWrapper>
      {modals.dom}
      <div>
        <HasTeamTitle trial={!!trialData}>
          {trialData
            ? 'Welcome to Parsec for Teams'
            : "You've got Parsec for Teams"}
        </HasTeamTitle>
        {trialData && hasAdminPanelAccess && (
          <Expiration>
            Trial expires in{' '}
            <strong>
              {trialData.daysLeft} Days (
              <Time date={trialData.endDate} />)
            </strong>
          </Expiration>
        )}
      </div>
      <Content>
        <p>
          Your team name: <TeamName>{teamName}</TeamName>
        </p>

        {canLeaveTeam && (
          <CanLeave>
            <span>
              You can leave <TeamName>{teamName}</TeamName> at any time.
            </span>

            <LeaveButton
              level="secondary"
              onClick={() =>
                modals.open('leaveModal', { leaveTeam: onLeaveTeam })
              }
            >
              Leave Team
            </LeaveButton>
          </CanLeave>
        )}
        {hasAdminPanelAccess && (
          <>
            <p>
              Head to the Admin Panel to manage your team&apos;s computers,
              <br /> members, connection rules, app settings, and more.
            </p>

            {isEnterprise ? null : (
              <Upsell>
                <GetEnterprise>Need an Enterprise plan?</GetEnterprise>
                <StyledLink href={ELOQUA_LINKS.CONTACT_US} target="_blank">
                  Talk to our Sales Team
                </StyledLink>
              </Upsell>
            )}

            <ButtonBox>
              <Button href={TEAMS_URL}>Go to Admin Panel</Button>
            </ButtonBox>
          </>
        )}
      </Content>
    </HasTeamWrapper>
  );
}

const LeaveButton = styled(Button, {
  color: '$error500',
  display: 'inline-block'
});

const Content = styled('div', {
  display: 'grid',
  gap: '$large',
  justifyItems: 'left'
});

const HasTeamWrapper = styled('div', {
  display: 'grid',
  color: '$consoleWhite',
  padding: '3.6rem $xxlarge 4.1rem',
  gap: '$large',
  height: 'fit-content'
});

const HasTeamTitle = styled('p', {
  fontSize: '$header',
  lineHeight: '$header',
  variants: {
    trial: {
      true: { color: '$consoleWhite', fontWeight: 'bold' },
      false: { color: '$rhyhorn' }
    }
  }
});

const TeamName = styled('span', {
  textTransform: 'uppercase'
});

const CanLeave = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  width: 'fit-content',
  gap: '$large'
});

const GetEnterprise = styled('p', {
  fontSize: '1rem',
  lineHeight: '1.6rem',
  color: '$rhyhorn'
});

const StyledLink = styled('a', {
  fontWeight: 'bold',
  color: '$primary500',
  display: 'grid',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '$info',
  lineHeight: '$info'
});

const Upsell = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  width: 'fit-content',
  justifyContent: 'center',
  gap: '$small'
});

const ButtonBox = styled('div', {
  width: '26rem',
  display: 'grid'
});

const Expiration = styled('p', {
  fontSize: '1.4rem',
  color: '#FFAD11', //no token,
  lineHeight: '$info'
});
