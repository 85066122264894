//Kessel

// Components
import { useMemo } from 'react';

import { Time, styled, Icon, keyframes, Bread } from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import {
  WarpInterval,
  WarpSubscription,
  WarpPlanID,
  Estimate
} from '@parsec/kessel';
import { parseError } from '@parsec/request';

interface ConfirmationFormProps {
  subscription?: WarpSubscription;
  estimate?: Estimate;
  selectedPlanId: string;
  isResume?: boolean;
  coupon?: string;
  onConfirm(): Promise<void>;
  onSubscribe(): void;
  onError(error: string): void;
}

export default function ConfirmationForm(props: ConfirmationFormProps) {
  const {
    subscription,
    estimate,
    selectedPlanId,
    isResume = false,
    coupon,
    onSubscribe,
    onConfirm,
    onError
  } = props;

  const renewalDate = useMemo(() => {
    return subscription?.status === 'in_trial'
      ? new Date(subscription?.trial_end ?? Date.now())
      : isResume
        ? new Date(subscription?.current_term_end ?? Date.now())
        : selectedPlanId === WarpPlanID.Yearly
          ? addYear(new Date(), 1)
          : addMonth(new Date(), 1);
  }, [isResume, selectedPlanId, subscription]);

  const unitPrice = estimate?.line_items[0].amount_cents ?? NaN;
  const subtotal = estimate?.subtotal_amount_cents ?? NaN;
  const discount = estimate?.discount_amount_cents ?? NaN;
  const preTax = subtotal - discount;
  const tax = estimate?.tax_amount_cents ?? NaN;
  const total = estimate?.total_amount_cents ?? NaN;

  const hasDiscount = discount > 0;

  const selectedInterval =
    selectedPlanId === WarpPlanID.Yearly
      ? WarpInterval.Yearly
      : WarpInterval.Monthly;

  const loadingEstimate = !estimate;

  const trial = !subscription || subscription.status === 'in_trial';

  const currentTime = new Date();
  const twoWeeks = new Date(currentTime.setDate(currentTime.getDate() + 14));

  return (
    <>
      <Section>
        <Subtotal>
          Subtotal
          <Value css={{ gridColumnStart: 4 }}>
            {hasDiscount && (
              <DiscountedCss>
                <Bread>{unitPrice}</Bread>
              </DiscountedCss>
            )}
            <Bread>{preTax}</Bread>
          </Value>
        </Subtotal>
        <Subtotal>
          Tax
          {loadingEstimate ? (
            <Value
              css={{
                position: 'relative',
                gridColumnStart: 4
              }}
            >
              <Loader name="loader" />
            </Value>
          ) : (
            <Value css={{ gridColumnStart: 4 }}>
              <Bread>{tax}</Bread>
            </Value>
          )}
        </Subtotal>
        {coupon ? (
          <SummaryItem border>
            Coupon Code
            <CouponCode>{coupon}</CouponCode>
          </SummaryItem>
        ) : null}
        <SummaryItem border={!coupon}>
          Grand Total
          {trial || isResume ? (
            <SubText>
              {selectedInterval}ly total (billed starting{' '}
              <strong>
                <Time date={trial ? twoWeeks : renewalDate} />
              </strong>
              )
            </SubText>
          ) : (
            <Value green strong css={{ gridColumnStart: 3 }}>
              billed today
            </Value>
          )}
          {loadingEstimate ? (
            <Value css={{ position: 'relative', gridColumnStart: 4 }}>
              <Loader name="loader" />
            </Value>
          ) : (
            <Value css={{ gridColumnStart: 4 }} strong={!trial}>
              <Bread>{total}</Bread>
            </Value>
          )}
        </SummaryItem>

        {trial && (
          <SummaryItem>
            <Value green strong css={{ gridColumnStart: 3 }}>
              billed today
            </Value>
            <Total css={{ gridColumnStart: 4 }}>
              <Bread>{0}</Bread>
            </Total>
          </SummaryItem>
        )}

        {trial && (
          <TrialDisclaimer>
            You will be charged upon trial expiration{' '}
            <strong>
              (
              <Time date={twoWeeks} />)
            </strong>
          </TrialDisclaimer>
        )}
      </Section>

      <Form
        id="subscription_confirmation_form"
        method="post"
        onSubmit={handleFormSubmit(async () => {
          try {
            onError('');
            await onConfirm();
            onSubscribe();
          } catch (err) {
            const error = parseError(err);
            onError(
              error.error || 'Could not subscribe to Warp. Please try again.'
            );
          }
        })}
      >
        <Blurb>
          {trial ? (
            <>
              You are purchasing a recurring subscription beginning{' '}
              <strong>
                <Time date={twoWeeks} />.
              </strong>{' '}
              We will charge you once your trial period ends
            </>
          ) : (
            <>
              <strong>This is a non-refundable purchase.</strong> You are
              purchasing a recurring subscription renewing{' '}
              <strong>
                <Time date={new Date(renewalDate)} />.
              </strong>{' '}
              We will charge you{' '}
              {isResume ? 'at the end of your current subscription' : 'today'}
            </>
          )}
          <>
            {' '}
            and we will keep charging you {selectedInterval}ly until you cancel
            the subscription. You can cancel at any time. Have questions?{' '}
          </>
          <a
            href="https://support.parsec.app"
            target="_blank"
            rel="noreferrer noopener"
          >
            Contact our support team
          </a>
          .
        </Blurb>
      </Form>
    </>
  );
}

const Section = styled('div', {
  padding: '$xxlarge $xxlarge',
  background: '#2c2c2d',
  display: 'grid'
});

const DiscountedCss = styled('span', {
  textDecoration: 'line-through',
  color: `#f9fafc26`, //no token
  marginRight: '$small'
});

const Form = styled('form', {
  backgroundColor: '$carkol',
  padding: '$xxlarge'
});

function cloneDate(date: Date) {
  return new Date(date.getTime());
}

function addMonth(date: Date, months: number) {
  const cloned = cloneDate(date);
  return new Date(cloned.setMonth(cloned.getMonth() + months));
}

function addYear(date: Date, years: number) {
  const cloned = cloneDate(date);
  return new Date(cloned.setFullYear(cloned.getFullYear() + years));
}

const Subtotal = styled('span', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '$xlarge'
});

const spin = keyframes({
  '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
  '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' }
});

const Loader = styled(Icon, {
  position: 'absolute',
  top: '0',
  right: '50%',
  animation: `${spin} linear 2s infinite`,
  fontSize: '$header'
});

const SummaryItem = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'max-content auto max-content 7rem',
  alignItems: 'center',
  justifyContent: 'unset',
  columnGap: '$xxxlarge',
  marginBottom: '$xlarge',

  variants: {
    border: {
      true: {
        boxShadow: 'inset 0 0.1rem 0 $colors$pancham',
        paddingTop: '$xxlarge'
      }
    }
  }
});

const Total = styled('strong', {
  fontSize: '$subtitle',
  gridColumnStart: 4,
  textAlign: 'right'
});

const TrialDisclaimer = styled('span', {
  color: '$rhyhorn',
  boxShadow: 'inset 0 0.1rem 0 $colors$pancham',
  paddingTop: '$xlarge'
});

const Value = styled('span', {
  textAlign: 'right',
  variants: {
    strong: {
      true: {
        fontWeight: 'bold'
      }
    },
    green: {
      true: {
        color: '$success500'
      }
    }
  }
});

const SubText = styled('span', {
  color: '$rhyhorn',
  gridColumnStart: 3,
  textAlign: 'right',
  display: 'none',
  '@medium': {
    display: 'block'
  }
});

const Blurb = styled('p', {
  fontSize: '$info',
  marginTop: '$xlarge',
  variants: {
    faded: {
      true: {
        color: '$rhyhorn'
      },
      false: {}
    }
  }
});

const CouponCode = styled('p', {
  color: '$success500',
  gridColumnStart: '3'
});
