import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Coupon as BaseCoupon, Input } from '@parsec/components';

// Input captures the fields available in the Coupon component
export interface CouponData {
  coupon: string;
}

interface CouponProps {
  defaultValue?: string;
  apply(coupon: string): void;
  isValid: boolean;
  errorMsg?: string;
}

export function Coupon(props: CouponProps) {
  const { defaultValue, isValid, errorMsg, apply } = props;
  const { register, setValue } = useFormContext();
  const [showMessage, setShowMessage] = useState(false);
  const hasError = Boolean(errorMsg);

  useEffect(() => {
    setShowMessage(false);
  }, []);

  const successMessage =
    showMessage && isValid ? 'Coupon code successfully applied!' : '';

  const failureMessage =
    showMessage && hasError ? 'This coupon code is invalid.' : '';

  useEffect(() => {
    setValue('coupon', isValid ? defaultValue : '');
  }, [isValid, defaultValue, setValue]);

  return (
    <div>
      <BaseCoupon
        defaultValue={defaultValue ?? ''}
        apply={coupon => {
          apply(coupon);
          setShowMessage(true);
        }}
        successMsg={successMessage}
        errorMsg={failureMessage}
      />
      <Input {...register('coupon')} hidden />
    </div>
  );
}
