import { styled } from '@parsec/components';

export default styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    layout: {
      center: { justifyContent: 'center' },
      spread: { justifyContent: 'space-between' }
    },
    spacer: {
      top: { '&::before': { content: '""' } },
      bottom: { '&::after': { content: '""' } },
      both: { '&::before': { content: '""' }, '&::after': { content: '""' } }
    }
  }
});
