import { FormProvider, useForm } from 'react-hook-form';

// @parsec
import {
  styled,
  CredentialForm,
  ErrorMessage,
  Modal,
  TfaBackupCodes
} from '@parsec/components';
import { useResetTFABackupCodes } from '@parsec/queries';

interface Props {
  showTfa?: boolean;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

interface FormValues {
  password: string;
  tfa?: string;
}

export default function BackupCodesModal(props: Props) {
  const { showTfa, isOpen, onClose, onAfterClose } = props;

  const resetTFABackupCodes = useResetTFABackupCodes(),
    codes = resetTFABackupCodes.data?.map(code => code.code) || [];

  const formMethods = useForm<FormValues>();

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods;

  const onSubmit = handleSubmit(async values => {
    try {
      await resetTFABackupCodes.mutateAsync({
        password: values.password,
        tfa: values.tfa?.toString()
      });
    } catch (_error) {
      // noop, error captured on mutation result
    }
  });

  return (
    <FormProvider {...formMethods}>
      <Modal
        title="Display 2FA Backup Codes"
        isOpen={isOpen}
        onClose={onClose}
        onAfterClose={onAfterClose}
        actions={
          codes.length
            ? [
                {
                  text: 'Download Codes',
                  form: 'download_backup_codes'
                },
                {
                  text: 'Cancel',
                  level: 'secondary',
                  onClick: onClose
                }
              ]
            : [
                {
                  text: 'Display Codes',
                  form: 'reset_backup_codes',
                  disabled: isSubmitting
                },
                { text: 'Cancel', level: 'secondary', onClick: onClose }
              ]
        }
      >
        {codes.length ? (
          <TfaBackupCodes
            id="download_backup_codes"
            codes={codes}
            onDone={onClose}
          />
        ) : (
          <Form id="reset_backup_codes" onSubmit={onSubmit}>
            <p>
              Enter your password to display your two-factor authentication
              backup codes.
            </p>
            <p>
              Note: this will invalidate any previous backup codes you have
              saved.
            </p>

            <CredentialForm
              showTfaInput={
                showTfa ||
                resetTFABackupCodes.error?.codes.some(
                  code => code.type === 'tfa_incorrect'
                )
              }
            />

            {resetTFABackupCodes.error ? (
              <ErrorMessage>{resetTFABackupCodes.error.error}</ErrorMessage>
            ) : null}
          </Form>
        )}
      </Modal>
    </FormProvider>
  );
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});
