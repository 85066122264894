// libraries

// @parsec
import { styled, Modal } from '@parsec/components';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
  leaveTeam(): void;
}

export default function LeaveTeamModal(props: Props) {
  const { isOpen, onClose, onAfterClose, leaveTeam } = props;

  return (
    <Modal
      title="Leave Team"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      actions={[
        { text: 'Leave', kind: 'error', onClick: leaveTeam },
        { text: 'Cancel', level: 'secondary', onClick: onClose }
      ]}
    >
      <Important>Are you sure you want to leave your team?</Important>
      <FinePrint>
        Upon leaving you will be logged out and no longer able to access any
        team services. Don&apos;t worry! You&apos;ll still be able to log back
        in and continue to use our free service.
      </FinePrint>
    </Modal>
  );
}

const Important = styled('header', {
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold'
});

const FinePrint = styled('p', {
  lineHeight: '$body',
  fontSize: '$body',
  marginTop: '$xxlarge',
  display: 'grid',
  rowGap: '$xlarge'
});
