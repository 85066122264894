// libraries
import { useEffect } from 'react';

// @react
import { useCookie } from '@parsec/cookie';
import { runAuthRedirect, setAuthRedirect } from '@parsec/redirect';

/** Redirects the user away from the page they are on if they are logged in. Returns `true` if user is not authenticated */
export function useEnsuredLoggedOut() {
  const { token } = useCookie();
  useEffect(() => {
    // If logged in, use localStorage redirect
    if (token) runAuthRedirect('/');
  }, [token]);

  return !token;
}

/** Redirects the user away from the page they are on if they are logged out. Returns `true` if user is authenticated */
export function useEnsureLoggedIn() {
  const { token } = useCookie();

  useEffect(() => {
    // If logged out...
    if (!token) {
      if (typeof window !== 'undefined') {
        const pathname = window.location?.pathname ?? '',
          search = window.location?.search ?? '';

        setAuthRedirect(`${pathname}${search}`);
      }

      window.location.assign('/login');
    }
  }, [token]);

  return !!token;
}
