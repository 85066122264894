// libraries
import { useEffect, useMemo, useState } from 'react';

// @parsec
import { useForm } from 'react-hook-form';

import { useGetMe } from '@parsec/queries';
import { parseError, FailureRes } from '@parsec/request';
import { styled } from '@parsec/styles';

import ErrorMessage from './ErrorMessage';
import FieldLabel from './FieldLabel';
import Input from './Input';
import QrCode from './QrCode';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});

const Steps = styled('ol', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$xxxlarge',
  listStyleType: 'decimal',
  paddingLeft: '$xlarge'
});

const Step = styled('li', {
  fontWeight: 'bold'
});

const StepTitle = styled('h3', {
  marginBottom: '$medium'
});

const StepDescription = styled('p', {
  marginBottom: '$medium',
  fontWeight: 'normal'
});

const TfaCode = styled(QrCode, {
  float: 'right',
  marginLeft: '$xlarge'
});

const Inputs = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  columnGap: '$xlarge',
  rowGap: '$large'
});

const StyledFieldLabel = styled(FieldLabel, {
  variants: {
    columns: {
      1: { gridColumn: 1 },
      3: { gridColumn: '1 / 3' }
    }
  }
});

const Reassure = styled('p', {
  color: '$rhyhorn'
});

interface Props {
  id?: string;
  description?: string;
  password?: string;
  onSetup(): Promise<string>;
  onConfirm(code: string, password: string, email?: string): Promise<void>;
  onValidate(isFormValid: boolean): void;
}

interface FormValues {
  code: string;
  email: string;
  password: string;
}

function TfaConfirm(props: Props) {
  const me = useGetMe();

  const {
    description = 'Make your Parsec account more secure by requiring an extra authentication code when you log in.',
    password,
    onSetup,
    onConfirm,
    onValidate,
    id
  } = props;

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    criteriaMode: 'all'
  });

  const [uri, setUri] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [setupError, setSetupError] = useState<FailureRes<any> | null>(null);
  useEffect(() => {
    async function init() {
      try {
        const res = await onSetup();
        setUri(res);
      } catch (e) {
        const err = parseError(e, {
          error: "Couldn't set up two-factor authentication."
        });
        setSetupError(err);
      }
    }

    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const secret = useMemo(() => {
    if (!uri) return '';

    try {
      const url = new URL(uri);
      return new URLSearchParams(url.search).get('secret') ?? '';
    } catch (_e) {
      console.error(`${uri} is not a valid URL.`);
      return '';
    }
  }, [uri]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [enableError, setEnableError] = useState<FailureRes<any> | null>(null);
  const requirePassword = !password;

  const ERROR_NAME_EMAIL = 'email';

  function validateEmail(_: string, formValues: FormValues) {
    if (!formValues) return false;
    if (!me.data?.email) return true;
    if (formValues.email === me.data.email) {
      setError(ERROR_NAME_EMAIL, {
        type: 'custom',
        message: 'Recovery email must not be the same as primary account email'
      });
      onValidate(isValid);
      return false;
    }
    clearErrors(ERROR_NAME_EMAIL);
    onValidate(isValid);
    return true;
  }

  const onSubmit = handleSubmit(async (values: FormValues) => {
    try {
      await onConfirm(
        values.code,
        password || values.password || '',
        values.email
      );
    } catch (e) {
      const err = parseError(e, {
        error: "Couldn't confirm two-factor authentication."
      });
      setEnableError(err);
    }
  });

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  return (
    <Form onSubmit={onSubmit} id={id}>
      <p>{description}</p>
      {setupError ? (
        <ErrorMessage>{setupError.error}</ErrorMessage>
      ) : (
        <>
          <Steps>
            <Step>
              <StepTitle>Download an authenticator app</StepTitle>
              <StepDescription>
                Download an authenticator app such as{' '}
                <a
                  href="https://authy.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Authy
                </a>{' '}
                or{' '}
                <a
                  href="https://1password.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  1Password
                </a>{' '}
                on your phone.
              </StepDescription>
            </Step>
            <Step>
              <StepTitle>Scan the QR code</StepTitle>
              <TfaCode value={uri} label={secret} />
              <StepDescription>
                Open the authenticator app, scan the QR code to the right and
                enter the code that your app shows.
              </StepDescription>
              <Inputs>
                <StyledFieldLabel columns={requirePassword ? 1 : 3}>
                  <FieldLabel.Label required label="Code">
                    <Input
                      type="numeric"
                      autoFocus
                      {...register('code', {
                        required: 'You must enter a 2FA Code',
                        minLength: 6,
                        maxLength: 6
                      })}
                    />
                  </FieldLabel.Label>
                </StyledFieldLabel>

                {requirePassword && (
                  <StyledFieldLabel>
                    <FieldLabel.Label required label="Password">
                      <Input
                        type="password"
                        autoComplete="off"
                        {...register('password', {
                          required: 'You must enter your password'
                        })}
                      />
                    </FieldLabel.Label>
                  </StyledFieldLabel>
                )}
              </Inputs>
            </Step>
            <Step>
              <StepTitle>Enter a recovery email (optional)</StepTitle>
              <StepDescription>
                You can use this email to reset your 2FA. It must be different
                from your primary email.
              </StepDescription>
              <Inputs css={{ gridRow: 3 }}>
                <StyledFieldLabel columns={3}>
                  <FieldLabel.Label label="Recovery email">
                    <Input
                      type="email"
                      {...register('email', {
                        validate: validateEmail
                      })}
                    />
                  </FieldLabel.Label>
                </StyledFieldLabel>
              </Inputs>
            </Step>
          </Steps>

          <Reassure>
            You can change or remove 2FA at any time in the future.
          </Reassure>

          {enableError ? (
            <ErrorMessage>{enableError.error}</ErrorMessage>
          ) : null}

          {errors.email ? (
            <ErrorMessage>{errors.email.message}</ErrorMessage>
          ) : null}
        </>
      )}
    </Form>
  );
}
export default TfaConfirm;
