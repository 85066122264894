import type { UrlObject } from 'url';

import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

// Grabbed from next/router types, it's not exported tho
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

const useSafePush = () => {
  const [onChanging, setOnChanging] = useState(false);

  const handleRouteChange = () => {
    setOnChanging(false);
  };

  const router = useRouter();

  // safePush is used to avoid route pushing errors when users click multiple times or when the network is slow:  "Error: Abort fetching component for route"
  const safePush = useCallback(
    (
      path: UrlObject | string,
      as?: object | string,
      options?: TransitionOptions
    ) => {
      if (onChanging || !router.isReady) {
        return;
      }
      setOnChanging(true);
      router.push(path, as, options);
    },
    [onChanging, router]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setOnChanging]);

  return { safePush };
};

export default useSafePush;
