import { useCallback } from 'react';

// @parsec
import { styled, Icon } from '@parsec/components';

import { MARKETING_URL } from 'lib/config';

interface Props {
  color?: 'light' | 'dark';
}

export default function Footer(props: Props) {
  const { color = 'light', ...rest } = props;

  const toggleOneTrustPref = useCallback(() => {
    if (typeof window !== 'undefined') window?.OneTrust?.ToggleInfoDisplay();
  }, []);

  return (
    <Wrapper color={color} {...rest}>
      <li>
        <FooterLink href="https://support.parsec.app">
          <Icon name="help" />
          Help
        </FooterLink>
      </li>
      <li>
        <FooterLink href={`${MARKETING_URL}/terms`}>
          <Icon name="gavel" />
          Terms
        </FooterLink>
      </li>
      <li>
        <FooterLink href={`${MARKETING_URL}/privacy`}>
          <Icon name="lock" />
          Privacy
        </FooterLink>
      </li>
      <li>
        <FooterLink
          as="button"
          onClick={toggleOneTrustPref}
          css={{ cursor: 'pointer' }}
        >
          Cookie Settings
        </FooterLink>
      </li>
    </Wrapper>
  );
}

const Wrapper = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: '$xxlarge',
  justifyContent: 'center',
  paddingTop: '$xxlarge',
  variants: {
    color: {
      dark: { color: '$computerBlack' },
      light: { color: '$rhyhorn' }
    }
  }
});

const FooterLink = styled('a', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: '$small',
  alignItems: 'center',
  color: 'inherit'
});
