export enum CaptchaActions {
  Login = 'login',
  ForgotPassword = 'forgot_password',
  CreateTeamSendConfirmationEmail = 'create_team_send_confirm_email',
  SignUpSendConfirmationEmail = 'sign_up_send_confirm_email',
  PurchaseGuestAccess = 'purchase_guest_access',
  CreateAccessLinks = 'create_access_links',
  InviteTeamMember = 'invite_team_member',
  ResendInvite = 'resend_invite',
  SetPassword = 'set_password',
  ConfirmEmailCode = 'confirm_email_code',
  UpdateEmail = 'update_email',
  ConfirmUpdateEmail = 'confirm_update_email',
  ResetPassword = 'reset_password',
  ResetTFA = 'reset_tfa_email'
}
