// libraries
import Link from 'next/link';

// @parsec
import {
  styled,
  Button,
  Icon,
  Time,
  type VariantProps
} from '@parsec/components';
import { useTeamTrialDate, useWarpTrialDate } from '@parsec/hooks';
import { useGetTeam } from '@parsec/queries';

import { WarpLogo } from 'components';

import Teams from 'lib/images/header/teams.svg';
import warpStar from 'lib/images/header/warpStar.svg';

import background from 'lib/images/header/cardBackground.png';
import background2x from 'lib/images/header/cardBackground@2x.png';
import trialBackground from 'lib/images/header/trialCardBackground.png';

interface WarpPlan {
  isMonthly: boolean;
  termEnd: string | null;
  estimate: number;
  status: string;
  trial: boolean;
  cancelled_at?: string;
}

type TeamsPlan =
  | {
      isAdmin: true;
      isMonthly: boolean;
      termEnd: string | null;
      estimate: number;
      isTrial: boolean;
      status: string;
      isSelfServeTrial: boolean;
      isSelfServe: boolean;
    }
  | { isAdmin: false };

interface Props {
  className?: string;
  warp?: WarpPlan;
  teams?: TeamsPlan;
}

type WrapperType = VariantProps<typeof Wrapper>['type'];

const getWrapperType = ({
  teams,
  warp
}: {
  teams?: TeamsPlan;
  warp?: WarpPlan;
}): WrapperType => {
  if (teams) {
    return 'teams';
  } else if (warp) {
    return 'warp';
  } else {
    return 'free'; // default value
  }
};

export default function Plan(props: Props) {
  const { className, teams, warp } = props;
  const wrapperType = getWrapperType({ teams, warp });

  return (
    <Wrapper className={className} type={wrapperType}>
      {teams ? (
        <TeamsPlanCard plan={teams} />
      ) : warp ? (
        <WarpPlanCard plan={warp} />
      ) : (
        <FreePlanCard />
      )}
    </Wrapper>
  );
}

function FreePlanCard() {
  return (
    <InnerWrapper>
      <Intro>Your Parsec Plan</Intro>
      <FreeLogo>Free</FreeLogo>

      <Info>
        Get more out of Parsec! Better color, extra display options, pen and
        tablet support and more!
      </Info>

      <Actions>
        <Link href="/plan" passHref>
          <Button
            kind="primary"
            level="secondary"
            icon={<Icon name="star" />}
            target="_self"
          >
            Upgrade
          </Button>
        </Link>
      </Actions>
    </InnerWrapper>
  );
}

function WarpPlanCard(props: { plan: WarpPlan }) {
  const { plan } = props;

  const planEnding = plan.status === 'non_renewing';
  const planEnded = plan.status === 'cancelled';

  const trialData = useWarpTrialDate();

  const estimate = !isNaN(plan.estimate) ? plan.estimate : undefined;

  const willBeCharged = Boolean(!planEnding && !planEnded && estimate);

  const showDate = Boolean(plan.termEnd);

  return (
    <InnerWrapper>
      <Intro>Your Parsec Plan</Intro>
      <Title>
        <StyledWarpLogo />

        <Badge data-testid={plan.isMonthly ? 'monthlyBadge' : 'yearlyBadge'}>
          {plan.isMonthly ? 'Monthly' : 'Yearly'}
        </Badge>
        {trialData && (
          <Badge type="warpTrial" data-testid="trialBadge">
            Trial
          </Badge>
        )}
      </Title>

      <Info data-testid="planInfo">
        {trialData ? (
          <>
            Trial expires in <strong>{trialData.daysLeft} days</strong>
            {willBeCharged && showDate && (
              <>
                {' '}
                and will convert to a paid Warp account on{' '}
                <strong>
                  <Time date={new Date(plan.termEnd ?? NaN)} />
                </strong>
              </>
            )}
            .
          </>
        ) : showDate ? (
          <>
            {planEnding
              ? 'Ends on '
              : planEnded
                ? 'Ended on '
                : 'Automatically renews '}
            <Time date={new Date(plan.termEnd ?? NaN)} />.
          </>
        ) : null}

        {willBeCharged && (
          <>
            {' '}
            You will be charged <strong>${(estimate! / 100).toFixed(2)}</strong>
            .
          </>
        )}
      </Info>
      <Actions>
        <Link href="/plan" passHref>
          <Button kind="brand" level="secondary" target="_self">
            <WarpStarIcon />
            Switch to Teams
          </Button>
        </Link>
      </Actions>
    </InnerWrapper>
  );
}

function TeamsPlanCard(props: { plan: TeamsPlan }) {
  const { plan } = props;

  const planEnding = plan.isAdmin && plan.status === 'non_renewing';
  const planEnded = plan.isAdmin && plan.status === 'cancelled';
  const showDate = Boolean(plan.isAdmin && plan.termEnd);

  const canUpdateSubscription =
    (plan.isAdmin && plan.isSelfServe) ||
    (plan.isAdmin && plan.isSelfServeTrial);
  const trialData = useTeamTrialDate();
  const showTrial = trialData && plan.isAdmin;

  const estimate =
    canUpdateSubscription && !isNaN(plan.estimate) ? plan.estimate : null;

  const team = useGetTeam();

  const isEnterprise =
    team.data?.capabilities.features.is_relay_enabled ?? false;

  const enterpriseYearly = isEnterprise && plan.isAdmin && !plan.isMonthly;
  const willBeCharged = Boolean(!planEnding && !planEnded && estimate);

  return (
    <InnerWrapper>
      {!showTrial && <Intro>Your Parsec Plan</Intro>}
      <Title>
        <TeamsLogo />

        {plan.isAdmin && (
          <Badge
            data-testid={plan.isMonthly ? 'monthlyBadge' : 'yearlyBadge'}
            type="teamTrial"
          >
            {plan.isMonthly ? 'Monthly' : 'Yearly'}
          </Badge>
        )}

        {showTrial && (
          <Badge type="teamTrial" data-testid="trialBadge">
            Trial
          </Badge>
        )}
      </Title>
      {plan.isAdmin ? (
        <Info data-testid="planInfo">
          {trialData ? (
            <>
              {plan.isSelfServeTrial && planEnding
                ? 'Resume Trial to retain access to Parsec for Teams when your trial ends. '
                : 'Try out everything Parsec for Teams has to offer free for 14 days. '}

              <>
                Trial expires in <strong>{trialData.daysLeft} days</strong>{' '}
                {showDate && (
                  <>
                    (<Time date={new Date(plan.termEnd ?? NaN)} />)
                  </>
                )}
                {willBeCharged && (
                  <> and will convert to a paid Teams account</>
                )}
              </>
            </>
          ) : showDate ? (
            <>
              {planEnding
                ? 'Ends on '
                : planEnded
                  ? 'Ended on '
                  : 'Automatically renews '}
              <Time date={new Date(plan.termEnd ?? NaN)} />
            </>
          ) : null}
          .
          {willBeCharged && (
            <>
              {' '}
              You will be charged{' '}
              <strong>${(estimate! / 100).toFixed(2)}</strong>.
            </>
          )}
        </Info>
      ) : (
        <Info css={{ marginTop: '5.2rem' }}>
          Need help? Contact your admin for assistance.
        </Info>
      )}

      {/* important to note: self serve trial and regular self serve are not the same property.  */}
      {!canUpdateSubscription || enterpriseYearly ? null : (
        <Actions>
          <Link href="/plan" passHref>
            <Button
              kind={showTrial ? 'dark' : 'primary'}
              level={showTrial ? undefined : 'secondary'}
              icon={<Icon name="star" />}
              target="_self"
            >
              {plan.isSelfServeTrial && planEnded
                ? 'Resubscribe'
                : showTrial
                  ? 'Manage Subscription'
                  : 'Upgrade'}
            </Button>
          </Link>
        </Actions>
      )}
    </InnerWrapper>
  );
}

const Wrapper = styled('div', {
  position: 'relative',

  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '0.2rem',
    bottom: '0.2rem',
    left: '0.2rem',
    right: '0.2rem',
    pointerEvents: 'none',
    backgroundSize: '29.6rem 14.9rem',
    mixBlendMode: 'overlay',
    zIndex: 0
  },
  variants: {
    type: {
      warp: {
        boxShadow: 'inset 0 0 0 0.2rem $colors$error500',
        background: `url(${background.src}) bottom center no-repeat`,
        '@retina': { backgroundImage: `url(${background2x.src})` },
        '&::after': {
          mixBlendMode: 'normal',
          opacity: 0.3
        }
      },

      teams: {
        boxShadow: 'inset 0 0 0 0.2rem #FFAD11',
        color: '$ultraDark',
        background: `url(${trialBackground.src}) bottom center no-repeat #FFAD11`
      },
      free: {
        boxShadow: 'inset 0 0 0 0.2rem $colors$primary500',
        background: `$primary500`
      }
    }
  }
});

const InnerWrapper = styled('div', {
  position: 'relative',
  padding: '$xlarge',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column'
});

const Intro = styled('span', {
  display: 'block',
  fontWeight: 'bold',
  fontFamily: '$heading'
});

const Title = styled('div', {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  columnGap: '$medium'
});

const FreeLogo = styled('span', {
  display: 'block',
  fontWeight: 'bold',
  fontStyle: 'italic',
  fontFamily: '$heading',
  textTransform: 'uppercase',
  fontSize: '2.7rem',
  lineHeight: '3.2rem'
});

const StyledWarpLogo = styled(WarpLogo, {
  display: 'block',
  height: '2rem',
  margin: '0.8rem 0',
  width: 'auto'
});

const WarpStarIcon = styled(warpStar, {
  display: 'inline-block',
  width: '1.4rem',
  fill: '$error500',
  marginRight: '$small'
});

const TeamsLogo = styled(Teams, {
  display: 'block',
  height: '2rem',
  margin: '0.8rem 0'
});

const Badge = styled('span', {
  display: 'block',
  fontSize: '$info',
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  height: '2rem',
  lineHeight: '2rem',
  borderRadius: '2rem',
  padding: '0 $medium',
  color: '$ultraDark',
  backgroundColor: '#ffca67', //no token
  variants: {
    type: {
      warpTrial: {
        backgroundColor: 'transparent',
        border: '.1rem solid $error500',
        color: '$error500'
      },
      teamTrial: {
        backgroundColor: 'transparent',
        border: '.1rem solid'
      }
    }
  }
});

const Info = styled('p', {
  fontSize: '$info',
  lineHeight: '$info',
  marginBottom: '$medium'
});

const Actions = styled('footer', {
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'row-reverse'
});
