// libraries
import { FormProvider, useForm } from 'react-hook-form';

// @parsec
import {
  styled,
  CredentialForm,
  ErrorMessage,
  Modal
} from '@parsec/components';
import { useDisableTFA } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});

interface DisableTfaModalProps {
  showTfa?: boolean;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

interface FormValues {
  password: string;
  tfa?: string;
}

export default function DisableTfaModal(props: DisableTfaModalProps) {
  const { showTfa, isOpen, onClose, onAfterClose } = props;

  const disableTFA = useDisableTFA();
  const formMethods = useForm<FormValues>();

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods;

  const onSubmit = handleSubmit(async values => {
    try {
      await disableTFA.mutateAsync({
        password: values.password,
        tfa: values.tfa?.toString()
      });
      onClose();
    } catch (_error) {
      // noop, error captured on mutation result
    }
  });

  return (
    <FormProvider {...formMethods}>
      <Modal
        title="Disable Two-Factor Authentication"
        isOpen={isOpen}
        onClose={onClose}
        onAfterClose={onAfterClose}
        actions={[
          {
            text: 'Disable 2FA',
            form: 'disable_tfa',
            kind: 'error',
            disabled: isSubmitting
          },
          {
            text: 'Cancel',
            level: 'secondary',
            onClick: onClose,
            disabled: isSubmitting
          }
        ]}
      >
        <Form id="disable_tfa" onSubmit={onSubmit}>
          <p>
            Disabling two-factor authentication means that Parsec will no longer
            ask you for an authentication code when logging in.
          </p>

          <CredentialForm
            showTfaInput={
              showTfa ||
              disableTFA.error?.codes.some(
                code => code.type === 'tfa_incorrect'
              )
            }
          />

          {disableTFA.error ? (
            <ErrorMessage>{disableTFA.error.error}</ErrorMessage>
          ) : null}
        </Form>
      </Modal>
    </FormProvider>
  );
}
