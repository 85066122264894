import Image from 'next/image';

import { Button } from '@parsec/components';
import { ELOQUA_LINKS } from '@parsec/constants';

import { MARKETING_URL } from 'lib/config';

import {
  Wrapper,
  FeaturesList,
  FeatureDescription,
  FeatureTitle,
  ListItem,
  Banner,
  BannerLink
} from './sharedStyles';

import installApp from 'lib/images/confirmation/install_app@2x.png';
import inviteMembers from 'lib/images/confirmation/invite_members@2x.png';
import parsecLogo from 'lib/images/confirmation/parsec-logo@2x.png';
import recommendedSettings from 'lib/images/confirmation/recommended_settings@2x.png';

// CONTAINER
const JoinTeamConfirmation = () => {
  return (
    <Wrapper>
      <Button size="large" href={`${MARKETING_URL}/downloads`}>
        <span>Download the Parsec app</span>
      </Button>
      <FeaturesList>
        <ListItem>
          <Image
            src={installApp.src}
            alt=""
            width={65}
            height={62}
            priority={false}
          />
          <div>
            <FeatureTitle>Install The App</FeatureTitle>
            <FeatureDescription>
              Setup Parsec for your operating system and hardware of choice to
              begin hosting or making connections.{' '}
              <a
                href="https://support.parsec.app/hc/en-us/sections/4422904279309-Get-Set-Up"
                target="_blank"
                rel="noreferrer"
              >
                How to get set up
              </a>
            </FeatureDescription>
          </div>
        </ListItem>
        <ListItem>
          <Image
            src={recommendedSettings.src}
            alt=""
            width={65}
            height={54}
            priority={false}
          />
          <div>
            <FeatureTitle>Use Recommended Settings</FeatureTitle>
            <FeatureDescription>
              Configure your machine for art, animation, video editing, user
              research, or other common use cases.{' '}
              <a
                href="https://support.parsec.app/hc/en-us/sections/4422904316941-Use-Cases-and-Recommended-Settings"
                target="_blank"
                rel="noreferrer"
              >
                Learn about recommended settings
              </a>
            </FeatureDescription>
          </div>
        </ListItem>
        <ListItem>
          <Image
            src={inviteMembers.src}
            alt=""
            width={65}
            height={58}
            priority={false}
          />
          <div>
            <FeatureTitle>Collaborate With Your Team</FeatureTitle>
            <FeatureDescription>
              Ask your admin about how to connect to others using team members
              and groups.
            </FeatureDescription>
          </div>
        </ListItem>
      </FeaturesList>
      <a href={ELOQUA_LINKS.CONTACT_US} target="_blank" rel="noreferrer">
        <span>Looking for Enterprise features?</span>
      </a>
      <Banner>
        <Image
          src={parsecLogo.src}
          alt="Parsec Logo"
          width={120}
          height={29}
          priority={false}
        />
        <BannerLink href={`${MARKETING_URL}/downloads`}>
          <span>Download Parsec App</span>
        </BannerLink>
      </Banner>
      <a
        href="https://support.parsec.app/hc/en-us/categories/4420173134861-Parsec-for-Teams"
        target="_blank"
        rel="noreferrer"
      >
        <span>How do I use Parsec?</span>
      </a>
    </Wrapper>
  );
};

export default JoinTeamConfirmation;
