import { Button, ErrorMessage, LegacyModal, styled } from '@parsec/components';

interface PurchaseErrorProps {
  error: string;
  onStartOver(): void;
}

export default function PurchaseError(props: PurchaseErrorProps) {
  const { error, onStartOver } = props;

  return (
    <>
      <Section>
        <ErrorMessage>{error}</ErrorMessage>
      </Section>
      <LegacyModal.Actions>
        <Button kind="neutral" onClick={onStartOver}>
          Start Over
        </Button>
      </LegacyModal.Actions>
    </>
  );
}

const Section = styled('div', {
  padding: '$xxlarge',
  background: '$perfectGray',
  display: 'grid'
});
