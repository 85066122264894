// libraries
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

// @parsec
import { Button, TfaBackupCodes, TfaConfirm, styled } from '@parsec/components';
import { useCreateTFASetupURI, useEnableTFA } from '@parsec/queries';
import { parseError } from '@parsec/request';

import {
  TFA_BACKUP_CODES_IDS,
  TFA_CONFIRM_FORM_IDS,
  type TfaBackupCodesId,
  type TfaConfirmId
} from 'lib/constants/formIds';
import { useSignup } from 'lib/hooks/useSignup';

import Actions from './Actions';

/** ========= STYLES ========= */

const Wrapper = styled('div', {
  width: '30rem',
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge',
  transition: '0.25s width ease-in-out',
  '@large': { width: '38rem' }
});

const Title = styled('h2', {
  fontSize: '$title',
  lineHeight: '$title'
});

const SkipButton = styled('button', {
  color: '$consoleWhite',
  fontWeight: 'bold',
  cursor: 'pointer'
});

/** ======== CONTEXT ======== */
export interface TfaContextType {
  dlConversion?: boolean;
  onTfaView(): void;
  onTfaSkip(): void;
  onTfaSubmit(): void;
  onBackupView(): void;
  onBackupSkip(): void;
  onBackupDownload(): void;
}

export const TfaContext = createContext<TfaContextType | undefined>(undefined);

export const useTfaContext = () => {
  const context = useContext(TfaContext);
  if (!context) {
    throw new Error(
      'useTfaContext must be used within a <TfaContext.Provider>'
    );
  }
  return context;
};

/** ========= COMPONENTS ========= */

interface Props {
  description?: string;
  canSkip?: boolean;
  defaultPassword?: string;
  onDone(): void;
  confirmId?: TfaConfirmId;
  backupCodesId?: TfaBackupCodesId;
  dlConversion?: boolean; // temporary until join-team flow is fixed
}

export default function TfaSetup(props: Props) {
  const {
    description,
    defaultPassword,
    canSkip = true,
    onDone,
    confirmId = TFA_CONFIRM_FORM_IDS.DEFAULT_ID,
    backupCodesId = TFA_BACKUP_CODES_IDS.DEFAULT_ID
  } = props;
  const { teamData } = useSignup();
  const { onTfaView } = useTfaContext();
  const [password, setPassword] = useState(defaultPassword ?? '');

  useEffect(() => {
    const timeout = setTimeout(() => setPassword(''), 180_000);
    return () => clearTimeout(timeout);
  }, []);

  const [tfaView, setTFAView] = useState(false);

  useEffect(() => {
    // Seeing a double dataview post where we see 3.1.1 and later, we see 1.8.1.
    // Try using this state to prevent double submissions.
    if (tfaView) return;

    // DataLayer TFA view
    onTfaView?.();

    setTFAView(true);
  }, [onTfaView, teamData, tfaView]);

  const [codes, setCodes] = useState<string[]>([]);

  const handleDone = useCallback(
    (backups: string[]) => {
      if (!backups.length) {
        return onDone();
      }

      setCodes(backups);
      setPassword('');
    },
    [onDone]
  );

  return (
    <Wrapper>
      {codes.length ? (
        <Backup id={backupCodesId} codes={codes} onDone={onDone} />
      ) : (
        <Confirm
          id={confirmId}
          description={description}
          password={password}
          canSkip={canSkip}
          onDone={handleDone}
        />
      )}
    </Wrapper>
  );
}

interface ConfirmProps {
  description?: string;
  password?: string;
  canSkip?: boolean;
  onDone(backups: string[]): void;
  id: TfaConfirmId;
}

function Confirm({ id, description, password, canSkip, onDone }: ConfirmProps) {
  const { onTfaSkip, onTfaSubmit } = useTfaContext();

  const createTfaSetupURI = useCreateTFASetupURI(),
    { mutateAsync: setupMutate } = createTfaSetupURI;

  const [isValid, setIsValid] = useState(false);

  const handleSetup = useCallback(async () => {
    try {
      const res = await setupMutate();
      return res.data;
    } catch (err) {
      const error = parseError(err);
      if (error.status === 409) onDone([]);
      throw err;
    }
  }, [setupMutate, onDone]);

  const enableTFA = useEnableTFA(),
    { mutateAsync: enableMutate } = enableTFA;

  const handleConfirm = useCallback(
    async (code: string, password: string, email?: string) => {
      onTfaSubmit?.();

      const res = await enableMutate({
        code,
        password,
        email
      });
      onDone(res.filter(code => !code.used).map(code => code.code));
    },
    [enableMutate, onDone, onTfaSubmit]
  );

  const handleSkip = useCallback(() => {
    onTfaSkip?.();
    onDone([]);
  }, [onDone, onTfaSkip]);

  const onValidate = useCallback((isValid: boolean) => {
    setIsValid(isValid);
  }, []);

  return (
    <>
      <Title>Protect your account</Title>
      <TfaConfirm
        id={id}
        description={description}
        password={password}
        onSetup={handleSetup}
        onConfirm={handleConfirm}
        onValidate={onValidate}
      />
      <Actions css={{ marginTop: '$xlarge' }}>
        {canSkip ? (
          <SkipButton onClick={handleSkip} type="button">
            Set Up Later
          </SkipButton>
        ) : null}
        <Button
          wide
          id="submit_signup_tfa"
          type="submit"
          form={id}
          disabled={!isValid}
        >
          Enable 2FA
        </Button>
      </Actions>
    </>
  );
}

function Backup(props: {
  id: TfaBackupCodesId;
  codes: string[];
  onDone(): void;
}) {
  const { id, codes, onDone } = props;
  const { onBackupView, onBackupSkip, onBackupDownload } = useTfaContext();
  const [backupView, setBackupView] = useState(false);

  useEffect(() => {
    // Seeing a double dataview post where we see 3.1.4 and later, we see 1.8.3:270.
    // Try using this state to prevent double submissions.
    if (backupView) return;

    onBackupView?.();

    setBackupView(true);
  }, [backupView, onBackupView]);

  const handleSkip = useCallback(() => {
    onBackupSkip?.();
    onDone();
  }, [onBackupSkip, onDone]);

  return (
    <>
      <Title>Backup codes</Title>
      <TfaBackupCodes
        codes={codes}
        onDone={onDone}
        id={id}
        onDownload={onBackupDownload}
      />
      <Actions>
        <SkipButton onClick={handleSkip}>Skip</SkipButton>
        <Button wide type="submit" form={id}>
          Download
        </Button>
      </Actions>
    </>
  );
}
