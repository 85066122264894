import { useEffect, useMemo, useState } from 'react';

import Head from 'next/head';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { type BoundTurnstileObject } from 'react-turnstile';

import { pushDlSignUp, pushDlSignUpV3 } from '@parsec/analytics';
import {
  Button,
  CaptchaActions,
  CaptchaWidget,
  Divider,
  ErrorMessage,
  FieldLabel,
  Icon,
  Input,
  styled
} from '@parsec/components';
import {
  useConfirmEmailCode,
  useSignUp,
  useGetVerification
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlert, Wrapper, SignUpDisclaimer } from 'components';
import { TURNSTILE_SITE_KEY } from 'lib/config';
import { EMAIL_VERIFICATION_FORM_ID } from 'lib/constants/formIds';
import { useSignup as useSignupProvider } from 'lib/hooks/useSignup';
import { getJourneyTrigger } from 'lib/util/journey-util';

import Actions from '../Actions';

import passwordHero from 'lib/images/password@2x.png';

interface EmailStatus {
  kind: 'error' | 'success';
  message: string;
}

const CenteredErrorMessage = styled(ErrorMessage, {
  textAlign: 'center'
});

// STYLE
const VerifyEmailContent = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  maxWidth: '38rem',
  rowGap: '$xxlarge'
});

const StyledForm = styled('form', {
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'row',
  transition: '0.25s width ease-in-out'
});

const Centered = styled('div', {
  textAlign: 'center'
});

const Title = styled('h1', {
  fontSize: '$title',
  lineHeight: '$title'
});

const ContentWrapper = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  rowGap: '3.6rem',
  maxWidth: '38rem'
});

const title = 'Verify Your Email';

// COMPONENT
export default function VerifyEmail() {
  const verifyEmailCode = useConfirmEmailCode();
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaBound, setCaptchaBound] = useState<BoundTurnstileObject>();
  const [isResendingEmail, setIsResendingEmail] = useState(false);

  const { send, step, steps, userData, setUserData, teamData, savedUrlParams } =
    useSignupProvider();
  const [alert, setAlert] = useAlert();
  const { data: verification } = useGetVerification();
  const isVerified = verification?.confirmed;

  const trigger = getJourneyTrigger(savedUrlParams);

  const signUp = useSignUp();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { isValid, isSubmitting, errors }
  } = useForm();
  const { isTeam, isTeamAdmin } = teamData;

  // dataLayer pushes
  useEffect(() => {
    if (isTeam || isTeamAdmin) {
      pushDlSignUp({ step: '1.5', isTeam, isTeamAdmin, trigger });
    } else {
      pushDlSignUpV3({ step: '1.5', trigger });
    }
  }, [isTeam, isTeamAdmin, trigger]);

  const emailStatus = useMemo<EmailStatus | undefined>(() => {
    if (signUp.isError) {
      return {
        kind: 'error',
        message: parseError(signUp.error, {
          error:
            'Sorry there was an error re-sending the email. Please try again.'
        }).error
      };
    }
    return undefined;
  }, [signUp]);

  const resetTurnstile = () => {
    // Reset the captcha widget so we are granted a new token
    setCaptchaToken('');
    captchaBound?.reset();
  };

  const onSubmit = handleSubmit(async values => {
    try {
      if (isTeam || isTeamAdmin) {
        pushDlSignUp({ step: '1.6', isTeam, isTeamAdmin, trigger });
      } else {
        pushDlSignUpV3({ step: '1.6', trigger });
      }

      const resp = await verifyEmailCode.mutateAsync({
        email: values.email,
        secret: values.secret,
        captcha_token: captchaToken
      });
      setUserData({ userId: resp.body.data.user_id });
    } catch (error) {
      const parsedError = parseError(error, {
        error: 'There was an problem with your verification code.'
      });

      setError('api_error', {
        type: parsedError.status.toString(),
        message: parsedError.error
      });
    } finally {
      resetTurnstile();
    }
  });

  const handleResendEmail = async () => {
    setIsResendingEmail(true);
    try {
      if (
        userData.email === undefined ||
        userData.username === undefined ||
        userData.password === undefined
      ) {
        throw new Error('Missing user data');
      }

      await signUp.mutateAsync({
        email: userData.email,
        name: userData.username,
        password: userData.password,
        marketing_opt_in: userData.marketing_opt_in ? true : null,
        captcha_token: captchaToken
      });
    } catch (error) {
      const parsedError = parseError(error, {
        error: 'There was an problem with re-sending the email.'
      });

      setError('api_error', {
        type: parsedError.status.toString(),
        message: parsedError.error
      });
    } finally {
      reset();
      resetTurnstile();
      setIsResendingEmail(false);
    }
  };

  useEffect(() => {
    if (signUp.isSuccess) {
      setAlert({
        kind: 'success',
        title: 'Confirmation resent!',
        message: 'Please check your email'
      });
    }
  }, [signUp.isSuccess, setAlert]);

  useEffect(() => {
    if (isVerified && step === steps.VerifyEmail) {
      send({
        type: 'EMAIL_VERIFIED'
      });
    }
  }, [send, step, steps, isVerified]);

  return (
    <Wrapper layout="spread" spacer="top">
      <Head>
        <title>{`${title} | Parsec`}</title>
        <meta name="robots" key="robots" content="noindex, nofollow" />
      </Head>

      <ContentWrapper>
        <Image src={passwordHero.src} alt="" width={250} height={125} />

        <Title>Verify Your Email</Title>

        {alert}

        <VerifyEmailContent id={EMAIL_VERIFICATION_FORM_ID}>
          <p>
            Before we continue, we&apos;ll need to verify your email. You should
            have a verification email waiting in your inbox.
          </p>

          <StyledForm onSubmit={onSubmit}>
            <Input
              type="hidden"
              defaultValue={userData.email}
              {...register('email')}
            />

            <FieldLabel>
              <FieldLabel.Label label="Verification Code">
                <Input
                  {...register('secret', {
                    required: true,
                    minLength: 1,
                    onChange: () => {
                      clearErrors('api_error');
                    }
                  })}
                />
              </FieldLabel.Label>
            </FieldLabel>

            {Object.keys(errors).map((error, index) => (
              <ErrorMessage key={`error-message-${index}`}>
                {errors[error]?.message?.toString()}
              </ErrorMessage>
            ))}

            <Actions css={{ marginTop: '$xlarge' }}>
              <Button
                wide
                css={{ marginTop: '$xlarge' }}
                icon={<Icon name="check" />}
                id="submit_verification_code"
                iconPosition="left"
                disabled={!isValid || isSubmitting || !captchaToken}
              >
                Submit Verification Code
              </Button>
            </Actions>
          </StyledForm>

          <Divider css={{ width: '100%' }} />
          <Centered>
            Haven&apos;t received the email?
            <br />
            Click resend and we&apos;ll try again.
          </Centered>
          <Button
            onClick={handleResendEmail}
            icon={<Icon name="send" />}
            level="secondary"
            iconPosition="left"
            disabled={isSubmitting || isResendingEmail || !captchaToken}
          >
            Resend Email
          </Button>

          {emailStatus?.kind === 'error' && (
            <CenteredErrorMessage>{emailStatus.message}</CenteredErrorMessage>
          )}

          <CaptchaWidget
            sitekey={TURNSTILE_SITE_KEY}
            onCaptchaVerify={(token: string) => setCaptchaToken(token)}
            onCaptchaLoad={(_, bound) => setCaptchaBound(bound)}
            appearance="interaction-only"
            action={CaptchaActions.ConfirmEmailCode}
          />
        </VerifyEmailContent>
      </ContentWrapper>
      <SignUpDisclaimer />
    </Wrapper>
  );
}
