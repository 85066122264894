import { useRouter } from 'next/router';

import { AlertModal, Button, styled } from '@parsec/components';
import { useLogOut } from '@parsec/queries';

import { clearStickyState } from 'lib/util/storageAvailable';

// STYLE
const LogInBtn = styled('button', {
  color: '$rhyhorn',
  cursor: 'pointer',
  '&:hover, &:focus': {
    color: '$primary500'
  }
});

const BackToLogInModal = () => {
  const { mutateAsync: logOut } = useLogOut();
  const router = useRouter();

  const handleLogOutClick = async () => {
    await logOut();
    clearStickyState(`isInSignupFlow`);
    router.push(`/login`);
  };

  return (
    <AlertModal>
      <AlertModal.Trigger asChild>
        <LogInBtn>Log in to a different account</LogInBtn>
      </AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="small">
            <AlertModal.Header>
              <AlertModal.Title>Back To Login</AlertModal.Title>
            </AlertModal.Header>
            <AlertModal.ContentWrapper>
              <p>You&apos;ll be sent to login so you may switch accounts.</p>
            </AlertModal.ContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Action asChild>
                <Button onClick={handleLogOutClick}>Log out</Button>
              </AlertModal.Action>
              <AlertModal.Cancel asChild>
                <Button level="secondary">Cancel</Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

export default BackToLogInModal;
