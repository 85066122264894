/**
 * When to use this Dropdown Select component ?
 * If you need to implement a list of options for the user to pick from.
 * Only for single selection !
 * Doc https://www.radix-ui.com/docs/primitives/components/select
 */
import { type ReactNode, forwardRef } from 'react';

import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as Select from '@radix-ui/react-select';

import { styled } from '@parsec/styles';

import Checkbox from '../Checkbox';
import Radio from '../Radio';
import {
  StyledContent,
  StyledViewport,
  StyledItem
} from '../SharedDropdownStyles';
import { Side, Align, Size } from '../SharedType';

// STYLES
const DropdownSelectContent = styled(Select.Content, {
  ...StyledContent,
  paddingTop: '1rem'
});

const ScrollAreaViewport = styled(ScrollArea.Viewport, {
  ...StyledViewport,
  paddingTop: '$none'
});

const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar, {
  padding: '1rem $none',
  width: '0.8rem' // Doesn't work with token
});

const ScrollAreaThumb = styled(ScrollArea.Thumb, {
  width: '$medium',
  background: '$kos',
  borderRadius: '3rem'
});

const DropdownSelectItem = styled(Select.Item, {
  ...StyledItem
});

const DropdownSelectCheckedItem = styled(DropdownSelectItem, {
  display: 'flex',
  gap: '$large'
});

// COMPONENTS
/**
 * DropdownSelect base component
 * More info on props https://www.radix-ui.com/docs/primitives/components/select#root
 */
export interface DropdownSelectProps extends Select.SelectProps {}
export function DropdownSelect(props: DropdownSelectProps) {
  const {
    children,
    defaultOpen,
    open,
    onOpenChange,
    defaultValue,
    value,
    onValueChange,
    name,
    disabled = false,
    required = false,
    ...rest
  } = props;
  return (
    <Select.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
      name={name}
      disabled={disabled}
      required={required}
      {...rest}
    >
      {children}
    </Select.Root>
  );
}

/**
 * Trigger
 * The button that toggles the select.
 * The DropdownSelect.Content will position itself by aligning over the trigger.
 * You can style it or use it as a wrapper.
 */
interface TriggerProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Trigger = (props: TriggerProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.Trigger asChild={asChild} {...rest}>
      {children}
    </Select.Trigger>
  );
};

/**
 * Value
 * The part that reflects the selected value.
 * By default the selected item's text will be rendered.
 * If you require more control, you can instead control the select and pass your own children.
 * It should not be styled to ensure correct positioning.
 * An optional placeholder prop is also available for when the select has no value.
 */
interface ValueProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
  placeholder?: ReactNode; // The content that will be rendered inside the DropdownSelect.Value when no value or defaultValue is set.
}

const Value = (props: ValueProps) => {
  const { children, asChild = false, placeholder, ...rest } = props;

  return (
    <Select.Value asChild={asChild} placeholder={placeholder} {...rest}>
      {children}
    </Select.Value>
  );
};

/**
 * Icon
 * A small icon often displayed next to the value as a visual affordance for the fact it can be open.
 * By default renders ▼ but you can use your own icon via asChild or use children.
 */
interface IconProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Icon = (props: IconProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.Icon asChild={asChild} {...rest}>
      {children}
    </Select.Icon>
  );
};

/**
 * Portal
 * When used, portals the content part into the body.
 */
interface PortalProps {
  children: ReactNode;
  container?: HTMLElement | null;
}

const Portal = (props: PortalProps) => {
  const { children, container = document.body, ...rest } = props;

  return (
    <Select.Portal container={container} {...rest}>
      {children}
    </Select.Portal>
  );
};

/**
 * Content
 * The component that pops out when the select is open.
 * Didn't add all the props, check the doc for more info.
 * Doc: https://www.radix-ui.com/docs/primitives/components/select#content
 */
export enum Position {
  ItemAligned = 'item-aligned',
  Popover = 'popper'
}

interface ContentProps {
  children: ReactNode;
  asChild?: boolean;
  position?: Position;
  side?: Side;
  sideOffset?: number;
  align?: Align;
  alignOffset?: number;
  size?: Size;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(function (
  props: ContentProps,
  ref
) {
  const {
    children,
    asChild = false,
    position = Position.ItemAligned,
    side = Side.Bottom,
    sideOffset = 0,
    align = Align.Start,
    alignOffset = 0,
    size = Size.Small,
    ...rest
  } = props;
  return (
    <DropdownSelectContent
      asChild={asChild}
      position={position}
      side={side}
      sideOffset={sideOffset}
      align={align}
      alignOffset={alignOffset}
      size={size}
      ref={ref}
      {...rest}
    >
      {children}
    </DropdownSelectContent>
  );
});

/**
 * Viewport
 * The scrolling viewport that contains all of the items.
 * Had to add the ScrollArea component because the Select.Viewport from Radix
 * hide the scrollbar by default
 * See here https://www.radix-ui.com/docs/primitives/components/select#with-custom-scrollbar
 */
interface ViewportProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Viewport = (props: ViewportProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <ScrollArea.Root type="auto" className="ScrollAreaRoot">
      <Select.Viewport asChild>
        <ScrollAreaViewport
          className="ScrollAreaViewPort"
          style={{ overflowY: undefined }} // fixes this error https://github.com/radix-ui/primitives/issues/2059
          asChild={asChild}
          {...rest}
        >
          {children}
        </ScrollAreaViewport>
      </Select.Viewport>
      <ScrollAreaScrollbar
        orientation="vertical"
        className="ScrollAreaScrollbar"
      >
        <ScrollAreaThumb className="ScrollAreaThumb" />
      </ScrollAreaScrollbar>
    </ScrollArea.Root>
  );
};

/**
 * Item
 * The component that contains the select items.
 * More info on props https://www.radix-ui.com/docs/primitives/components/select#item
 */
export interface ItemProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
  value: string; // The value given as data when submitted with a name.
  disabled?: boolean;
  textValue?: string;
}

const Item = forwardRef<HTMLDivElement, ItemProps>((props, forwardedRef) => {
  const {
    children,
    asChild = false,
    value,
    disabled,
    textValue,
    ...rest
  } = props;

  return (
    <DropdownSelectItem
      asChild={asChild}
      value={value}
      disabled={disabled}
      textValue={textValue}
      ref={forwardedRef}
      {...rest}
    >
      {children}
    </DropdownSelectItem>
  );
});

/**
 * CheckBoxItem
 * DropdownSelect Item with a checkbox.
 */
interface CheckboxItemProps {
  children?: ReactNode;
  value: string; // The value given as data when submitted with a name.
  disabled?: boolean;
  textValue?: string;
  selectedValue: string;
}

const CheckboxItem = forwardRef<HTMLDivElement, CheckboxItemProps>(function (
  props: CheckboxItemProps,
  ref
) {
  const { children, value, disabled, textValue, selectedValue, ...rest } =
    props;

  return (
    <DropdownSelectCheckedItem
      value={value}
      disabled={disabled}
      textValue={textValue}
      ref={ref}
      {...rest}
    >
      <Checkbox readOnly checked={selectedValue === value} />
      <Select.ItemText>{children}</Select.ItemText>
    </DropdownSelectCheckedItem>
  );
});

/**
 * RadioItem
 * DropdownSelect Item with a radio button.
 */
interface RadioItemProps {
  children?: ReactNode;
  value: string; // The value given as data when submitted with a name.
  disabled?: boolean;
  textValue?: string;
  selectedValue: string;
}

const RadioItem = forwardRef<HTMLDivElement, RadioItemProps>(function (
  props: RadioItemProps,
  ref
) {
  const { children, value, disabled, textValue, selectedValue, ...rest } =
    props;

  return (
    <DropdownSelectCheckedItem
      value={value}
      disabled={disabled}
      textValue={textValue}
      ref={ref}
      {...rest}
    >
      <Radio readOnly checked={selectedValue === value} />
      <Select.ItemText>{children}</Select.ItemText>
    </DropdownSelectCheckedItem>
  );
});

/**
 * ItemText
 * The textual part of the item.
 * It should only contain the text you want to see in the trigger when that item is selected.
 * It should not be styled to ensure correct positioning.
 * Use this when you have a complex item.
 * Example: https://www.radix-ui.com/docs/primitives/components/select#with-complex-items
 */
interface ItemTextProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const ItemText = (props: ItemTextProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.ItemText asChild={asChild} {...rest}>
      {children}
    </Select.ItemText>
  );
};

/**
 * ItemIndicator
 * Renders when the item is selected.
 * You can style this element directly, or you can use it as a wrapper to put an icon into, or both.
 */
interface ItemIndicatorProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const ItemIndicator = (props: ItemIndicatorProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.ItemIndicator asChild={asChild} {...rest}>
      {children}
    </Select.ItemIndicator>
  );
};

/**
 * ScrollUpButton
 * An optional button used as an affordance to show the viewport overflow as well as functionaly enable scrolling upwards.
 * Not styled.
 */
interface ScrollUpButtonProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const ScrollUpButton = (props: ScrollUpButtonProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.ScrollUpButton asChild={asChild} {...rest}>
      {children}
    </Select.ScrollUpButton>
  );
};

/**
 * ScrollDownButton
 * An optional button used as an affordance to show the viewport overflow as well as functionaly enable scrolling downwards.
 *Base.
 */
interface ScrollDownButtonProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const ScrollDownButton = (props: ScrollDownButtonProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.ScrollDownButton asChild={asChild} {...rest}>
      {children}
    </Select.ScrollDownButton>
  );
};

/**
 * Group
 * Used to group multiple items.
 * Use in conjunction with DropdownSelect.Label to ensure good accessibility via automatic labelling.
 * Not Styled
 */
interface GroupProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Group = (props: GroupProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.Group asChild={asChild} {...rest}>
      {children}
    </Select.Group>
  );
};

/**
 * Label
 * Used to render the label of a group.
 * It won't be focusable using arrow keys.
 * Not styled.
 */
interface LabelProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Label = (props: LabelProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.Label asChild={asChild} {...rest}>
      {children}
    </Select.Label>
  );
};

/**
 * Separator
 * Used to visually separate items in the select.
 * Not styled.
 */
interface SeparatorProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Separator = (props: SeparatorProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Select.Separator asChild={asChild} {...rest}>
      {children}
    </Select.Separator>
  );
};

/**
 * Arrow
 * An optional arrow element to render alongside the content.
 * This can be used to help visually link the trigger with the DropdownSelect.Content.
 * Must be rendered inside DropdownSelect.Content.
 * Only available when position is set to popover.
 * Not styled.
 */
interface ArrowProps {
  children: ReactNode;
  asChild?: boolean;
  width?: number;
  height?: number;
}

const Arrow = (props: ArrowProps) => {
  const { children, asChild = false, width, height, ...rest } = props;

  return (
    <Select.Arrow asChild={asChild} width={width} height={height} {...rest}>
      {children}
    </Select.Arrow>
  );
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <Item {...props} ref={forwardedRef}>
        <ItemText>{children}</ItemText>
      </Item>
    );
  }
);

export default DropdownSelect;

// EXPORTS
DropdownSelect.Trigger = Trigger;
DropdownSelect.Value = Value;
DropdownSelect.Icon = Icon;
DropdownSelect.Portal = Portal;
DropdownSelect.Content = Content;
DropdownSelect.Viewport = Viewport;
DropdownSelect.Item = Item;
DropdownSelect.SelectItem = SelectItem;
DropdownSelect.RadioItem = RadioItem;
DropdownSelect.CheckboxItem = CheckboxItem;
DropdownSelect.ItemText = ItemText;
DropdownSelect.ItemIndicator = ItemIndicator;
DropdownSelect.ScrollUpButton = ScrollUpButton;
DropdownSelect.ScrollDownButton = ScrollDownButton;
DropdownSelect.Group = Group;
DropdownSelect.Label = Label;
DropdownSelect.Separator = Separator;
DropdownSelect.Arrow = Arrow;
