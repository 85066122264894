import { useCallback, useEffect } from 'react';

import Identify from '@parsec/analytics/src/utils/Identify';

import { pushDlWarpTrial } from '@parsec/analytics';
import { Button, LegacyModal, styled } from '@parsec/components';
import { ERRORS } from '@parsec/constants';
import { WarpSubscription, WarpPlanID } from '@parsec/kessel';

import { WarpLogo } from 'components';
import {
  BillingInfoForm,
  Customer,
  Coupon,
  NewCard,
  CustomerData,
  NewCardData,
  CouponData
} from 'components/Billing';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';
import { useCreateModalState } from 'lib/hooks';

// local components
import { CREATE_HEADER_COPY, title } from '../copy-constants';
import PurchaseError from '../PurchaseError';
import PurchaseSuccess from '../PurchaseSuccess';
import { Description, Wrapper, HeadBack } from '../shared-components';
import ConfirmationForm from '../WarpConfirmPurchase';
import WarpPricing from '../WarpPricing';

type BillingInfoInput = CustomerData & NewCardData & CouponData;

interface Props {
  isOpen: boolean;
  subscription?: WarpSubscription;
  email: string;
  onClose(): void;
  onAfterClose?(): void;
}

export default function CreateModal(props: Props) {
  const {
    isOpen,
    onClose,
    subscription,
    onAfterClose = () => {},
    email
  } = props;

  const {
    step,
    error,
    req,
    loading,
    currentPlanId,
    subtotal,
    estimate,
    updateReq,
    setError,
    setStep,
    onCreateSubscription
  } = useCreateModalState({ subscription });
  //TODO: remove this pattern. explore useReducer/Context.

  const onCloseHandler = useCallback(() => {
    onClose();
    updateReq({ coupon: '' });
  }, [onClose, updateReq]);

  const hasCoupon = Boolean(req.coupon);
  const couponIsValid = hasCoupon && Boolean(estimate.data);

  const couponError =
    step === 'billing' &&
    hasCoupon &&
    estimate.error?.error === ERRORS.COUPON_ERROR
      ? ERRORS.COUPON_ERROR
      : '';

  const trial = !subscription || subscription.status === 'in_trial';

  // DataLayer View Pushes
  useEffect(() => {
    if (trial) {
      if (step === 'pricing') {
        pushDlWarpTrial({ step: '2.1' });
      } else if (step === 'billing') {
        pushDlWarpTrial({ step: '2.3' });
      } else if (step === 'confirmation') {
        pushDlWarpTrial({ step: '2.5' });
      } else if (step === 'success') {
        pushDlWarpTrial({ step: '2.7', isConversion: true });
      }
    }
  }, [step, trial]);

  const renderSteps = (): JSX.Element | null => {
    switch (step) {
      case 'pricing':
        return (
          <>
            <WarpPricing
              currentPlanId={currentPlanId ?? undefined}
              selectedPlanId={req.plan_id as WarpPlanID}
              subtotal={subtotal}
              updateReq={updateReq}
            />
            <LegacyModal.Actions>
              <Button
                onClick={() => {
                  if (trial) {
                    pushDlWarpTrial({ step: '2.2' });
                  }

                  setStep('billing');
                }}
              >
                Next
              </Button>
              <Button
                aria-label="Close Modal"
                level="secondary"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </LegacyModal.Actions>
          </>
        );
      case 'billing':
        return (
          <BillingInfoSection>
            <BillingInfoForm<BillingInfoInput>
              id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
              onSubmit={data => {
                if (trial) {
                  pushDlWarpTrial({ step: '2.4' });
                }

                updateReq({
                  customer: {
                    company: data.company_name,
                    email: data.billing_email,
                    phone: data.phone,
                    tax_id: data.tax_id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    billing_address: {
                      line1: data.address_1,
                      line2: data.address_2,
                      country: data.country,
                      state: data.state ?? '',
                      city: data.city,
                      zip: data.postal_code
                    }
                  },
                  token: data.token,
                  coupon: data.coupon
                });
                setStep('confirmation');
              }}
            >
              <Customer
                customer={{
                  ...req.customer,
                  email: req.customer.email === '' ? email : req.customer.email,
                  billing_address: {
                    ...req.customer.billing_address,
                    line2: req.customer.billing_address.line2,
                    zip: req.customer.billing_address.zip
                  }
                }}
                validateName={(_, formValues) => {
                  return (
                    Boolean(formValues.company_name) ||
                    (Boolean(formValues.first_name) &&
                      Boolean(formValues.last_name)) ||
                    'Require company name OR first AND last name.'
                  );
                }}
                requireCompanyName={false}
              />
              <TrialDisclaimer>
                You will not be charged for the trial if you choose to cancel
                before the trial ends.
              </TrialDisclaimer>
              <NewCard />
              <Coupon
                defaultValue={req.coupon}
                apply={coupon => updateReq({ coupon })}
                isValid={couponIsValid}
                errorMsg={couponError}
              />
              <LegacyModal.Actions>
                <Button
                  type="submit"
                  kind="success"
                  id="submit_create_subscription"
                  form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                >
                  View Summary
                </Button>
                <Button
                  level="secondary"
                  type="button"
                  onClick={() => setStep('pricing')}
                  aria-label="Back to Pricing"
                >
                  Back
                </Button>
              </LegacyModal.Actions>
            </BillingInfoForm>
          </BillingInfoSection>
        );
      case 'confirmation':
        return (
          <>
            <ConfirmationForm
              selectedPlanId={req.plan_id}
              subscription={subscription}
              estimate={estimate.data}
              coupon={req.coupon}
              onConfirm={onCreateSubscription}
              onSubscribe={() => {
                if (trial) {
                  pushDlWarpTrial({ step: '2.6' });
                }
                setStep('success');
                Identify({ warpSubscription: req.plan_id });
              }}
              onError={setError}
            />
            <LegacyModal.Actions>
              <Button
                loading={loading}
                kind="primary"
                form="subscription_confirmation_form"
              >
                Confirm and Submit
              </Button>
              <Button
                level="secondary"
                onClick={() => setStep('billing')}
                aria-label="Back to Billing"
              >
                Back
              </Button>
            </LegacyModal.Actions>
          </>
        );
      case 'success':
        return (
          <>
            <PurchaseSuccess
              headline="Feel that? That's the feeling of an even better Parsec."
              description={
                <>
                  <p>
                    Thanks for upgrading to Warp. Your subscription lets us
                    continue making Parsec awesome.
                  </p>
                  <p>Now enough mushy stuff &mdash; go enjoy all the perks.</p>
                </>
              }
            />
            <LegacyModal.Actions>
              <HeadBack onClick={onClose}>Head Back to Parsec</HeadBack>
            </LegacyModal.Actions>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <LegacyModal
      type="brand"
      padding={false}
      size="xlarge"
      isOpen={isOpen}
      onClose={onCloseHandler}
      onAfterClose={onAfterClose}
      title={
        error
          ? 'Uh oh!'
          : step === 'success'
            ? undefined
            : CREATE_HEADER_COPY.title[step as keyof title]
      }
      description={
        step === 'success' ? undefined : (
          <>
            <WarpLogo />
            <Description>{CREATE_HEADER_COPY.description}</Description>
          </>
        )
      }
    >
      <Wrapper>
        {error ? (
          <PurchaseError
            error={error}
            aria-label="Back to Pricing"
            onStartOver={() => {
              setError('');
              setStep('pricing');
            }}
          />
        ) : (
          renderSteps()
        )}
      </Wrapper>
    </LegacyModal>
  );
}

const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});

const TrialDisclaimer = styled('p', {
  marginBottom: '$xlarge',
  color: '#f9fafc4c', //no token
  fontSize: '$info',
  lineHeight: '$info'
});
