/**
 * This component is deprecated use BaseModal instead
 */

import { createContext, useContext, useState, ReactNode } from 'react';

import { createPortal } from 'react-dom';
import ReactModal from 'react-modal';

import { styled } from '@parsec/styles';

import Button from '../Button';
import IconButton from '../IconButton';

export interface LegacyModalAction {
  text: string;
  onClick?(): void;
  level?: 'primary' | 'secondary' | 'link';
  kind?: 'neutral' | 'success' | 'error';
  disabled?: boolean;
  form?: string;
}

export interface LegacyModalProps {
  title?: string;
  description?: ReactNode;
  children: React.ReactNode;
  type?: 'highlight' | 'basic' | 'negative' | 'principal' | 'brand';
  size?: 'small' | 'large' | 'xlarge' | 'xxlarge';
  isOpen: boolean;
  onOpen?(): void;
  onClose(): void;
  onAfterClose?(): void;
  actions?: LegacyModalAction[];
  appElement?: HTMLElement;
  padding?: boolean;
}

const ModalContext = createContext<HTMLElement | null>(null);

function LegacyModal(props: LegacyModalProps) {
  const {
    title,
    description,
    children,
    type = 'basic',
    size = 'large',
    isOpen,
    onOpen,
    onAfterClose,
    onClose,
    actions = [],
    appElement,
    padding = true
  } = props;

  const [portal, setPortal] = useState<HTMLElement | null>(null);

  return (
    <ModalContext.Provider value={portal}>
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={250}
        contentLabel={title}
        onAfterOpen={onOpen}
        onRequestClose={onClose}
        onAfterClose={onAfterClose}
        appElement={appElement}
      >
        <Wrapper size={size}>
          <Header type={type} rows={description ? 2 : 1}>
            {title && <Title>{title}</Title>}

            {description && (
              <Description type={type}>{description}</Description>
            )}
            <Close
              icon="ex"
              title="Close"
              kind="basic"
              aria-label="Close"
              onClick={() => onClose()}
            />
          </Header>

          <Content padding={padding}>{children}</Content>
          <Footer ref={setPortal}>
            {actions.map((action, i) => (
              <Button
                key={i}
                onClick={action.onClick}
                level={action.level}
                kind={action.kind}
                disabled={action.disabled}
                form={action.form}
              >
                {action.text}
              </Button>
            ))}
          </Footer>
        </Wrapper>
      </ReactModal>
    </ModalContext.Provider>
  );
}
export default LegacyModal;

function Actions(props: { children: ReactNode }) {
  const { children } = props;
  const portal = useContext(ModalContext);

  return portal ? createPortal(children, portal) : null;
}

export function setLegacyModalAppElement(el: string | HTMLElement) {
  ReactModal.setAppElement(el);
}

LegacyModal.Actions = Actions;

ReactModal.defaultStyles = {};

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  border: '0.1rem solid $pangoro',
  color: '$consoleWhite',
  backgroundColor: '$carkol',
  borderRadius: '$large',
  overflow: 'hidden',
  width: '38rem',
  maxHeight: '100vh',
  variants: {
    size: {
      small: {
        width: '38rem'
      },
      large: {
        width: '64rem'
      },
      xlarge: {
        width: '72rem'
      },
      xxlarge: {
        width: '91rem'
      }
    }
  }
});

const Header = styled('header', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: 'auto auto',
  gap: '$medium',
  backgroundColor: '$computerBlack',
  padding: '$xlarge $xlarge $xlarge $xxlarge',
  variants: {
    type: {
      basic: {
        backgroundColor: '$computerBlack',
        color: '$consoleWhite'
      },
      principal: {
        backgroundColor: '$primary500',
        color: '$consoleWhite'
      },
      negative: {
        backgroundColor: '$error500',
        color: '$consoleWhite'
      },
      highlight: {
        backgroundColor: '#ffae11',
        color: '$computerBlack'
      },
      brand: {
        backgroundColor: '$brand500',
        color: '$consoleWhite'
      }
    },

    rows: {
      [2]: {
        gridTemplateRows: 'auto auto'
      },
      [1]: {
        gridTemplateRows: 'auto'
      }
    }
  }
});

const Title = styled('h1', {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '$subtitle',
  lineHeight: '$subtitle',
  color: 'inherit'
});

const Description = styled('div', {
  gridColumn: '1',
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  variants: {
    type: {
      basic: {
        color: '$rhyhorn'
      },
      principal: {
        color: '$consoleWhite'
      },
      negative: {
        color: '$consoleWhite'
      },
      highlight: {
        color: '$computerBlack'
      },
      brand: {
        color: '$consoleWhite'
      }
    }
  }
});

const Close = styled(IconButton, {
  gridRow: '1',
  gridColumn: '2'
});

const Content = styled('div', {
  padding: '$xxlarge',
  overflow: 'auto',
  variants: {
    padding: {
      false: {
        padding: '$none'
      }
    }
  }
});

const Footer = styled('footer', {
  display: 'flex',
  gridAutoFlow: 'column',
  gridColumnGap: '$medium',
  backgroundColor: '$computerBlack',
  padding: '$xxlarge $xlarge',
  '&:empty': {
    display: 'none'
  }
});
