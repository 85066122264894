// libraries
import type { ReactNode } from 'react';

// @parsec
import { styled } from '@parsec/components';

interface Props {
  className?: string;
  as?: 'li';
  title: string;
  description: ReactNode;
  children: ReactNode;
}

export default function Setting(props: Props) {
  const { className, title, description, children, ...rest } = props;

  return (
    <Wrapper className={className} {...rest}>
      <Title>{title}</Title>
      <Description>{description}</Description>

      <Control>{children}</Control>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 50rem) auto',
  rowGap: '$small',
  columnGap: '$xxxlarge'
});

const Title = styled('h3', {
  gridColumn: 1,
  fontWeight: 'bold',
  fontSize: '$body',
  lineHeight: '$body'
});

const Description = styled('p', {
  gridColumn: 1,
  fontSize: '$info',
  lineHeight: '$info'
});

const Control = styled('div', {
  gridColumn: 2,
  gridRow: '1 / span 2',
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'end',
  alignItems: 'center',
  columnGap: '$xlarge'
});
