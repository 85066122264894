// @parsec
import { useRef } from 'react';

import { useInfiniteQuery } from 'react-query';

import { clear } from '@parsec/cookie';
import { schema, teamMember, TeamMember } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe, useMeData } from './me';
import { useTeamRolePermissionSummaryData } from './permission';
import { useKessel } from './Provider';
import { useGetTeam, useTeamData } from './team';
import { useTeamGroupsData } from './teamGroup';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_member');

export function useTeamMemberData() {
  return useQueryData(key.all(), schema.paginatedTeamMemberData);
}

/******************************************************************************
 * Get Team Members
 ******************************************************************************/

type GetTeamMembersOpts = Omit<teamMember.GetTeamMembersReq, 'team_id'>;

/** Fetches team members */
export function useGetTeamMembers(
  opts: GetTeamMembersOpts & { skip?: boolean }
) {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  const vars = {
    team_id: teamId ?? '',
    ...opts
  };

  const enabled = vars.team_id !== '' && !opts.skip;

  const varsRef = useRef(vars);

  const keepPreviousData =
    varsRef.current.team_id === vars.team_id &&
    varsRef.current.role_id === vars.role_id &&
    varsRef.current.email === vars.email;
  varsRef.current = vars;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamMember.getTeamMembers(req);
      return res.body;
    },
    { enabled, keepPreviousData }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get team members.'
  });
  return { ...result, error };
}

/******************************************************************************
 * GET ALL EXHAUSTIVE COMPLETE list of Team Members
 ******************************************************************************/

export function useGetAllTeamMembers(
  opts: Omit<GetTeamMembersOpts, 'limit' | 'offset'> & { skip?: boolean }
) {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = {
    team_id: me.data?.team_id ?? '',
    ...opts
  };

  const enabled = vars.team_id !== '' && !opts.skip;

  const varsRef = useRef(vars);

  const keepPreviousData =
    varsRef.current.team_id === vars.team_id &&
    varsRef.current.role_id === vars.role_id &&
    varsRef.current.email === vars.email;
  varsRef.current = vars;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);

      let count = 0;
      let members: TeamMember[] = [];
      let offset = 0;
      const limit = 200;
      let maxRequests = 100;
      while (true) {
        const res = await kessel.teamMember.getTeamMembers({
          offset,
          limit,
          ...req
        });
        count = res.body.count;
        members = members.concat(res.body.data);
        offset += limit;
        if (members.length >= count) break;
        if (--maxRequests <= 0) break;
      }

      return { data: members, count };
    },
    { enabled, keepPreviousData }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get team members.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Get team members with infinite query (future refactor with wrapper)
 ******************************************************************************/

export function useInfiniteGetTeamMembers(
  opts: Omit<teamMember.GetTeamMembersReq, 'team_id'>
) {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  const vars = {
    team_id: teamId ?? '',
    ...opts
  };

  async function queryFn(
    params: Omit<teamMember.GetTeamMembersReq, 'team_id'>
  ) {
    const res = await kessel.teamMember.getTeamMembers({
      team_id: teamId,
      ...params
    });
    return res.body;
  }

  const enabled = teamId !== '';

  return useInfiniteQuery(
    key.list(vars),
    ({ pageParam }) => queryFn(pageParam),
    {
      enabled,
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) => {
        const allDataCount = pages.reduce(
          (memo, cur) => memo + cur.data.length,
          0
        );
        if (lastPage.count <= allDataCount) return undefined;
        return opts;
      }
    }
  );
}

/******************************************************************************
 * Get Team Members To Remove
 ******************************************************************************/

export function useGetTeamMembersToRemove() {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  const vars = { team_id: teamId };

  const enabled = teamId !== '';

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamMember.getTeamMembersToRemove(req);
      const count = res.body.count;
      const members = res.body.data;
      return { count, members };
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get team members to remove.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Get Team Member
 ******************************************************************************/

/** Fetches the auth user */
export function useGetTeamMember(userId?: number) {
  const kessel = useKessel();
  const me = useGetMe();

  const id = userId ?? me.data?.id;

  const vars = { team_id: me.data?.team_id ?? '', user_id: id ?? 0 };

  const enabled = vars.team_id !== '' && vars.user_id !== 0;

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamMember.getTeamMember(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Add Team Member
 ******************************************************************************/

/** Adds a team member */
export function useAddTeamMember() {
  const kessel = useKessel();
  const meCache = useMeData();
  const teamMembersCache = useTeamMemberData();
  const teamCache = useTeamData();

  function onSuccess() {
    meCache.invalidate();
    teamMembersCache.invalidate();
    teamCache.invalidate();
  }

  const result = useMutation(kessel.teamMember.addTeamMember, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to add team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Update Team Member
 ******************************************************************************/

type UpdateTeamMemberVars = Omit<teamMember.UpdateTeamMemberReq, 'team_id'>;

/** Updates a team member's tag */
export function useUpdateTeamMember() {
  const kessel = useKessel();
  const teamMembersCache = useTeamMemberData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: UpdateTeamMemberVars) {
    const res = await kessel.teamMember.updateTeamMember({
      ...reqBody,
      team_id: teamId
    });
    return res.body?.data;
  }

  function onSuccess() {
    teamMembersCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to update team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Remove Team Member
 ******************************************************************************/

/** Removes a team member from the team */
export function useRemoveTeamMember() {
  const kessel = useKessel();
  const teamMembersCache = useTeamMemberData();
  const teamCache = useTeamData();

  const me = useGetMe();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: { user_id: number }) {
    return await kessel.teamMember.removeTeamMember({
      ...reqBody,
      team_id: teamId
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSuccess(data: any, reqBody: { user_id: number }) {
    if (data && me.data?.id === reqBody.user_id) {
      clear();
    } else {
      teamMembersCache.invalidate();
      teamCache.invalidate();
    }
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to remove team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Reset Team Member TFA
 ******************************************************************************/

type ResetTeamMemberTFAVars = Omit<teamMember.ResetTeamMemberTFAReq, 'team_id'>;

/** Resets a team member's TFA */
export function useResetTeamMemberTFA() {
  const kessel = useKessel();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: ResetTeamMemberTFAVars) {
    return await kessel.teamMember.resetTeamMemberTFA({
      ...reqBody,
      team_id: teamId
    });
  }

  const result = useMutation(mutationFn);

  const error = useWrapError(result.error, {
    error: "Failed to reset team member's TFA."
  });
  return { ...result, error };
}

/******************************************************************************
 * Add Roles to Team Member
 ******************************************************************************/

type AddRoleToTeamMemberVars = Omit<
  teamMember.AddTeamMemberRolesReq,
  'team_id'
>;

/** Adds roles to a team member */
export function useAddRoleToTeamMember() {
  const kessel = useKessel();
  const permissionsCache = useTeamRolePermissionSummaryData();
  const teamMembersCache = useTeamMemberData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: AddRoleToTeamMemberVars) {
    return await kessel.teamMember.addTeamMemberRoles({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    permissionsCache.invalidate();
    teamMembersCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to add role to team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Remove Roles from Team Member
 ******************************************************************************/

type RemoveRoleFromTeamMemberVars = Omit<
  teamMember.RemoveTeamMemberRolesReq,
  'team_id'
>;

/** Removes roles from a team member */
export function useRemoveRoleFromTeamMember() {
  const kessel = useKessel();
  const teamMembersCache = useTeamMemberData();
  const permissionsCache = useTeamRolePermissionSummaryData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: RemoveRoleFromTeamMemberVars) {
    return await kessel.teamMember.removeTeamMemberRoles({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    permissionsCache.invalidate();
    teamMembersCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to remove roles from team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Update a TeamMember's groups
 ******************************************************************************/

type UpdateTeamMemberGroupsVars = Omit<
  teamMember.UpdateTeamMemberGroupAssignmentsReq,
  'team_id'
>;

/** Adds roles to a team member */
export function useUpdateTeamMemberGroups() {
  const kessel = useKessel();
  const teamMembersCache = useTeamMemberData();
  const teamGroupsCache = useTeamGroupsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: UpdateTeamMemberGroupsVars) {
    return await kessel.teamMember.updateTeamMemberGroupAssignments({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamMembersCache.invalidate();
    teamGroupsCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to add role to team member.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Resend Team Member confirmation email
 ******************************************************************************/

type ResendTeamMemberConfirmationEmailVars = Omit<
  teamMember.ResendTeamMemberConfirmationEmailReq,
  'team_id'
>;

/** Resend a team member's confirmation email */
export function useResendTeamMemberConfirmationEmail() {
  const kessel = useKessel();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: ResendTeamMemberConfirmationEmailVars) {
    return await kessel.teamMember.resendTeamMemberConfirmationEmail({
      ...reqBody,
      team_id: teamId
    });
  }

  const result = useMutation(mutationFn);

  const error = useWrapError(result.error, {
    error: "Couldn't resend confirmation email."
  });
  return { ...result, error };
}
