import { ReactNode } from 'react';

import { Tab, Tabs as TabsWrapper, TabList, TabPanel } from 'react-tabs';

import { css } from '@parsec/styles';

export interface TabsProps<T> {
  className?: string;
  tabs: Array<[T, string]>;
  defaultTab?: T;
  tab?: T;
  onSelect?(index: number): void;
  children?(key: T): ReactNode;
  style?: React.CSSProperties;
  tabStyle?: 'rounded' | 'flat';
  disabled?: boolean;
  version?: 'newFont';
}

export default function Tabs<T>(props: TabsProps<T>) {
  const {
    className,
    tabs,
    tab,
    defaultTab,
    children,
    style,
    tabStyle = 'rounded',
    disabled = false,
    version
  } = props;

  return (
    <TabsWrapper
      onSelect={props.onSelect}
      style={style}
      className={classNames(
        className,
        !children ? noWrapper().className : wrapperStyle().className
      )}
      selectedIndex={
        tab != null && props.onSelect
          ? tabs.findIndex(([t]) => t === tab)
          : undefined
      }
      defaultIndex={
        tab == null && defaultTab != null
          ? tabs.findIndex(([tab]) => tab === defaultTab)
          : undefined
      }
    >
      <TabList
        className={
          tabStyle === 'rounded'
            ? tabListStyleRounded({ version }).className
            : tabListStyleFlat({ version }).className
        }
      >
        {tabs.map(([, name], i) => (
          <Tab
            disabled={disabled}
            key={i}
            tabIndex={disabled ? '-1' : '0'}
            className={
              tabStyle === 'rounded'
                ? tabStyleRounded().className
                : tabStyleFlat().className
            }
          >
            {name}
          </Tab>
        ))}
      </TabList>

      {tabs.map(([key], i) => (
        <TabPanel key={i} className={tabPanelStyle().className}>
          {children?.(key)}
        </TabPanel>
      ))}
    </TabsWrapper>
  );
}

function classNames(...names: Array<string | undefined>) {
  return names.filter(Boolean).join(' ');
}

const wrapperStyle = css({
  position: 'relative',
  backgroundColor: '$carkol',
  padding: '$xxlarge',
  borderRadius: '$medium',
  marginTop: '$xxxlarge'
});

const noWrapper = css({
  position: 'relative',
  backgroundColor: 'none !important',
  borderBottom: '2px solid $pangoro'
});

const tabListStyleRounded = css({
  display: 'grid',
  gridAutoFlow: 'column',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '$small',
  backgroundColor: '$pangoro',
  borderRadius: '2.2rem',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  variants: {
    version: {
      newFont: {
        fontSize: '$body',
        fontFamily: '$newDefault'
      }
    }
  }
});

const tabListStyleFlat = css({
  borderRadius: 0,
  width: '100%',
  transform: 'none',
  position: 'relative',
  left: 0,
  top: 0,
  padding: 0,
  backgroundColor: 'transparent',
  display: 'flex',
  boxShadow: 'inset 0 -0.1rem 0 $pancham',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  variants: {
    version: {
      newFont: {
        fontSize: '$body',
        fontFamily: '$newDefault'
      }
    }
  }
});

const tabStyleRounded = css({
  padding: '0 $xxxlarge',
  height: '3.6rem',
  lineHeight: '3.6rem',
  borderRadius: '1.8rem',
  fontWeight: 'bold',
  cursor: 'pointer',

  '&[aria-selected="true"]': {
    backgroundColor: '$carkol',
    color: '$primary500'
  }
});

const tabStyleFlat = css({
  color: '$rhyhorn',
  padding: '$medium 0',
  textAlign: 'center',
  cursor: 'pointer',
  fontWeight: 'bold',
  flexBasis: '0%',
  flexGrow: 1,
  flexShrink: 1,
  borderBottom: '0.4rem solid transparent',
  transition: '0.25s all ease',

  '&:hover': {
    color: '$primary400'
  },
  '&:focus': {
    color: '$primary400'
  },

  '&[aria-selected="true"]': {
    color: '$primary500',
    borderBottom: '0.4rem solid $primary500'
  },

  '&[aria-disabled="true"]': {
    color: '$rhyhorn',
    border: 'none',
    '&:hover': {
      color: '$rhyhorn',
      cursor: 'default'
    },
    '&:focus': {
      outline: 'none'
    }
  }
});

const tabPanelStyle = css({
  '&:not(:empty)': {
    marginTop: '2.2rem'
  }
});
