import { styled, CSS } from '@parsec/styles';

import Warp from 'lib/images/header/warp.svg';

const Logo = styled('svg', {
  width: '14rem'
});

export default function WarpLogo(props: { css?: CSS }) {
  return <Logo as={Warp} css={props.css} />;
}
