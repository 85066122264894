import { useCallback, useState } from 'react';

import {
  intervalToPlan,
  WarpInterval,
  WarpPlanID,
  WarpSubscription,
  CreateWarpSubscriptionReq
} from '@parsec/kessel';
import { useCreateWarpSubscription } from '@parsec/queries';
import { parseError } from '@parsec/request';

import useWarpPricingState from './useWarpPricingState';

export default function useCreateModalState(props: {
  subscription?: WarpSubscription;
}) {
  const { subscription } = props;

  const [step, setStep] = useState('pricing');
  const [error, setError] = useState('');

  const currentPlanId = subscription?.plan_id as WarpPlanID;
  const createWarpSubscription = useCreateWarpSubscription();

  const [req, setReq] = useState<CreateWarpSubscriptionReq>({
    plan_id: intervalToPlan(WarpInterval.Yearly),
    token: '',
    customer: {
      email: '',
      billing_address: { line1: '', city: '', state: '', country: '', zip: '' }
    },
    coupon: ''
  });

  const onCreateSubscription = async () => {
    //only one step because this endpoint is making the card connection for us
    try {
      await createWarpSubscription.mutateAsync(req);
    } catch (err) {
      const error = parseError(err);
      setError(error.error);
    }
  };

  const updateReq = useCallback(
    (args: Partial<CreateWarpSubscriptionReq>) => {
      setReq({ ...req, ...args });
    },
    [req]
  );

  const { unitPrice, subtotal, estimate } = useWarpPricingState({
    req
  });

  return {
    step,
    req,
    error,
    unitPrice,
    loading: createWarpSubscription.isLoading,
    currentPlanId,
    subtotal,
    estimate,
    updateReq,
    setStep,
    setError,
    onCreateSubscription
  };
}
