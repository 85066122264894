import type { BillingInfoId } from 'lib/constants/formIds';

import { ReactNode } from 'react';

import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';

type BillingInfoProps<T extends FieldValues> = {
  children: ReactNode;
  onSubmit: SubmitHandler<T>;
  id: BillingInfoId;
};

export function BillingInfoForm<T extends FieldValues>(
  props: BillingInfoProps<T>
) {
  const { children, onSubmit, id } = props;

  const methods = useForm<T>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  return (
    <FormProvider {...methods}>
      <form id={id} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}
