import { useEffect } from 'react';

import AmplitudeClient from '@parsec/analytics/src/utils/AmplitudeClient';
import Link from 'next/link';
import { useForm } from 'react-hook-form';

import { pushDlSignUp, pushDlSignUpV3 } from '@parsec/analytics';
import { Button, FieldLabel, Input } from '@parsec/components';
import { styled } from '@parsec/styles';

import { Wrapper, SignUpDisclaimer } from 'components';
import { SIGNUP_USERNAME_FORM_ID } from 'lib/constants/formIds';
import { useSignup } from 'lib/hooks/useSignup';
import { getJourneyTrigger } from 'lib/util/journey-util';

import Actions from '../Actions';
import FormContainer from '../Form';

import signupHero from 'lib/images/signup@2x.png';

const PromptContainer = styled('div', {
  margin: '$xxlarge 0'
});

interface Props {
  email?: string;
  title: string;
  usernamePrompt: ReactNode;
  emailProvided?: boolean;
  showLoginLink?: boolean;
}

const INPUT_MAX_LENGTH = 255;

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface FormValues {
  email: string;
  username: string;
}

// same as StyledForm in Password Step
const StyledForm = styled('form', {
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',
  '@large': {
    width: '38rem'
  }
});

const DEFAULT_TITLE = 'Sign up for Parsec';

const DEFAULT_USERNAME_PROMPT = (
  <p>
    Welcome to Parsec! We&apos;re so glad you&apos;re here. Enter your email
    address and a username to get started.
  </p>
);

export default function UsernameStep({
  showLoginLink = false,
  ...props
}: Props) {
  const {
    title = DEFAULT_TITLE,
    usernamePrompt = DEFAULT_USERNAME_PROMPT,
    emailProvided
  } = props;

  const { userData, setUserData, send, data, teamData, savedUrlParams } =
    useSignup();
  const email = data?.email || userData.email;
  const { username } = userData;
  const trigger = getJourneyTrigger(savedUrlParams);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid }
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    defaultValues: {
      email,
      username
    }
  });

  const { isTeam, isTeamAdmin } = teamData;

  useEffect(() => {
    // only run when the component is mounted
    if (isTeam || isTeamAdmin) {
      pushDlSignUp({ step: '1.1', isTeam, isTeamAdmin, trigger });
    } else {
      pushDlSignUpV3({ step: '1.1', trigger });
    }

    // Reset any user properties that may remain
    AmplitudeClient().reset();
  }, [isTeam, isTeamAdmin, trigger]);

  const onSubmit = handleSubmit(values => {
    if (isTeam || isTeamAdmin) {
      pushDlSignUp({ step: '1.2', isTeam, isTeamAdmin, trigger });
    } else {
      pushDlSignUpV3({ step: '1.2', trigger });
    }
    setUserData(userData => ({ ...userData, ...values }));
    send({
      type: 'SET_EMAIL_USERNAME'
    });
  });

  return (
    <Wrapper layout="spread" spacer={showLoginLink ? undefined : 'top'}>
      {showLoginLink ? (
        <p>
          Already have an account? <Link href="/login">Log in</Link>
        </p>
      ) : null}
      <FormContainer
        title={title}
        hero={signupHero}
        alignTitle="center"
        id={SIGNUP_USERNAME_FORM_ID}
        className="hs-signup_username"
      >
        <StyledForm onSubmit={onSubmit}>
          <PromptContainer>{usernamePrompt}</PromptContainer>

          <FieldLabel>
            <FieldLabel.Label label="Email">
              <Input
                type="email"
                autoComplete="email"
                placeholder="ash@parsec.app"
                autoFocus
                disabled={emailProvided && Boolean(email)}
                {...register('email', {
                  required: 'required',
                  maxLength: INPUT_MAX_LENGTH,
                  pattern: EMAIL_REGEX
                })}
              />
            </FieldLabel.Label>
            <FieldLabel.HelperTextContainer>
              <FieldLabel.Message>Now with 100% less spam!</FieldLabel.Message>
              <FieldLabel.Count
                count={watch('email', '').length}
                maxCount={INPUT_MAX_LENGTH}
              />
            </FieldLabel.HelperTextContainer>
          </FieldLabel>
          <FieldLabel>
            <FieldLabel.Label label="Username">
              <Input
                autoComplete="username"
                placeholder="LanSolo1138"
                {...register('username', {
                  required: 'required',
                  maxLength: INPUT_MAX_LENGTH
                })}
              />
            </FieldLabel.Label>
            <FieldLabel.HelperTextContainer>
              <FieldLabel.Count
                count={watch('username', '')?.length}
                maxCount={INPUT_MAX_LENGTH}
              />
            </FieldLabel.HelperTextContainer>
          </FieldLabel>
          <Actions css={{ marginTop: '$xlarge' }}>
            <Button
              wide
              type="submit"
              id="submit_signup_email_name"
              disabled={!isValid || isSubmitting}
            >
              Next
            </Button>
          </Actions>
        </StyledForm>
      </FormContainer>
      <SignUpDisclaimer />
    </Wrapper>
  );
}
