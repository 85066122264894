export interface GraphAction {
  type: string;
  goto?: string;
  navigate?: string;
  default?: boolean;
}

export interface GraphDataItem {
  actions?: GraphAction[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [i: string]: any };
}

export interface GraphData {
  [key: string]: GraphDataItem;
}

export enum SignupCtaFlow {
  Standard = 'standard', // default
  Teams = 'teams',
  Warp = 'warp'
}
