// @parsec

import { useRef } from 'react';

import { schema, TeamGroup, teamGroup } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useTeamMemberData } from './teamMember';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_group');

export function useTeamGroupsData() {
  return useQueryData(key.all(), schema.paginatedTeamGroupData);
}

/******************************************************************************
 * GET ALL TEAM GROUPS in the auth user's team
 ******************************************************************************/

export function useGetTeamGroups() {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = {
    team_id: me.data?.team_id ?? '',
    offset: 0,
    limit: 200
  };

  const enabled = Boolean(me.data?.team_id);

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamGroup.getTeamGroups(req);
      if (!!res.body?.data) {
        // Sort: alphanumeric
        res.body?.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      return res.body;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team groups."
  });
  return { ...result, error };
}

/******************************************************************************
 * GET ALL EXHAUSTIVE COMPLETE TEAM GROUPS in the auth user's team
 ******************************************************************************/

export function useGetAllTeamGroups() {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = {
    team_id: me.data?.team_id ?? '',
    offset: 0,
    limit: 200
  };

  const varsRef = useRef(vars);
  const keepPreviousData = varsRef.current.team_id === vars.team_id;
  varsRef.current = vars;

  const enabled = Boolean(me.data?.team_id);

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);

      let count = 0;
      let groups: TeamGroup[] = [];
      let offset = 0;
      const limit = 200;
      let maxRequests = 100;
      while (true) {
        const res = await kessel.teamGroup.getTeamGroups({
          ...req,
          offset,
          limit
        });
        count = res.body.count;
        groups = groups.concat(res.body.data);
        offset += limit;
        if (groups.length >= count) break;
        if (--maxRequests <= 0) break;
      }

      return { data: groups, count };
    },
    { enabled, keepPreviousData }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get team groups."
  });
  return { ...result, error };
}

/******************************************************************************
 * GET TEAM GROUP
 ******************************************************************************/

/** Fetches a group in the auth user's team */
export function useGetGroup(group_id: number) {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = {
    team_id: me.data?.team_id ?? '',
    group_id
  };

  const enabled = vars.team_id !== '' && group_id !== 0;

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamGroup.getTeamGroup(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team group."
  });
  return { ...result, error };
}

type CreateGroupVars = Omit<
  teamGroup.CreateTeamGroupReq & {
    incoming: number[];
    outgoing: number[];
  },
  'team_id'
>;

/******************************************************************************
 * CREATE TEAM GROUP
 ******************************************************************************/

export function useCreateGroup() {
  const kessel = useKessel();
  const teamGroupCache = useTeamGroupsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: CreateGroupVars) {
    const res = await kessel.teamGroup.createTeamGroup({
      team_id: teamId,
      name: reqBody.name,
      allow_connections_within_group: reqBody.allow_connections_within_group,
      show_group_assigned_managed_hosts:
        reqBody.show_group_assigned_managed_hosts,
      show_user_assigned_managed_hosts:
        reqBody.show_user_assigned_managed_hosts,
      show_personal_hosts: reqBody.show_personal_hosts
    });

    return res.body?.data;
  }

  function onSuccess() {
    teamGroupCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't create team group."
  });
  return { ...result, error };
}
/******************************************************************************
 * UPDATE TEAM GROUP
 ******************************************************************************/

type UpdateGroupVars = Omit<teamGroup.UpdateTeamGroupReq, 'team_id'>;

export function useUpdateGroup() {
  const kessel = useKessel();
  const teamGroupCache = useTeamGroupsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: UpdateGroupVars) {
    return await kessel.teamGroup.updateTeamGroup({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamGroupCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't update team group."
  });
  return { ...result, error };
}

/******************************************************************************
 * DELETE TEAM GROUP
 ******************************************************************************/

type DeleteGroupVars = Omit<teamGroup.DeleteTeamGroupReq, 'team_id'>;

export function useDeleteGroup() {
  const kessel = useKessel();
  const teamGroupCache = useTeamGroupsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: DeleteGroupVars) {
    return await kessel.teamGroup.deleteTeamGroup({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamGroupCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't delete team group."
  });
  return { ...result, error };
}

/******************************************************************************
 * BULK ADD TEAM GROUP MEMBERS
 ******************************************************************************/

type BulkUpdateGroupMembershipsVars = Omit<
  teamGroup.BulkUpdateGroupMembershipsReq,
  'team_id'
>;

// This method uses the v2 request
export function useBulkUpdateGroupMemberships() {
  const kessel = useKessel();
  const teamGroupCache = useTeamGroupsData();
  const teamMemberCache = useTeamMemberData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: BulkUpdateGroupMembershipsVars) {
    return await kessel.teamGroup.BulkUpdateGroupMemberships({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamGroupCache.invalidate();
    teamMemberCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "Couldn't update team group memberships."
  });
  return { ...result, error };
}
