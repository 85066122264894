import { ReactElement, ReactNode, RefObject, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import { styled, CSS } from '@parsec/styles';

const TooltipWrapper = styled('div', {
  pointerEvents: 'none',
  background: '$computerBlack',
  borderRadius: '$small',
  border: '0.2rem solid $perfectGray',
  padding: '$medium $xlarge',
  position: 'absolute',
  width: 'max-content',
  zIndex: 5,
  variants: {
    hidden: {
      true: {
        display: 'none'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.4rem'
      }
    }
  }
});

export const TOOLTIP_CONTAINER_ID = 'popovers';

const isTextOverflowing = (el?: Element | null) => {
  const clientWidth = el?.clientWidth ?? 0;
  const scrollWidth = el?.scrollWidth ?? 0;
  return clientWidth < scrollWidth;
};

export interface TooltipProps {
  children: ReactNode;
  tooltipText: React.ReactNode;
  triggerRef?: RefObject<Element> | null;
  showOnOverflow?: boolean;
  triggerStyle?: React.CSSProperties;
  wrapperStyle?: CSS;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end';
  disabled?: boolean;
  version?: 'newFont';
}

interface RenderInPortalProps {
  children: ReactElement;
  portal: HTMLElement | undefined;
}

const RenderInPortal = ({ children, portal }: RenderInPortalProps) => {
  if (!portal) {
    return children;
  }

  return createPortal(children, portal);
};

const Tooltip = (props: TooltipProps) => {
  const {
    triggerRef,
    children,
    tooltipText,
    showOnOverflow = false,
    placement = 'bottom',
    disabled = false,
    triggerStyle,
    wrapperStyle,
    version
  } = props;

  const [showOverflow, setShowOverflow] = useState(false);

  const portal =
    typeof document !== 'undefined'
      ? document.getElementById(TOOLTIP_CONTAINER_ID) ?? undefined
      : undefined;

  const text = !showOnOverflow || showOverflow ? tooltipText : '';

  useEffect(() => {
    if (showOnOverflow && triggerRef)
      setShowOverflow(isTextOverflowing(triggerRef.current));
  }, [triggerRef, showOnOverflow]);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    trigger: disabled || !text ? null : 'hover',
    placement
  });

  return (
    <>
      {/* trigger element */}
      <div ref={setTriggerRef} style={triggerStyle}>
        {children}
      </div>

      {visible && (
        <RenderInPortal portal={portal}>
          {/* tooltip element */}
          <TooltipWrapper
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip-container' })}
            version={version}
            css={wrapperStyle}
          >
            {text}
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </TooltipWrapper>
        </RenderInPortal>
      )}
    </>
  );
};

export default Tooltip;
