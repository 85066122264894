import { styled } from '@parsec/styles';

const ButtonSpan = styled('span', {
  display: 'inline-grid',
  alignItems: 'center',
  gridAutoFlow: 'column',
  gap: '1rem'
});

export default ButtonSpan;
