import { useEffect, useState } from 'react';

const LOCAL_KEY = 'isInSignupFlow';

export function useIsInSignupFlow() {
  const [isInSignupFlow, setIsInSignup] = useState(true);

  useEffect(() => {
    const stickyValue = window.localStorage.getItem(LOCAL_KEY);
    const parsedValue = stickyValue !== null ? JSON.parse(stickyValue) : false;
    setIsInSignup(Boolean(parsedValue));
  }, [setIsInSignup]);

  const setIsInSignupFlow = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem(LOCAL_KEY, 'true');
    }
  };

  const removeIsInSignup = () => {
    if (typeof window !== undefined) {
      window.localStorage.removeItem(LOCAL_KEY);
    }
  };

  return { isInSignupFlow, setIsInSignupFlow, removeIsInSignup };
}
