import { useState } from 'react';

//Components
import { Time, styled, Button, LegacyModal } from '@parsec/components';
import { useCancelWarpSubscription } from '@parsec/queries';

import { WarpLogo } from 'components';

//Kessel

interface Props {
  isOpen: boolean;
  subPrice?: number;
  subInterval?: 'month' | 'year';
  subNextBillingDate?: string;
  onClose(): void;
  onError(message: string): void;
  trial?: boolean;
  trialEnd?: Date;
}

export default function UnsubscribeModal(props: Props) {
  const { isOpen, onClose, onError, trial = false } = props;
  const [step, setStep] = useState(1);
  const cancelWarpSubscription = useCancelWarpSubscription();

  if (step === 1) {
    return (
      <LegacyModal
        title={`Are you sure you want to ${
          trial ? 'cancel your Warp trial?' : 'unsubscribe from Warp?'
        }`}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={() => setStep(1)}
      >
        <Wrapper>
          <UnsubscribeStep {...props} />
        </Wrapper>

        <LegacyModal.Actions>
          <Button
            kind="error"
            disabled={cancelWarpSubscription.isLoading}
            onClick={async () => {
              try {
                await cancelWarpSubscription.mutateAsync();
              } catch (_err) {
                onError(`Couldn't cancel ${trial ? 'trial' : 'subscription'}`);
                onClose();
              }
              setStep(2);
            }}
          >
            Yes, {trial ? 'Cancel' : 'Unsubscribe'}
          </Button>
          <Button onClick={onClose} level="secondary">
            Back
          </Button>
        </LegacyModal.Actions>
      </LegacyModal>
    );
  } else {
    return (
      <LegacyModal
        title=""
        description={
          <>
            <WarpLogo css={{ width: '13.4rem' }} />
            <h4>Warp misses you already.</h4>
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
        onOpen={() => setStep(1)}
      >
        <SuccessBody data-testid="success">
          {trial
            ? "You've successfully cancelled your trial. After the 14 day trial period ends, you will be back to using plain ol' Parsec"
            : `You’ve successfully unsubscribed from Warp. After the billing period ends, you’ll be back to using plain ol’ Parsec. Remember: you can re-enroll in Warp at any time.`}
        </SuccessBody>
        <LegacyModal.Actions>
          <Button onClick={onClose}>Got It</Button>
        </LegacyModal.Actions>
      </LegacyModal>
    );
  }
}

interface UnsubscribeStepProps {
  subPrice?: number;
  subInterval?: 'month' | 'year';
  subNextBillingDate?: string;
  trial?: boolean;
  trialEnd?: Date;
}

function UnsubscribeStep(props: UnsubscribeStepProps) {
  const {
    subPrice,
    subInterval,
    subNextBillingDate,
    trial = false,
    trialEnd
  } = props;

  const endDate = trial ? trialEnd : subNextBillingDate;

  return (
    <>
      {endDate && (
        <div>
          <p>
            You&apos;ll keep all your Warp perks until{' '}
            <strong>
              <Time date={new Date(endDate)} />
            </strong>
            {!trial ? ', the end of your current billing period.' : '.'}
          </p>
          <br />
          <p>
            After that, you&apos;ll lose your Warp features and won&apos;t be
            charged{!trial && ' again'}.
          </p>
        </div>
      )}

      <Actions>
        <Stretchy>
          <h4>Subscription Updates</h4>
          <Plan>
            <PlanPrice>
              <PlanLabel>
                Parsec Warp {`${subInterval}ly `}
                cancelled
              </PlanLabel>
              <span>
                {subPrice && formatCurrency(subPrice / 100)}/{subInterval}
              </span>
            </PlanPrice>
          </Plan>
          {!trial && (
            <Disclaimer>
              Remember: you can restart your subscription at any time. Hope
              you&apos;ll be back.
            </Disclaimer>
          )}
        </Stretchy>
      </Actions>
    </>
  );
}

const Plan = styled('div', {
  marginTop: '$small',
  display: 'flex',
  alignItems: 'center',
  color: '$error500'
});

const PlanPrice = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between'
});

const PlanLabel = styled('span', {
  fontWeight: 'bold'
});

const Actions = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto 1fr',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: '"text text text" "unsubscribe keep ."',
  gridGap: '1.2rem',
  justifyContent: 'start',
  justifyItems: 'start',
  backgroundColor: '$carkol'
});

const Stretchy = styled('div', {
  gridArea: 'text',
  justifySelf: 'stretch',
  padding: '$xlarge'
});

const Disclaimer = styled('p', {
  marginTop: '$xxlarge',
  color: '#f9fafc4c'
});

const SuccessBody = styled('p', {
  marginTop: '$xxxlarge'
});

function formatCurrency(amount: number, decimal = true) {
  const string = amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return decimal ? string : string.slice(0, -3);
}

const Wrapper = styled('div', {
  display: 'grid',
  gap: '$xlarge'
});
