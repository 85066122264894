// libraries
import { useCallback, useState } from 'react';

// @parsec
import { Modal, TfaConfirm, TfaBackupCodes, Button } from '@parsec/components';
import { useCreateTFASetupURI, useEnableTFA } from '@parsec/queries';
import { parseError } from '@parsec/request';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

export default function EnableTfaModal(props: Props) {
  const { isOpen, onClose, onAfterClose } = props;

  const [isValid, setIsValid] = useState(false);
  const [codes, setCodes] = useState<string[]>([]);

  const handleDone = useCallback(
    (backups: string[]) => {
      if (!backups.length) return onClose();
      setCodes(backups);
    },
    [onClose]
  );

  const onValidate = useCallback((isValid: boolean) => {
    setIsValid(isValid);
  }, []);

  return (
    <Modal
      title="Enable Two-Factor Authentication"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
    >
      {codes.length ? (
        <div>
          <TfaBackupCodes id="tfa_backup_form" codes={codes} onDone={onClose} />
          <Modal.Actions>
            <Button form="tfa_backup_form">Download Codes</Button>
            <Button level="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Actions>
        </div>
      ) : (
        <div>
          <Confirm onDone={handleDone} onValidate={onValidate} />
          <Modal.Actions>
            <Button form="tfa_confirm_form" disabled={!isValid}>
              Enable 2FA
            </Button>
            <Button level="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Actions>
        </div>
      )}
    </Modal>
  );
}

function Confirm(props: {
  onDone(backups: string[]): void;
  onValidate(isValid: boolean): void;
}) {
  const { onDone, onValidate } = props;

  const createTfaSetupURI = useCreateTFASetupURI(),
    { mutateAsync: setupMutate } = createTfaSetupURI;

  const handleSetup = useCallback(async () => {
    try {
      const res = await setupMutate();
      return res.data;
    } catch (e) {
      const error = parseError(e);
      if (error.status === 409) onDone([]);
      throw e;
    }
  }, [setupMutate, onDone]);

  const enableTFA = useEnableTFA(),
    { mutateAsync: enableMutate } = enableTFA;

  const handleConfirm = useCallback(
    async (code: string, password: string, email?: string) => {
      const res = await enableMutate({ code, password, email });
      onDone(res.filter(code => !code.used).map(code => code.code));
    },
    [enableMutate, onDone]
  );

  return (
    <TfaConfirm
      id="tfa_confirm_form"
      onSetup={handleSetup}
      onConfirm={handleConfirm}
      onValidate={onValidate}
    />
  );
}
