// @parsec
import {
  CreateTeamMachinesReq,
  DeleteTeamMachinesReq,
  AssignTeamMachineReq as AssignTeamMachineReq,
  UpdateTeamMachineReq,
  UnassignTeamMachineReq
} from '@parsec/kessel/src/teamMachine';

import { schema, teamMachine } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useGetTeamRolePermissionSummary } from './permission';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_machine');

export function useAllTeamMachinesData() {
  return useQueryData(key.all(), schema.paginatedMachineData);
}

/******************************************************************************
 * Get all Team Machines
 ******************************************************************************/

export function useGetAllTeamMachines(
  opts: Omit<teamMachine.GetTeamMachinesReq, 'team_id'> & { skip?: boolean }
) {
  const kessel = useKessel();
  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const perms = useGetTeamRolePermissionSummary();
  const groupPermissions = perms.data?.groups ?? {};

  const hasGroupManageTeamComputerPermission = Object.keys(
    groupPermissions
  ).some(key => groupPermissions[key].manage_team_computers);

  const hasAnyPermission =
    !!perms.data?.team.manage_team_computers ||
    !!perms.data?.team.manage_guest_access_invites ||
    !!hasGroupManageTeamComputerPermission;

  const vars = { ...opts, team_id: teamId };

  const enabled = vars.team_id !== '' && hasAnyPermission && !opts.skip;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamMachine.getTeamMachines(req);
      return res.body;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get team computers.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Create Team Machine
 ******************************************************************************/

export function useCreateTeamMachine() {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';
  const teamMachinesCache = useAllTeamMachinesData();

  async function mutationFn(reqBody: Omit<CreateTeamMachinesReq, 'team_id'>) {
    return await kessel.teamMachine.createTeamMachine({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamMachinesCache.invalidate();
  }
  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to create team computer.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Update Team Machine
 ******************************************************************************/

export function useUpdateTeamComputer() {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';
  const teamMachinesCache = useAllTeamMachinesData();

  async function mutationFn(reqBody: Omit<UpdateTeamMachineReq, 'team_id'>) {
    return await kessel.teamMachine.updateTeamMachine({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamMachinesCache.invalidate();
  }
  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to update team computer.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Delete Team Machine
 ******************************************************************************/

export function useDeleteTeamComputer() {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';
  const teamMachinesCache = useAllTeamMachinesData();

  async function mutationFn(reqBody: Omit<DeleteTeamMachinesReq, 'team_id'>) {
    await kessel.teamMachine.deleteTeamMachine({ ...reqBody, team_id: teamId });
  }

  function onSuccess() {
    teamMachinesCache.invalidate();
  }
  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to delete team computer.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Assign TeamMachine
 ******************************************************************************/

export function useAssignTeamComputerV2() {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';
  const teamMachinesCache = useAllTeamMachinesData();

  async function mutationFn(reqBody: Omit<AssignTeamMachineReq, 'team_id'>) {
    return await kessel.teamMachine.assignTeamMachine({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamMachinesCache.invalidate();
  }
  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to assign team computer.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Unassign Team Machine
 ******************************************************************************/

export function useUnassignTeamComputer() {
  const kessel = useKessel();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';
  const teamMachinesCache = useAllTeamMachinesData();

  // Unassign a computer is equivalent to assign a computer to no user, email, or groups.
  async function mutationFn(reqBody: Omit<UnassignTeamMachineReq, 'team_id'>) {
    return await kessel.teamMachine.assignTeamMachine({
      machine_id: reqBody.machine_id,
      team_id: teamId,
      user_id: null,
      email: null,
      team_group_ids: null
    });
  }

  function onSuccess() {
    teamMachinesCache.invalidate();
  }
  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to unassign team computer.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Kick Guest From Team Machine
 ******************************************************************************/

export function useKickTeamMachineGuest() {
  const kessel = useKessel();
  const teamMachinesCache = useAllTeamMachinesData();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(req: { machine_id: string; guest_id: number }) {
    return await kessel.teamMachine.kickTeamMachineGuest({
      ...req,
      team_id: teamId
    });
  }

  function onSuccess() {
    teamMachinesCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'Failed to kick user from machine.'
  });
  return { ...result, error };
}
