// Password input component for Signup flow
import { useEffect, useState } from 'react';

import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { type BoundTurnstileObject } from 'react-turnstile';
import * as z from 'zod';

import { pushDlSignUp, pushDlSignUpV3 } from '@parsec/analytics';
import {
  Button,
  CaptchaActions,
  CaptchaWidget,
  FieldLabel,
  Input,
  PasswordInput,
  createPasswordInputSchema,
  styled
} from '@parsec/components';
import { useSignUp } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { SignUpDisclaimer, Wrapper } from 'components';
import { TURNSTILE_SITE_KEY } from 'lib/config';
import { SIGNUP_PASSWORD_FORM_ID } from 'lib/constants/formIds';
import { useSignup as useSignupProvider } from 'lib/hooks/useSignup';
import { getJourneyTrigger } from 'lib/util/journey-util';

import Actions from '../Actions';
import Form from '../Form';

import passwordHero from 'lib/images/password@2x.png';

/** ========= STYLES ========= */

const OptionalSpan = styled('span', {
  color: '$rhyhorn'
});

const Span = styled('span', {
  flex: 1
});

const BackButton = styled('button', {
  color: '$consoleWhite',
  fontWeight: 'bold',
  cursor: 'pointer'
});

const UmaLabel = styled('label', {
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
  columnGap: '1.2rem'
});

const StyledCheckbox = styled(Input, {
  '&  input': {
    boxShadow: 'none',
    height: '100%'
  }
});

const StyledForm = styled('form', {
  marginTop: '3rem',
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',
  '@large': {
    width: '38rem'
  }
});

/** ========= COMPONENTS ========= */
interface Props {
  teamId?: string;
  inviteToken?: string;
  passwordPrompt: ReactNode;
}

interface FormValues {
  password: string;
  repeat_password: string;
  marketing_opt_in: boolean;
  captcha_error: string;
  captcha_token: string;
  api_error: string;
}

export default function PasswordStep(props: Props) {
  const { passwordPrompt } = props;
  const signUp = useSignUp();

  const { send, teamData, userData, setUserData, savedUrlParams } =
    useSignupProvider();
  const [captchaBound, setCaptchaBound] = useState<BoundTurnstileObject>();
  const trigger = getJourneyTrigger(savedUrlParams);

  const formSchema = z
    .object({
      password: z.string().min(1),
      repeat_password: z.string(),
      // Token must be set to some value.
      // The input is automatically filled in when the captcha is solved.
      captcha_token: z.string().min(1),
      marketing_opt_in: z.boolean()
    })
    .merge(createPasswordInputSchema('password'))
    .refine(
      ({ password }) =>
        password.toLowerCase() !== userData.email?.toLowerCase() &&
        password.toLowerCase() !== userData.username?.toLowerCase(),
      {
        message: 'Your password cannot be your email address or username.',
        path: ['password']
      }
    )
    .refine(({ password, repeat_password }) => password === repeat_password, {
      message: "Passwords don't match",
      path: ['repeat_password']
    });

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: zodResolver(formSchema)
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    register,
    setValue,
    getValues,
    setError
  } = formMethods;

  const { username, email } = userData;
  const { isTeam, isTeamAdmin } = teamData;

  const onSubmit = handleSubmit(async values => {
    try {
      if (isTeam || isTeamAdmin) {
        pushDlSignUp({ step: '1.4', isTeam, isTeamAdmin, trigger });
      } else {
        pushDlSignUpV3({ step: '1.4', trigger });
      }

      setUserData(userData => ({
        ...userData,
        password: values.password,
        marketing_opt_in: values.marketing_opt_in
      }));

      if (email === undefined || username === undefined) {
        throw new Error('Missing email or username');
      }

      await signUp.mutateAsync({
        email: email,
        name: username,
        password: values.password,
        marketing_opt_in: values.marketing_opt_in ? true : null,
        captcha_token: getValues('captcha_token')
      });

      // Successfully submitted
      if (isTeam || isTeamAdmin) {
        pushDlSignUp({ step: '1.4.1', isTeam, isTeamAdmin, trigger });
      } else {
        pushDlSignUpV3({ step: '1.4.1', trigger });
      }
    } catch {
      // noop, error captured on mutation result
      return;
    } finally {
      captchaBound?.reset();
    }

    send({
      type: 'SUBMITTED_SIGNUP'
    });
  });

  useEffect(() => {
    if (isTeam || isTeamAdmin) {
      pushDlSignUp({ step: '1.3', isTeam, isTeamAdmin, trigger });
    } else {
      pushDlSignUpV3({ step: '1.3', trigger });
    }
  }, [isTeam, isTeamAdmin, trigger]);

  useEffect(() => {
    if (signUp.error) {
      const error = parseError(signUp.error, { error: "Couldn't sign up" });
      // dataLayer password submit error | failure
      if (isTeam || isTeamAdmin) {
        pushDlSignUp({
          step: '1.4.2',
          isTeam,
          isTeamAdmin,
          stepMsg: error.error,
          trigger
        });
      } else {
        pushDlSignUpV3({ step: '1.4.2', stepMsg: error.error, trigger });
      }

      setError('api_error', {
        type: 'api',
        message: error.error
      });
    }
  }, [setError, signUp.error, teamData, isTeam, isTeamAdmin, trigger]);

  return (
    <FormProvider {...formMethods}>
      <Wrapper layout="spread" spacer="top">
        <Form
          title="Set a password"
          alignTitle="center"
          id={SIGNUP_PASSWORD_FORM_ID}
          className="hs-signup"
          hero={passwordHero}
        >
          <StyledForm onSubmit={onSubmit}>
            <p>{passwordPrompt}</p>
            <FieldLabel>
              <FieldLabel.Label label="Password">
                <PasswordInput
                  identifier="password"
                  autoComplete="new-password"
                />
              </FieldLabel.Label>
            </FieldLabel>
            <FormErrorMessage
              errors={errors}
              name="password"
              render={({ messages }) =>
                messages &&
                Object.entries(messages)
                  .filter(([type, _]) => type === z.ZodIssueCode.custom)
                  .map(([type, message]) => (
                    <FieldLabel.HelperTextContainer key={type}>
                      <FieldLabel.Message role="alert" hasError>
                        {message}
                      </FieldLabel.Message>
                    </FieldLabel.HelperTextContainer>
                  ))
              }
            />
            <FieldLabel>
              <FieldLabel.Label label="Repeat password">
                <Input
                  type="password"
                  autoComplete="new-password"
                  placeholder="************"
                  {...register('repeat_password')}
                />
              </FieldLabel.Label>
            </FieldLabel>
            <FormErrorMessage
              errors={errors}
              name="repeat_password"
              render={({ message }) => (
                <FieldLabel.HelperTextContainer>
                  <FieldLabel.Message role="alert" hasError>
                    {message}
                  </FieldLabel.Message>
                </FieldLabel.HelperTextContainer>
              )}
            />
            <UmaLabel>
              <StyledCheckbox
                type="checkbox"
                {...register('marketing_opt_in')}
              />
              <Span>
                I agree to have{' '}
                <a
                  href="https://create.unity.com/marketingactivities?_ga=2.36720550.708690487.1685630706-1605599102.1672861974"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marketing Activities
                </a>{' '}
                directed to me by and receive marketing and promotional
                information from Unity, including via email and social media{' '}
                <OptionalSpan>(optional)</OptionalSpan>.
              </Span>
            </UmaLabel>
            <CaptchaWidget
              sitekey={TURNSTILE_SITE_KEY}
              onCaptchaVerify={(token: string) => {
                setValue('captcha_token', token, { shouldValidate: true });
              }}
              onCaptchaLoad={(_, bound) => setCaptchaBound(bound)}
              action={CaptchaActions.SetPassword}
            />
            {/* Using a hidden captcha input so we can have all validation run through RHF / zod */}
            <Input type="hidden" {...register('captcha_token')} />
            <FormErrorMessage
              errors={errors}
              name="api_error"
              render={({ message }) => (
                <FieldLabel.HelperTextContainer>
                  <FieldLabel.Message role="alert" hasError>
                    {message}
                  </FieldLabel.Message>
                </FieldLabel.HelperTextContainer>
              )}
            />
            <Actions css={{ marginTop: '$xlarge' }}>
              <BackButton
                type="button"
                onClick={() => send({ type: 'BACK_TO_USERNAME' })}
              >
                Back
              </BackButton>
              <Button
                wide
                id="submit_signup_password"
                disabled={Boolean(isSubmitting || !isValid)}
              >
                Create Account
              </Button>
            </Actions>
          </StyledForm>
        </Form>
        <SignUpDisclaimer />
      </Wrapper>
    </FormProvider>
  );
}
