// libraries
import { useEffect, useState } from 'react';

import { useDropzone, FileRejection } from 'react-dropzone';

// @parsec
import {
  styled,
  Avatar,
  Button,
  Icon,
  ErrorMessage,
  Modal,
  Input
} from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import { useUpdateAvatar } from '@parsec/queries';

// Maximum accepted file size for Avatar image in bytes
export const AVATAR_MAX_SIZE = 800000;

export const FILE_TOO_LARGE_ERROR = `File is too large. Maximum file size of ${
  AVATAR_MAX_SIZE * 0.001
} kb`;

const Form = styled('form', {
  display: 'grid',
  rowGap: '$medium',
  justifyItems: 'center'
});

const CurrentAvatar = styled(Avatar, {
  width: '16rem',
  height: '16rem'
});

const NewAvatar = styled('img', {
  borderRadius: '50%',
  width: '16rem',
  height: '16rem',
  objectFit: 'cover'
});

interface Props {
  userId: number;
  nonce?: string;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

export default function ChangeAvatarModal(props: Props) {
  const { userId, nonce, isOpen, onClose, onAfterClose } = props;
  const [avatar, setAvatar] = useState<{ file: File; preview: string }>();
  const [error, setError] = useState<string | null>(null);

  const updateAvatar = useUpdateAvatar();

  const updateAvatarError = updateAvatar.error?.error;

  useEffect(() => {
    if (updateAvatarError) {
      setError(updateAvatarError);
    }
  }, [updateAvatarError]);

  const onDrop = (accepted: File[], rejected: FileRejection[]) => {
    if (rejected.length) {
      const fileSize = rejected[0].file.size;

      if (fileSize > AVATAR_MAX_SIZE) {
        setError(FILE_TOO_LARGE_ERROR);
      }
    }

    if (accepted.length) {
      setAvatar({
        file: accepted[0],
        preview: URL.createObjectURL(accepted[0])
      });
      setError(null);
    } else {
      setAvatar(undefined);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
    maxSize: AVATAR_MAX_SIZE
  });

  return (
    <Modal
      title="Profile Picture"
      size="small"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      actions={[
        { text: 'Confirm & Save', form: 'update_avatar', disabled: !avatar },
        { text: 'Cancel', level: 'secondary', onClick: onClose }
      ]}
    >
      <Form
        id="update_avatar"
        onSubmit={handleFormSubmit(async () => {
          if (!avatar) return;

          await updateAvatar.mutateAsync({ image: avatar.file });
          onClose();
        })}
      >
        <div {...getRootProps()}>
          {avatar ? (
            <NewAvatar src={avatar.preview} alt="New avatar" />
          ) : (
            <CurrentAvatar size={160} userId={userId} nonce={nonce} />
          )}
          <Input data-testid="drop-input" {...getInputProps()} />
        </div>

        <Button
          icon={<Icon name="upload" />}
          type="button"
          level="link"
          kind="primary"
          onClick={open}
        >
          Upload New
        </Button>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </Modal>
  );
}
