import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';

import { type ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';

import { useCookie } from '@parsec/cookie';
import { runAuthRedirect } from '@parsec/redirect';

import { type GraphData } from 'components/SignUp';

export interface UserData {
  userId?: number;
  email?: string;
  username?: string;
  password?: string;
  marketing_opt_in?: boolean;
}

export interface TeamData {
  isTeam: boolean;
  isTeamAdmin: boolean;
}

export interface sendArgs {
  type: string;
}

type StepsEnum = {
  [key: string]: string;
};

type SignupContextType = {
  userData: UserData;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
  send: (args: sendArgs) => void;
  goto: (step: keyof GraphData | undefined) => void;
  teamData: TeamData;
  setGraph: React.Dispatch<React.SetStateAction<GraphData | undefined>>;
  graph?: GraphData;
  step?: keyof GraphData;
  steps: StepsEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isSignupPage: React.MutableRefObject<boolean>;
  savedUrlParams?: ParsedUrlQuery;
  setSavedUrlParams: React.Dispatch<React.SetStateAction<ParsedUrlQuery>>;
};

const Context = createContext<SignupContextType>({} as SignupContextType);

function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export interface SignupOptions {
  gotoDashWhenLoggedIn: boolean;
  watchUrlParams?: string[];
  forceUrlParams?: { [key: string]: string };
}

/**
 * Only use if you don't want any of the side-effects of regular useSignup
 * @returns SignupContext
 */
export const useSignupContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useSignupContext must be used within a <SignupProvider>');
  }
  return context;
};

export const useSignup = (signupGraph?: GraphData, options?: SignupOptions) => {
  const router = useRouter();
  const { token } = useCookie();
  const context = useContext(Context);

  if (!context) {
    throw new Error('useSignup must be used within a <SignupProvider>');
  }

  const { step, setGraph, graph, goto, send, isSignupPage, setSavedUrlParams } =
    context;
  const { query } = router;

  if (signupGraph) {
    isSignupPage.current = true;

    /* The following code is a temporary solution for the auto-advance survey experiment.
     * Remove it once the experiment is over
     */
    const query = Object.keys(router.query)[0];
    if (
      router.pathname === '/signup' &&
      query !== undefined &&
      signupGraph.ProductSelection.actions
    ) {
      signupGraph.ProductSelection.actions[1].navigate = `/setup/?${query}`;
    }
  }

  useEffect(() => {
    if (!graph && signupGraph) {
      setGraph(signupGraph);
      return;
    }

    if (!step && graph) {
      const initialStep = Object.keys(graph)[0];

      if (initialStep) {
        goto(initialStep);
      }
      return;
    }

    if (step && graph) {
      const action = graph[step].actions?.find(action => action.default);

      if (action) {
        send({ type: action.type });
      }
      return;
    }

    if (!step && !graph && Boolean(isSignupPage && !isSignupPage.current)) {
      router.push('/signup');
    }
  }, [graph, step, setGraph, signupGraph, goto, send, router, isSignupPage]);

  useEffect(() => {
    if (options?.gotoDashWhenLoggedIn && token) {
      runAuthRedirect('/');
      return;
    }
  }, [token, options]);

  useEffect(() => {
    const hasWatchUrlParams = Boolean(
      options && options.watchUrlParams && options.watchUrlParams.length > 0
    );

    const watchedParams: ParsedUrlQuery = {};

    if (query && Object.keys(query).length > 0 && hasWatchUrlParams) {
      if (typeof query !== 'string') {
        // has to be a dictionary of strings
        for (const param in query) {
          if (options?.watchUrlParams?.includes(param)) {
            watchedParams[param] = query[param];
          }
        }

        setSavedUrlParams(watchedParams);
      } // no case where it's only a string
    }

    // Force params can be used in combination with actual params
    if (
      options &&
      options.forceUrlParams &&
      Object.keys(options.forceUrlParams).length > 0
    ) {
      setSavedUrlParams({ ...watchedParams, ...options.forceUrlParams });
    }
  }, [query, options, setSavedUrlParams]);

  return context;
};

const DEFAULT_TEAM_DATA = { isTeam: false, isTeamAdmin: false };

export const SignupProvider = ({
  children,
  teamData = DEFAULT_TEAM_DATA
}: {
  children: ReactNode;
  teamData?: TeamData;
}) => {
  const router = useRouter();
  const [userData, setUserData] = useState<UserData>({} as UserData);
  const [graph, setGraph] = useState<GraphData | undefined>(undefined);
  const [step, goto] = useState<keyof GraphData | undefined>(undefined);
  const [savedUrlParams, setSavedUrlParams] = useState<ParsedUrlQuery>({});

  const isSignupPage = useRef(false);
  const teamDataRef = useRef(teamData);

  const steps = useMemo<StepsEnum>(
    () => strEnum(Object.keys(graph ?? {})),
    [graph]
  );

  const data = useMemo(() => graph?.[step ?? '']?.data ?? {}, [step, graph]);

  const send = ({ type }: sendArgs) => {
    if (!step || !graph) {
      return;
    }

    const actionStep = graph[step].actions?.find(
      action => action.type === type
    );

    const fallbackAction = Object.keys(graph).find(step =>
      graph[step].actions?.find(action => action.type === type)
    );

    if (!fallbackAction) {
      throw new Error(`No Action found with name ${type}`);
    }

    const fallbackActionStep = graph[fallbackAction].actions?.find(
      action => action.type === type
    );

    const action = actionStep ? actionStep : fallbackActionStep;

    if (action?.goto) {
      goto(action.goto);
    }

    if (action?.navigate) {
      router.push(action.navigate);
    }
  };

  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        teamData: teamDataRef.current,
        steps,
        step,
        goto,
        send,
        graph,
        setGraph,
        data,
        isSignupPage,
        savedUrlParams,
        setSavedUrlParams
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default useSignup;
