import Turnstile, { type BoundTurnstileObject } from 'react-turnstile';

import { captureException, captureMessage } from '@parsec/sentry';
import { styled } from '@parsec/styles';

import { CaptchaActions } from './types';

const StyledTurnstile = styled(Turnstile, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export interface CaptchaWidgetProps
  extends Pick<
    React.ComponentPropsWithRef<typeof Turnstile>,
    'size' | 'className' | 'style' | 'userRef' | 'id' | 'sitekey' | 'appearance'
  > {
  onCaptchaVerify(token: string): void;
  onCaptchaLoad?: (widgetId: string, bound: BoundTurnstileObject) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCaptchaError?: (error: Error | any) => void;
  onCaptchaExpire?: () => void;
  onCaptchaTimeout?: () => void;
  action: CaptchaActions;
}

const sentryContext = {
  tags: { component: 'CaptchaWidget' }
};

const CaptchaWidget = ({
  onCaptchaVerify,
  onCaptchaLoad,
  onCaptchaError,
  onCaptchaExpire,
  onCaptchaTimeout,
  action,
  userRef,
  size,
  appearance,
  className,
  style,
  sitekey,
  id
}: CaptchaWidgetProps) => {
  // https://developers.cloudflare.com/turnstile/troubleshooting/client-side-errors/#error-codes
  const IGNORED_TURNSTILE_ERROR_CODES = new Set<string>([
    // Error codes indicating an automated device / bot
    '106010',
    '300010',
    '300030',
    '300031',
    '600010',
    // Error code indicating the visitor took too long to solve the challenge
    '110600'
  ]);

  const onLoad = (widget: string, bound: BoundTurnstileObject) => {
    if (onCaptchaLoad) {
      onCaptchaLoad(widget, bound);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (error: Error | any) => {
    const reformedError =
      typeof error === 'string'
        ? new Error(`Turnstile Error: ${error}`)
        : error;

    if (
      typeof error !== 'string' ||
      !IGNORED_TURNSTILE_ERROR_CODES.has(error)
    ) {
      captureException(reformedError, sentryContext);
    }

    if (onCaptchaError) {
      onCaptchaError(error);
    }
  };

  const onExpire = () => {
    captureMessage('Turnstile: token expired', sentryContext);

    if (onCaptchaExpire) {
      onCaptchaExpire();
    }
  };

  const onTimeout = () => {
    captureMessage('Turnstile: timeout, challenge expired', sentryContext);

    if (onCaptchaTimeout) {
      onCaptchaTimeout();
    }
  };

  const onUnsupported = () => {
    captureMessage('Turnstile: browser unsupported', sentryContext);
  };

  return (
    <StyledTurnstile
      sitekey={sitekey}
      userRef={userRef}
      size={size}
      appearance={appearance}
      id={id}
      className={className}
      style={style}
      theme="dark"
      execution="render"
      action={action}
      onVerify={onCaptchaVerify}
      onLoad={onLoad}
      onError={onError}
      onExpire={onExpire}
      onTimeout={onTimeout}
      onUnsupported={onUnsupported}
    />
  );
};

export default CaptchaWidget;
