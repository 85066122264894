import { styled } from '@parsec/components';

export default styled('footer', {
  display: 'grid',
  gridAutoFlow: 'column',
  rowGap: '$large',
  columnGap: '$xxxlarge',
  justifyContent: 'center',
  alignItems: 'center'
});
