import { useCallback, useState } from 'react';

import { CreateWarpSubscriptionReq } from '@parsec/kessel/src/warpSubscription';

import { WarpPlanID, WarpSubscription } from '@parsec/kessel';
import {
  useCreateWarpBillingCard,
  useGetWarpBillingDetails,
  useUpdateWarpSubscription
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import useWarpPricingState from './useWarpPricingState';

export default function useUpgradeModalState(props: {
  subscription?: WarpSubscription;
}) {
  const { subscription } = props;
  const [step, setStep] = useState('pricing');
  const [error, setError] = useState('');

  const billingDetails = useGetWarpBillingDetails();

  const currentPlanId = subscription?.plan_id as WarpPlanID;

  const oppositeId =
    subscription?.plan_id === WarpPlanID.Monthly
      ? WarpPlanID.Yearly
      : WarpPlanID.Monthly;

  const [shouldUpdateCard, setShouldUpdateCard] = useState(false);

  const createWarpBillingCard = useCreateWarpBillingCard();
  const updateWarpSubscription = useUpdateWarpSubscription();

  const onCreateBillingCard = async () => {
    try {
      await createWarpBillingCard.mutateAsync({
        token: req.token
      });
      return true;
    } catch (err) {
      const error = parseError(err);
      setError(error.error);
      return false;
    }
  };

  const updateSubscription = async () => {
    try {
      await updateWarpSubscription.mutateAsync(req);
    } catch (err) {
      const error = parseError(err);
      setError(error.error);
    }
  };

  const onUpdateSubscription = async () => {
    //create new card
    if (shouldUpdateCard) {
      const cardSuccess = await onCreateBillingCard();
      if (cardSuccess) await updateSubscription();
    } else {
      await updateSubscription();
    }
  };

  const [req, setReq] = useState<CreateWarpSubscriptionReq>({
    plan_id: oppositeId,
    token: '',
    customer: {
      email: billingDetails.data?.email ?? '',
      billing_address: {
        ...billingDetails?.data?.billing_address,
        line1: billingDetails.data?.billing_address.line1 ?? '',
        city: billingDetails.data?.billing_address.city ?? '',
        state: billingDetails.data?.billing_address.state ?? '',
        country: billingDetails.data?.billing_address.country ?? ''
      }
    },
    coupon: ''
  });

  const updateReq = useCallback(
    (args: Partial<CreateWarpSubscriptionReq>) => {
      setReq({ ...req, ...args });
    },
    [req]
  );

  const { unitPrice, subtotal, estimate } = useWarpPricingState({
    req
  });

  return {
    unitPrice,
    subtotal,
    estimate,
    error,
    step,
    req,
    currentPlanId,
    loading:
      updateWarpSubscription.isLoading || createWarpBillingCard.isLoading,
    onUpdateSubscription,
    setShouldUpdateCard,
    updateReq,
    setError,
    setStep
  };
}
