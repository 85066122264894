import { useMemo } from 'react';

import { Button, Icon, styled, CaptchaActions } from '@parsec/components';
import { useResendConfirmationEmail } from '@parsec/queries';

import ResendConfirmEmailModal from './ResendConfirmEmailModal';
import StarSvg from './star.svg';

// STYLES
const Wrapper = styled('div', {
  background: '$ultraDark',
  color: '$consoleWhite',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  columnGap: '$xxxxlarge',
  padding: '$xxxxlarge $xxlarge',
  margin: '$xxlarge',
  borderRadius: '$medium',
  minHeight: '15rem',
  border: '.1rem solid $pangoro',
  maxWidth: '60rem'
});

const Reload = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$xxlarge',
  width: 'fit-content',
  marginTop: '$xlarge'
});

const StyledIcon = styled(Icon, {
  width: '6.2rem',
  height: 'auto',
  fill: '$consoleWhite'
});

const Images = styled('div', {
  display: 'none',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'right',
  '@large': {
    display: 'grid'
  },
  '@xlarge': {
    alignItems: 'end'
  }
});

const Description = styled('p', {
  fontSize: '$info',
  lineHeight: '$info',
  marginTop: '$small'
});

const Confirmation = styled('p', {
  fontSize: '$body',
  lineHeight: '$body',
  marginTop: '$large',
  fontWeight: 'bold'
});

const Star = styled(StarSvg, {
  height: '1.7rem',
  bottom: '7rem',
  display: 'grid',
  right: '-2rem',
  position: 'absolute',
  marginBottom: '1rem'
});

const ResendEmailBtn = styled('button', {
  color: '$primary500',
  cursor: 'pointer',
  fontSize: '$info',
  lineHeight: '$info'
});

// COMPONENT
export default function EmailVerification(props: { onRefetch(): void }) {
  const { onRefetch } = props;
  const resend = useResendConfirmationEmail();

  const renderedResendStatus = useMemo(() => {
    if (resend.isSuccess) {
      return (
        <Confirmation aria-live="polite">
          Confirmation resent! Please check your email.
        </Confirmation>
      );
    } else if (resend.isError) {
      return (
        <Confirmation aria-live="polite">
          Sorry there was an error re-sending the email. Please try again.
        </Confirmation>
      );
    }
    return null;
  }, [resend.isSuccess, resend.isError]);

  return (
    <Wrapper>
      <Images>
        <Star />
        <StyledIcon name="email" />
      </Images>

      <div>
        <strong>
          You need a verified Parsec account before signing up for Teams.
        </strong>
        <Description>
          Didn&apos;t get the email?{' '}
          <ResendConfirmEmailModal
            onResend={opt => resend.mutate(opt)}
            actionName={CaptchaActions.CreateTeamSendConfirmationEmail}
          >
            <ResendEmailBtn>Resend Verification</ResendEmailBtn>
          </ResendConfirmEmailModal>{' '}
          or <a href="https://support.parsec.app">Contact Support</a> if you
          cannot complete sign up.
        </Description>
        {renderedResendStatus}{' '}
        <Reload>
          <Confirmation id="reload-button">Already verified?</Confirmation>
          <Button aria-describedby="reload-button" onClick={onRefetch}>
            Reload the Page
          </Button>
        </Reload>
      </div>
    </Wrapper>
  );
}
