// libraries
import { useState } from 'react';

import Link from 'next/link';

// @parsec
import {
  styled,
  IncidentNotificationBanner as BaseIncidentBanner,
  useIncidentNotification
} from '@parsec/components';

import Wrapper from 'components/Wrapper';

import Credentials from './credentials';
import Tfa from './tfa';

const IncidentBanner = styled(BaseIncidentBanner, {
  //   marginTop: '$xxlarge',
  //   marginBottom: '$large'
});

enum Step {
  Credentials,
  Tfa
}

interface Props {
  email?: string;
  showSignupLink?: boolean;
  showSamlLink?: boolean;
  onSuccess(): void | Promise<void>;
}

export default function LogIn(props: Props) {
  const { email, showSamlLink, onSuccess } = props;

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [step, setStep] = useState(Step.Credentials);

  const { incidentStatus, incidentText, incidentTitle, showIncidentBanner } =
    useIncidentNotification();
  const showSignupLink = props.showSignupLink && !showIncidentBanner;

  return (
    <Wrapper
      layout="spread"
      spacer={showSignupLink || showIncidentBanner ? 'bottom' : 'both'}
    >
      {showIncidentBanner ? (
        <IncidentBanner
          status={incidentStatus}
          text={incidentText}
          title={incidentTitle}
        />
      ) : null}
      {showSignupLink ? (
        <SignUpPrompt>
          Need an account? <Link href="/signup">Sign up</Link>
        </SignUpPrompt>
      ) : null}

      {step === Step.Credentials ? (
        <Credentials
          forceEmail={email}
          showSamlLink={showSamlLink}
          onSuccess={onSuccess}
          onNeedsTfa={credentials => {
            setCredentials(credentials);
            setStep(Step.Tfa);
          }}
        />
      ) : (
        <Tfa
          onSuccess={onSuccess}
          onBack={() => setStep(Step.Credentials)}
          email={credentials.email}
          password={credentials.password}
          id="tfa_login_form"
        />
      )}
    </Wrapper>
  );
}

const SignUpPrompt = styled('p', {
  marginBottom: '$xxxlarge'
});
