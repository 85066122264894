import { styled } from '@parsec/components';

export const Wrapper = styled('div', {
  display: 'grid',
  rowGap: '$large',
  justifyItems: 'center',
  width: '46.2rem',
  paddingTop: '$large',
  marginBottom: '$large'
});

export const FeaturesList = styled('ul', {
  paddingTop: '$xxxlarge',
  display: 'grid',
  rowGap: '$xxxxlarge'
});

export const ListItem = styled('li', {
  display: 'grid',
  gridTemplateColumns: '6.5rem auto',
  columnGap: '$xlarge',
  alignItems: 'start'
});

export const FeatureTitle = styled('p', {
  fontSize: '1.4rem',
  fontWeight: '$bold',
  lineHeight: '$attribution'
});

export const FeatureDescription = styled('p', {
  fontSize: '1rem',
  lineHeight: '$info'
});

export const Banner = styled('div', {
  width: '100%',
  padding: '$xxlarge $xxxxlarge',
  marginTop: '$xxxxlarge',
  backgroundColor: '$samehada',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$large'
});

export const BannerLink = styled('a', {
  fontSize: '1.4rem',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  color: '$ultraDark',
  backgroundColor: '$consoleWhite',
  padding: '$small $xlarge',
  borderRadius: '1.8rem'
});
