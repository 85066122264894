// libraries
import { useForm } from 'react-hook-form';

// @parsec
import {
  styled,
  FieldLabel,
  ErrorMessage,
  Input,
  Modal,
  Button
} from '@parsec/components';
import { useSetTFARecoveryEmail } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});

const Wrapper = styled('div', {
  display: 'grid',
  gap: '$xlarge',
  gridTemplate: 'auto / 2fr 1fr'
});

interface Props {
  primaryAccountEmail: string;
  currentRecoveryEmail?: string;
  showTfa?: boolean;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

interface FormValues {
  email: string;
  password: string;
  tfa: string;
}

const ERROR_NAME_EMAIL = 'email';

export default function BackupCodesModal(props: Props) {
  const {
    primaryAccountEmail,
    currentRecoveryEmail,
    showTfa,
    isOpen,
    onClose,
    onAfterClose
  } = props;

  const setTFARecoveryEmail = useSetTFARecoveryEmail();

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    criteriaMode: 'all'
  });

  function validateEmail(_: string, formValues: FormValues) {
    if (!formValues) return false;
    if (formValues.email === primaryAccountEmail) {
      setError(ERROR_NAME_EMAIL, {
        type: 'custom',
        message: 'Must not be the same as primary account email'
      });
      return false;
    }
    clearErrors(ERROR_NAME_EMAIL);
    return true;
  }

  const onSubmit = handleSubmit(async values => {
    try {
      await setTFARecoveryEmail.mutateAsync(values);
      onClose();
    } catch (_err) {
      return;
    }
  });

  return (
    <Modal
      title="2FA Recovery Email"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
    >
      <Form onSubmit={onSubmit} id="set_recovery_email">
        <p>
          Add a secondary email address. If you lose access to your two-factor
          authentication app and your backup codes, we will use it to recover
          access to your account.
        </p>
        <FieldLabel>
          <FieldLabel.Label label="Recovery Email" required>
            <Input
              type="email"
              defaultValue={currentRecoveryEmail}
              {...register('email', {
                required: 'You must enter a recovery email',
                validate: validateEmail
              })}
            />
          </FieldLabel.Label>
          {errors.email ? (
            <ErrorMessage>{errors.email.message}</ErrorMessage>
          ) : null}
        </FieldLabel>
        <Wrapper>
          <FieldLabel>
            <FieldLabel.Label label="Password" required>
              <Input
                type="password"
                autoComplete="off"
                {...register('password', {
                  required: 'You must enter a password'
                })}
              />
            </FieldLabel.Label>
          </FieldLabel>
          {showTfa ? (
            <FieldLabel>
              <FieldLabel.Label label="2FA Code" required>
                <Input
                  type="numeric"
                  {...register('tfa', {
                    required: 'You must enter a 2FA Code',
                    minLength: 6,
                    maxLength: 6
                  })}
                />
              </FieldLabel.Label>
            </FieldLabel>
          ) : null}
        </Wrapper>
        {setTFARecoveryEmail.error ? (
          <ErrorMessage>{setTFARecoveryEmail.error.error}</ErrorMessage>
        ) : null}
      </Form>
      <Modal.Actions>
        <Button form="set_recovery_email" disabled={!isValid}>
          Set Email
        </Button>
        <Button level="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
