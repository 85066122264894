/**
 * SIGNUP USERNAME
 */
export const SIGNUP_USERNAME_FORM_ID =
  'form_signup_account2_step1_set_email_username';

/**
 * SIGNUP PASSWORD
 */
export const SIGNUP_PASSWORD_FORM_ID =
  'form_signup_account2_step2_set_password';

/**
 * BILLING FORMS
 */
export const BILLING_INTERVAL_FORM_IDS = {
  DEFAULT_ID: 'select_billing_type_form',
  NEW_SIGNUP_ID: 'form_signup_teamtrial2_step4_set_name_plan',
  WARP_TRIAL_SIGNUP_ID: 'select_billing_type_form_warp_trial'
} as const;

export type BillingIntervalId =
  (typeof BILLING_INTERVAL_FORM_IDS)[keyof typeof BILLING_INTERVAL_FORM_IDS];

export const BILLING_INFO_FORM_IDS = {
  DEFAULT_ID: 'billing_info_form',
  NEW_SIGNUP_ID: 'form_signup_teamtrial2_step5_set_billing_info',
  WARP_TRIAL_SIGNUP_ID: 'billing_info_form_warp_trial'
} as const;

export type BillingInfoId =
  (typeof BILLING_INFO_FORM_IDS)[keyof typeof BILLING_INFO_FORM_IDS];

export const BILLING_SUMMARY_FORM_IDS = {
  DEFAULT_ID: 'create_team_purchase_form',
  NEW_SIGNUP_ID: 'form_signup_teamtrial2_step6_submit_summary',
  WARP_TRIAL_SIGNUP_ID: 'billing_summary_form_submit_warp_trial'
} as const;

export type BillingSummaryId =
  (typeof BILLING_SUMMARY_FORM_IDS)[keyof typeof BILLING_SUMMARY_FORM_IDS];

/**
 * SIGNUP SURVEY FORM
 */
export const SIGNUP_SURVEY_FORM_ID = 'form_signup_teamtrial2_step1_survey';
export const WARP_SIGNUP_SURVEY_FORM_ID = 'form_signup_warptrial2_step1_survey';

/**
 * EMAIL VERIFICATION
 */
export const EMAIL_VERIFICATION_FORM_ID =
  'form_signup_account2_step1_verify_email';

/**
 * TFA
 */
export const TFA_CONFIRM_FORM_IDS = {
  DEFAULT_ID: 'tfa_confirm_form',
  MYSELF_PATH_ID: 'form_signup_mewarppromo2_step1_enable_TFA',
  TEAM_PATH_ID: 'form_signup_teamtrial2_step2_enable_TFA'
} as const;

export type TfaConfirmId =
  (typeof TFA_CONFIRM_FORM_IDS)[keyof typeof TFA_CONFIRM_FORM_IDS];

export const TFA_BACKUP_CODES_IDS = {
  DEFAULT_ID: 'tfa_backup_form',
  MYSELF_PATH_ID: 'form_signup_mewarppromo2_step2_backup_codes',
  TEAM_PATH_ID: 'form_signup_teamtrial2_step3_backup_codes'
} as const;

export type TfaBackupCodesId =
  (typeof TFA_BACKUP_CODES_IDS)[keyof typeof TFA_BACKUP_CODES_IDS];

export const CHANGE_EMAIL_FORM_IDS = {
  DEFAULT_ID: 'change_email',
  VERIFY_ID: 'verify_change_email',
  RESEND_ID: 'resend_change_email'
};
