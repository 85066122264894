import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { PasswordRules, FieldLabel } from '@parsec/components';
import { styled } from '@parsec/styles';

import Input, { InputProps } from '../Input';

const StyledError = styled('p', {
  marginTop: '$large'
});

export interface PasswordInputProps extends InputProps {
  className?: string;
  value?: string;
  defaultValue?: string;
  identifier?: string;
}

const defaultIdentifier = 'password';

export const createPasswordInputSchema = (
  identifier: string = defaultIdentifier
) =>
  z.object({
    [identifier]: z
      .string()
      .min(12)
      .max(64, { message: 'Your password must have 64 characters or fewer.' })
      // Has letter
      .regex(/[a-zA-Z]/, { message: 'letter' })
      // Has number
      .regex(/\d/, { message: 'number' })
      // Has symbol
      .regex(/[`~!@#%&_=;:'"<>,[\](){}*+?|^$.\-/\\]/, {
        message: 'symbol'
      })
  });

export const PasswordInput = (props: PasswordInputProps) => {
  const { identifier = defaultIdentifier, className, ...rest } = props;

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();

  const watchPassword = watch(identifier);

  const passwordErrors = (() => {
    const errorField = errors[identifier];
    const hasPassword = Boolean(watchPassword);

    if (hasPassword && errorField === undefined) {
      return {
        minLength: false,
        hasLetter: false,
        hasNumber: false,
        hasSymbol: false
      };
    }

    if (errorField === undefined || errorField.types === undefined) {
      return undefined;
    }

    const types = errorField.types as Record<string, string[] | string>;
    const invalidStringErrors = types[z.ZodIssueCode.invalid_string];
    const invalidStringErrorsSet = new Set<string>();

    if (typeof invalidStringErrors === 'string') {
      invalidStringErrorsSet.add(invalidStringErrors);
    } else if (Array.isArray(invalidStringErrors)) {
      invalidStringErrors.forEach(error => invalidStringErrorsSet.add(error));
    }

    return {
      minLength: Boolean(types[z.ZodIssueCode.too_small]),
      hasLetter: invalidStringErrorsSet.has('letter'),
      hasNumber: invalidStringErrorsSet.has('number'),
      hasSymbol: invalidStringErrorsSet.has('symbol')
    };
  })();

  const passwordToBig = errors[identifier]?.type === z.ZodIssueCode.too_big;
  const customError = errors[identifier]?.type === z.ZodIssueCode.custom;

  return (
    <div className={className}>
      <Input type="password" {...rest} {...register(identifier)} />

      <PasswordRules errors={passwordErrors} />

      {/* Errors that should appear just below password rules */}
      {passwordToBig ? (
        <FieldLabel.HelperTextContainer>
          <FieldLabel.Message role="alert" hasError>
            <StyledError>
              Your password must have 64 characters or fewer.
            </StyledError>
          </FieldLabel.Message>
        </FieldLabel.HelperTextContainer>
      ) : customError ? (
        <FieldLabel.HelperTextContainer>
          <FieldLabel.Message role="alert" hasError>
            <StyledError>{errors[identifier]?.message?.toString()}</StyledError>
          </FieldLabel.Message>
        </FieldLabel.HelperTextContainer>
      ) : null}
    </div>
  );
};

export default PasswordInput;
