// libraries
import { useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

// import { type BoundTurnstileObject } from 'react-turnstile';

// @parsec
import { pushToDl } from '@parsec/analytics';
import {
  Button,
  ErrorMessage,
  FieldLabel,
  Input,
  styled
} from '@parsec/components';
import { useLogIn } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { param } from 'lib/util/router';
import { clearStickyState } from 'lib/util/storageAvailable';

import Actions from '../Actions';
import Form from '../Form';

import hero from 'lib/images/login@2x.png';

// import { TURNSTILE_SITE_KEY } from 'lib/config';

// STYLES
const Forgot = styled('span', {
  fontSize: '$info',
  lineHeight: '$info',
  marginTop: '-$large'
});

const Saml = styled('p', {
  textAlign: 'center'
});

// COMPONENT
interface Props {
  forceEmail?: string;
  showSamlLink?: boolean;
  onNeedsTfa(credentials: { email: string; password: string }): void;
  onSuccess(): void;
}

interface FormValues {
  email: string;
  password: string;
  tfa?: string;
}

export default function Credentials(props: Props) {
  const { forceEmail, showSamlLink, onNeedsTfa, onSuccess } = props;
  const [sessionError, setSessionError] = useState<null | string>();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    const invalidSession = param(router.query.invalidSession);
    if (invalidSession === '401') {
      setSessionError('Unauthorized, please login');
    } else if (invalidSession === '412') {
      setSessionError(' Your session has expired, please log in again');
    } else if (invalidSession === 'true') {
      setSessionError('Your session has expired. Please reauthenticate.');
    }
  }, [router]);

  // CAPTCHA
  // TODO: We're disabling Captcha verification on the login endpoints until
  // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
  // are resolved.
  // const [captchaToken, setCaptchaToken] = useState('');
  // const [captchaBound, setCaptchaBound] = useState<BoundTurnstileObject>();

  const logIn = useLogIn();

  const logInError = logIn.error
    ? parseError(logIn.error, { error: "Couldn't log in." })
    : undefined;

  return (
    <Form<FormValues>
      hero={hero}
      title="Log in to Parsec"
      onSubmit={async values => {
        setSessionError(null);
        try {
          // login event datalayer push

          pushToDl({
            event: 'userEvent',
            event_name: 'login_action',
            properties: {
              login_action: 'login_web',
              login_method: 'email'
            }
          });
          // TODO: We're disabling Captcha verification on the login endpoints until
          // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
          // are resolved.
          // await logIn.mutateAsync({ ...values, captcha_token: captchaToken });
          await logIn.mutateAsync({
            ...values,
            // Since we aren't validating the captcha_token, set it to an empty string to satisfy
            // the schema requirements
            captcha_token: ''
          });
          onSuccess();
        } catch (err) {
          const resError = parseError(err, {
            body: { tfa_required: false }
          });

          const needsTfa = resError.codes.some(
            code => code.type === 'tfa_incorrect'
          );
          if (needsTfa) onNeedsTfa(values);
          throw err;
        } finally {
          // TODO: We're disabling Captcha verification on the login endpoints until
          // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
          // are resolved.
          // Reset the captcha widget so we are granted a new token
          // setCaptchaToken('');
          // captchaBound?.reset();

          // TODO: Temporary to ensure that all traces of the warpTrialConfirmation are removed.
          // We deprecated the state in PARSEC-3071
          clearStickyState(`warpTrialConfirmation`);
        }
      }}
    >
      {sessionError ? <ErrorMessage>{sessionError}</ErrorMessage> : null}

      <FieldLabel>
        <FieldLabel.Label label="Email">
          <Input
            id="email"
            name="email"
            placeholder="ash@parsec.app"
            value={forceEmail}
            disabled={Boolean(forceEmail)}
            autoFocus
          />
        </FieldLabel.Label>
      </FieldLabel>
      <FieldLabel>
        <FieldLabel.Label label="Password">
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="************"
            autoComplete="off"
          />
        </FieldLabel.Label>
      </FieldLabel>
      <Forgot>
        <Link href="/forgot">Forgot your password?</Link>
      </Forgot>

      {logInError ? <ErrorMessage>{logInError.error}</ErrorMessage> : null}

      {/* 
      TODO: We're disabling Captcha verification on the login endpoints until
          all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
          are resolved.
       */}

      {/* <CaptchaWidget
        sitekey={TURNSTILE_SITE_KEY}
        onCaptchaVerify={(token: string) => {
          setCaptchaToken(token);
        }}
        onCaptchaLoad={(_, bound) => {
          setCaptchaBound(bound);
        }}
        action={CaptchaActions.Login}
      /> */}

      <Actions css={{ marginTop: '$xlarge' }}>
        <Button wide id="submit_login_email_password">
          Log In
        </Button>
      </Actions>
      {showSamlLink ? (
        <Saml>
          Teams user with SAML SSO? <Link href="/saml/login">Log in here</Link>
        </Saml>
      ) : null}
    </Form>
  );
}
