import { Button } from '@parsec/components';
import { styled } from '@parsec/styles';

export const Wrapper = styled('div', {
  maxWidth: 'inherit',
  overflow: 'hidden',
  display: 'grid'
});

export const HeadBack = styled(Button, {
  backgroundColor: '#f9fafcff', //no token
  color: '$brand500',

  '&:not(:disabled):hover': {
    backgroundColor: '#f9fafcff', //no token
    color: '$brand500'
  }
});

export const Description = styled('p', {
  fontSize: '$body',
  lineHeight: '$body',
  marginTop: '$medium'
});

export const Section = styled('div', {
  padding: '$xxlarge',
  background: '$perfectGray',
  gap: '2rem',
  display: 'grid'
});
