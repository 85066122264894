/**
 * TODO
 * refactor component to use react-hook-form
 * https://jira.unity3d.com/browse/PARSEC-1968
 */
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import {
  Bread,
  ErrorMessage,
  FieldLabel,
  Input,
  ManageSeats,
  styled
} from '@parsec/components';
import { TEAM_MAX_SEATS, TEAM_MIN_SEATS } from '@parsec/constants';
import { CreateTeamReq } from '@parsec/kessel';

import { Form } from 'components';
import { MARKETING_URL } from 'lib/config';
import {
  BILLING_INTERVAL_FORM_IDS,
  type BillingIntervalId
} from 'lib/constants/formIds';
import { getSeatUnitPrice } from 'lib/util/payment';
import {
  getChargebeePlanID,
  INTERVAL_MONTHLY,
  INTERVAL_YEARLY
} from 'lib/util/subscription';

interface Props {
  onSubmit(name: string): void;
  updateReq(args: Partial<CreateTeamReq>): void;
  error: string;
  req: {
    seats: number;
    interval: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY;
    name: string;
  };
  formId?: BillingIntervalId;
  onSeatValueValid: Dispatch<SetStateAction<boolean>>;
}

export default function BillingInterval(props: Props) {
  const {
    onSubmit,
    req,
    updateReq,
    error,
    formId = BILLING_INTERVAL_FORM_IDS.DEFAULT_ID,
    onSeatValueValid
  } = props;
  const { interval, name, seats } = req;
  const [teamName, setTeamName] = useState(name);

  const unitPrice = getSeatUnitPrice(interval);

  const handlePaidSeats = useCallback(
    (seats: number) => {
      updateReq({ standard_seats: seats });
    },
    [updateReq]
  );

  return (
    <Form
      title="Let's get your team set up."
      onSubmit={() => onSubmit(teamName)}
      id={formId}
      width="large"
      alignTitle="center"
    >
      <TeamLink href={`${MARKETING_URL}/teams`}>View Team Features</TeamLink>
      <p>What should we call your team?</p>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FieldLabel>
        <FieldLabel.Label label="Team Name" required>
          <Input
            id="teamName"
            name="teamName"
            type="text"
            value={teamName}
            onChange={e => setTeamName(e.target.value)}
            placeholder="Team Name"
            required
            autoFocus
          />
        </FieldLabel.Label>
        <FieldLabel.HelperTextContainer>
          <FieldLabel.Message>
            Don&apos;t think too hard; you can change the name as often as you
            like
          </FieldLabel.Message>
        </FieldLabel.HelperTextContainer>
      </FieldLabel>
      <p>
        {teamName && `Nice to meet you, ${teamName}.`} How many people will be
        using Parsec for Teams ?{' '}
      </p>
      <div>
        <PlanButton
          selected={interval === INTERVAL_MONTHLY}
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault();
            updateReq({
              plan_id: getChargebeePlanID(INTERVAL_MONTHLY, 'standard')
            });
          }}
        >
          <ButtonLabel>
            <strong>Monthly</strong>
          </ButtonLabel>
          <ButtonLabel>
            <strong>
              <Bread decimal={0}>{getSeatUnitPrice(INTERVAL_MONTHLY)}</Bread>
            </strong>{' '}
            p/ seat p/ month
          </ButtonLabel>
        </PlanButton>
        <PlanButton
          selected={interval === INTERVAL_YEARLY}
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault();
            updateReq({
              plan_id: getChargebeePlanID(INTERVAL_YEARLY, 'standard')
            });
          }}
        >
          <ButtonLabel>
            <strong>Annual</strong>
          </ButtonLabel>
          <ButtonLabel>
            <strong>
              <Bread decimal={0}>{getSeatUnitPrice(INTERVAL_YEARLY)}</Bread>
            </strong>{' '}
            p/ seat p/ year
          </ButtonLabel>
          <WowSavings>Save 14%</WowSavings>
        </PlanButton>
      </div>
      <ManageSeatsWrapper>
        <ManageSeats
          key={seats} // necessary to re-render ManageSeats from props
          seatUnitPrice={unitPrice}
          interval={interval}
          paidSeats={seats}
          freeSeats={0}
          minPaidSeats={TEAM_MIN_SEATS}
          maxPaidSeats={TEAM_MAX_SEATS}
          onSetPaidSeats={handlePaidSeats}
          onSeatValueValid={onSeatValueValid}
        />
      </ManageSeatsWrapper>
      <Explanation>
        You can add or remove seats at any time
        {teamName && ` as ${teamName} grows`}.
      </Explanation>
    </Form>
  );
}

const ButtonLabel = styled('span', {
  fontWeight: 'normal'
});

const WowSavings = styled('span', {
  display: 'none',
  backgroundColor: '$brand500',
  borderRadius: '4rem',
  border: 'none',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '$info',
  lineHeight: '$info',
  padding: '$small $medium',
  height: 'min-content',
  marginLeft: '$xlarge',

  '@medium': {
    display: 'inline-block'
  }
});

const PlanButton = styled('button', {
  width: '100%',
  height: '4.6rem',
  display: 'grid',
  alignItems: 'center',
  padding: '$large 1.5rem',
  backgroundColor: 'transparent',
  borderRadius: '$medium',
  boxShadow: 'inset 0 0 0 0.2rem $colors$espurr',
  cursor: 'pointer',
  transition: '125ms box-shadow ease',
  margin: '$small 0',
  textAlign: 'left',
  gridTemplateColumns: '6rem auto max-content',
  color: '$consoleWhite',
  gap: '$xlarge',
  '&:not(:last-child)': {
    marginTop: '$large'
  },
  '&:hover': {
    boxShadow: 'inset 0 0 0 0.1rem $colors$primary500',
    backgroundColor: 'transparent'
  },
  variants: {
    selected: {
      true: {
        cursor: 'default',
        backgroundColor: '$carkol',
        boxShadow: 'inset 0 0 0 0.2rem $colors$primary500'
      },
      false: {}
    }
  }
});

const ManageSeatsWrapper = styled('div', {
  marginTop: '3.8rem',
  borderRadius: '$medium',
  display: 'grid',
  justifyContent: 'center',
  rowGap: '$xxxlarge'
});

const Explanation = styled('span', {
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  display: 'grid',
  justifySelf: 'center'
});

const TeamLink = styled('a', {
  textAlign: 'center',
  marginTop: '-.6rem'
});
