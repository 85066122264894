// Subscription billing intervals
export const INTERVAL_YEARLY = 'year';
export const INTERVAL_MONTHLY = 'month';

// Plan IDs
export const TEAM_MONTHLY_CB_PLAN_ID = 'team-monthly';
export const TEAM_YEARLY_CB_PLAN_ID = 'team-yearly';
export const TEAM_ENTERPRISE_MONTHLY_CB_PLAN_ID = 'team-enterprise-monthly';
export const TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID = 'team-enterprise-yearly';

const CB_TEAM_SUB_PLAN_ID = {
  enterprise: {
    [INTERVAL_MONTHLY]: TEAM_ENTERPRISE_MONTHLY_CB_PLAN_ID,
    [INTERVAL_YEARLY]: TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID
  },
  standard: {
    [INTERVAL_MONTHLY]: TEAM_MONTHLY_CB_PLAN_ID,
    [INTERVAL_YEARLY]: TEAM_YEARLY_CB_PLAN_ID
  }
};
export const getChargebeePlanID = (
  interval: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY,
  type: 'standard' | 'enterprise'
) =>
  type === 'enterprise'
    ? CB_TEAM_SUB_PLAN_ID.enterprise[interval]
    : CB_TEAM_SUB_PLAN_ID.standard[interval];
