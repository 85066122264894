// @parsec
import { Card } from '@parsec/kessel';
import { styled } from '@parsec/styles';

export interface BillingCardPreviewProps {
  className?: string;
  card: Card;
  name?: string;
}

function BillingCardPreview(props: BillingCardPreviewProps) {
  const { className, card, name } = props;

  return (
    <Wrapper withName={!!name} className={className}>
      {name ? (
        <>
          <CardLabel bottom css={{ gridArea: 'nameHeader' }}>
            Name
          </CardLabel>
          <CardInfo css={{ gridArea: 'nameValue' }}>{name}</CardInfo>
        </>
      ) : null}

      <CardLabel bottom css={{ gridArea: 'cardHeader' }}>
        Card Number
      </CardLabel>
      <CardInfo css={{ gridArea: 'cardValue' }}>
        •••• •••• •••• {card.last4}
      </CardInfo>

      <CardLabel bottom css={{ gridArea: 'expiryHeader' }}>
        Expiration
      </CardLabel>
      <CardInfo css={{ gridArea: 'expiryValue' }}>
        {card.expiry_month}/{card.expiry_year}
      </CardInfo>

      <CardLabel bottom right css={{ gridArea: 'postalHeader' }}>
        Zip Code
      </CardLabel>
      <CardInfo
        right
        css={{
          gridArea: 'postalValue'
        }}
      >
        {card.zip}
      </CardInfo>
    </Wrapper>
  );
}

export default BillingCardPreview;

const Wrapper = styled('dl', {
  display: 'grid',
  gridAutoFlow: 'column',
  height: 'min-content',
  width: '100%',
  variants: {
    withName: {
      true: {
        gridTemplateColumns: '3fr 3fr 2fr 1fr',
        gridTemplateAreas: `"nameHeader cardHeader expiryHeader postalHeader" "nameValue cardValue expiryValue postalValue"`
      },
      false: {
        gridTemplateColumns: '6fr 2fr 1fr',
        gridTemplateAreas: `"cardHeader expiryHeader postalHeader" "cardValue expiryValue postalValue"`
      }
    }
  }
});

const CardLabel = styled('dt', {
  color: '$rhyhorn',
  fontSize: '$info',
  variants: {
    bottom: {
      true: {
        marginTop: '$large'
      }
    },
    right: {
      true: {
        textAlign: 'right'
      }
    }
  }
});

const CardInfo = styled('dd', {
  variants: {
    right: {
      true: {
        textAlign: 'right'
      }
    }
  }
});
