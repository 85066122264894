import { useCallback, useState } from 'react';

import { CreateWarpSubscriptionReq } from '@parsec/kessel/src/warpSubscription';

import {
  WarpInterval,
  WarpPlanID,
  WarpSubscription,
  intervalToPlan
} from '@parsec/kessel';
import {
  useCreateWarpBillingCard,
  useGetWarpBillingDetails,
  useResumeWarpSubscription,
  useUpdateWarpSubscription
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import useWarpPricingState from './useWarpPricingState';

export default function useResumeSubscriptionModalState(props: {
  subscription?: WarpSubscription;
}) {
  const { subscription } = props;
  const isResume = subscription?.status !== 'cancelled';

  const [step, setStep] = useState(isResume ? 'billing' : 'pricing');
  const [error, setError] = useState('');
  const [shouldUpdateCard, setShouldUpdateCard] = useState(false);

  const createWarpBillingCard = useCreateWarpBillingCard();
  const billingDetails = useGetWarpBillingDetails();
  const resumeWarpSubscription = useResumeWarpSubscription();
  const updateWarpSubscription = useUpdateWarpSubscription();

  const currentPlanId = subscription?.plan_id as WarpPlanID;

  const [req, setReq] = useState<CreateWarpSubscriptionReq>({
    plan_id: isResume ? currentPlanId : intervalToPlan(WarpInterval.Yearly),
    token: '',
    customer: {
      email: billingDetails.data?.email ?? '',
      billing_address: {
        ...billingDetails?.data?.billing_address,
        line1: billingDetails.data?.billing_address.line1 ?? '',
        city: billingDetails.data?.billing_address.city ?? '',
        state: billingDetails.data?.billing_address.state ?? '',
        country: billingDetails.data?.billing_address.country ?? ''
      }
    },
    coupon: subscription?.coupon
  });

  const handleCreateBillingCard = async () => {
    try {
      await createWarpBillingCard.mutateAsync({
        token: req.token
      });
      return true;
    } catch (err) {
      const error = parseError(err);
      setError(error.error ?? 'We were unable to update your billing card');
      return false;
    }
  };

  const resumeSubscription = async () => {
    try {
      await resumeWarpSubscription.mutateAsync();
    } catch (err) {
      const error = parseError(err);
      setError(
        error.error ??
          'There was an error resuming your Warp Subscription. Please try again.'
      );
    }
  };

  const updateSubscription = async () => {
    try {
      await updateWarpSubscription.mutateAsync(req);
      return true;
    } catch (err) {
      const error = parseError(err);
      setError(
        error.error ??
          'There was an error updating your Warp subscription details. Please try again.'
      );
      return false;
    }
  };

  const handleResubscribe = async () => {
    const updateSuccess = await updateSubscription();
    if (updateSuccess) {
      await resumeSubscription();
    }
  };

  const handleResume = async () => {
    let cardSuccess = undefined;

    //new card?
    if (req.token) {
      cardSuccess = await handleCreateBillingCard();
      if (cardSuccess) {
        if (isResume) {
          await resumeSubscription();
        } else {
          // had the option to edit their plan
          await handleResubscribe();
        }
      }
    } else {
      if (isResume) {
        await resumeSubscription();
      } else {
        // had the option to edit their plan
        await handleResubscribe();
      }
    }
  };

  // Pricing
  const updateReq = useCallback(
    (args: Partial<CreateWarpSubscriptionReq>) => {
      setReq({ ...req, ...args });
    },
    [req]
  );

  const { unitPrice, subtotal, discount, estimate } = useWarpPricingState({
    req
  });

  return {
    error,
    req,
    unitPrice,
    subtotal,
    discount,
    loading:
      resumeWarpSubscription.isLoading ||
      createWarpBillingCard.isLoading ||
      updateWarpSubscription.isLoading,
    step,
    estimate,
    shouldUpdateCard,
    setStep,
    setError,
    updateReq,
    handleResume,
    setShouldUpdateCard
  };
}
