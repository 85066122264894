import { ReactNode } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

export interface AlertProps {
  type?: 'success' | 'error';
  title?: ReactNode;
  message: string | JSX.Element;
  className?: string;
}

export default function Alert(props: AlertProps) {
  const { className, type = 'success', title, message } = props;
  return (
    <Wrapper className={className} type={type}>
      <StyledIcon name={type === 'success' ? 'check' : 'ban'} />
      <h3>{title}</h3>
      <div>{message}</div>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2.4rem auto',
  gridTemplateRows: 'auto auto',
  alignItems: 'center',
  columnGap: '$xlarge',
  backgroundColor: '$success500',
  borderRadius: '$small',
  padding: '$large $xxlarge',
  variants: {
    type: {
      success: {
        backgroundColor: '$success500'
      },
      error: {
        backgroundColor: '$error500'
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  width: '2.4rem',
  height: '2.4rem',
  gridRow: '1 / 3'
});
