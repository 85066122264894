// libraries
import { useEffect, useState } from 'react';

// Kessel
import {
  styled,
  LegacyModal,
  BillingCardPreview as BaseBillingCardPreview,
  Button
} from '@parsec/components';
import { Card } from '@parsec/kessel';
import {
  useCreateWarpBillingCard,
  useGetWarpBillingCard
} from '@parsec/queries';
import { parseError } from '@parsec/request';

// Components

import { useAlert, WarpLogo } from 'components';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';

import {
  BillingInfoForm,
  NewCard as BaseNewCard,
  NewCardData
} from './Billing';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

export default function EditCreditCardModal(props: Props) {
  const { isOpen, onClose } = props;
  const [alert, setAlert] = useAlert();
  const [shouldSaveCard, setShouldSaveCard] = useState(false);
  const createWarpBillingCard = useCreateWarpBillingCard();
  const warpBilling = useGetWarpBillingCard();

  const card = useGetWarpBillingCard();
  const hasCard = Boolean(card.data);
  const [isEditing, setIsEditing] = useState(true);
  useEffect(() => {
    setIsEditing(!hasCard);
  }, [hasCard]);

  useEffect(() => {
    setShouldSaveCard(isEditing);
  }, [isEditing]);

  const onSubmit = async (data: NewCardData) => {
    // No updates needed
    if (!data.token) {
      return;
    }

    try {
      await createWarpBillingCard.mutateAsync({
        token: data.token
      });
      setAlert({
        kind: 'success',
        title: 'Success',
        message: 'Your payment method has been updated.'
      });
      setIsEditing(false);
    } catch (err) {
      const error = parseError(err);
      setAlert({
        kind: 'error',
        title: 'Error',
        message: error.error ?? 'We were unable to update your billing card'
      });
    }
  };

  return (
    <LegacyModal
      title="Update Payment Method"
      type="brand"
      description={
        <>
          <WarpLogo />
          <p>Enhance the ways you game, work and whatever else.</p>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      padding={false}
    >
      <Wrapper>
        {alert}
        <BillingInfoSection>
          <BillingInfoForm<NewCardData>
            id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
            onSubmit={onSubmit}
          >
            {card.data && !isEditing ? (
              <ExistingCard
                card={card.data}
                onEditClick={() => {
                  setShouldSaveCard(true);
                  setIsEditing(true);
                }}
              />
            ) : (
              <NewCard onCancelClick={() => setIsEditing(false)} />
            )}
            <LegacyModal.Actions>
              <Button
                disabled={!shouldSaveCard}
                loading={
                  createWarpBillingCard.isLoading || warpBilling.isLoading
                }
                type="submit"
                id="submit_update_card"
                form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                level="primary"
              >
                Save
              </Button>
              <Button onClick={onClose} level="secondary">
                Cancel
              </Button>
            </LegacyModal.Actions>
          </BillingInfoForm>
        </BillingInfoSection>
      </Wrapper>
    </LegacyModal>
  );
}

function ExistingCard(props: { card: Card; onEditClick?(): void }) {
  const { card, onEditClick } = props;
  return (
    <ExistingCardSection>
      <StyledP>Payment Method</StyledP>
      <EditButton onClick={onEditClick}>Edit</EditButton>
      <BillingCardPreview card={card} />
    </ExistingCardSection>
  );
}

function NewCard(props: { onCancelClick(): void }) {
  const { onCancelClick } = props;
  return (
    <div>
      <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
      <BaseNewCard />
    </div>
  );
}

const Wrapper = styled('div', {
  maxWidth: 'inherit',
  borderRadius: '$large',
  overflow: 'hidden',
  minHeight: '10rem',
  display: 'grid',
  alignItems: 'center'
});

const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});

const ExistingCardSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 10rem',
  gridTemplateRows: '2rem auto',
  paddingBottom: '$xxxlarge'
});

const BillingCardPreview = styled(BaseBillingCardPreview, {
  gridColumnStart: 1,
  gridColumnEnd: 3
});

const StyledP = styled('p', {
  fontWeight: '$bold',
  fontSize: '$body'
});

const EditButton = styled('button', {
  background: 'transparent',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold',
  color: '$primary500',
  padding: '0',
  justifySelf: 'right',
  alignSelf: 'center',
  cursor: 'pointer'
});

const CancelButton = styled(EditButton, {
  padding: '0',
  position: 'absolute',
  right: '$xlarge'
});
