import { styled } from '@parsec/styles';

import AdminRolesSVG from './src/admin-roles.svg';
import AmexCardSVG from './src/amex-card.svg';
import AnonymousSVG from './src/anonymous.svg';
import ApiKeySVG from './src/api-key.svg';
import AppleCardSVG from './src/apple-card.svg';
import ArrowLeftSVG from './src/arrow-left.svg';
import ArrowSVG from './src/arrow.svg';
import BanSVG from './src/ban.svg';
import BillFilledSVG from './src/bill-filled.svg';
import BillSVG from './src/bill.svg';
import BrowserSVG from './src/browser.svg';
import BulbSVG from './src/bulb.svg';
import CardSwitchSVG from './src/card-switch.svg';
import CaretSVG from './src/caret.svg';
import ChainLinkSVG from './src/chain-link.svg';
import CheckFilledSVG from './src/check-filled.svg';
import CheckSVG from './src/check.svg';
import CheckedShieldSVG from './src/checked-shield.svg';
import CircleSVG from './src/circle.svg';
import CitiCardSVG from './src/citi-card.svg';
import ClockSVG from './src/clock.svg';
import ClosedEyeSVG from './src/closed-eye.svg';
import CloudDownloadSVG from './src/cloud-download.svg';
import CloudUploadSVG from './src/cloud-upload.svg';
import ComputerInSVG from './src/computer-in.svg';
import ComputerKeySVG from './src/computer-key.svg';
import ComputerOutSVG from './src/computer-out.svg';
import ComputerSVG from './src/computer.svg';
import CopyRoundSVG from './src/copy-round.svg';
import CopySVG from './src/copy.svg';
import CreditCardSVG from './src/credit-card.svg';
import CreditSVG from './src/credit.svg';
import DashSVG from './src/dash.svg';
import DiscoverCardSVG from './src/discover-card.svg';
import DomainSVG from './src/domain.svg';
import EllipsisSVG from './src/ellipsis.svg';
import EmailSVG from './src/email.svg';
import ExBoxSVG from './src/ex-box.svg';
import ExCircleSVG from './src/ex-circle.svg';
import ExSVG from './src/ex.svg';
import EyeSVG from './src/eye.svg';
import FilterSVG from './src/filter.svg';
import FlagSVG from './src/flag.svg';
import GamepadSVG from './src/gamepad.svg';
import GavelSVG from './src/gavel.svg';
import GearSVG from './src/gear.svg';
import GroupManagementSVG from './src/group-management.svg';
import GroupShapesSVG from './src/group-shapes.svg';
import GroupSVG from './src/group.svg';
import HelpSVG from './src/help.svg';
import HourglassSVG from './src/hourglass.svg';
import InfoFilledSVG from './src/info-filled.svg';
import InfoSVG from './src/info.svg';
import JcbCardSVG from './src/jcb-card.svg';
import KeySVG from './src/key.svg';
import KeyboardSVG from './src/keyboard.svg';
import KickSVG from './src/kick.svg';
import LaptopSVG from './src/laptop.svg';
import LawSVG from './src/law.svg';
import LeaveSVG from './src/leave.svg';
import ListSVG from './src/list.svg';
import LoaderSVG from './src/loader.svg';
import LockDonutSVG from './src/lock-donut.svg';
import LockOpenSVG from './src/lock-open.svg';
import LockSVG from './src/lock.svg';
import MasterCardSVG from './src/mastercard-card.svg';
import MemberSVG from './src/member.svg';
import MenuSVG from './src/menu.svg';
import MessageSVG from './src/message.svg';
import MinusSVG from './src/minus.svg';
import MouseSVG from './src/mouse.svg';
import PauseCircleSVG from './src/pause-circle.svg';
import PaypalCardSVG from './src/paypal-card.svg';
import PencilSVG from './src/pencil.svg';
import PermissionSVG from './src/permission.svg';
import PinSVG from './src/pin.svg';
import PlusSVG from './src/plus.svg';
import PrivacySettingsSVG from './src/privacy-settings.svg';
import ProgressSVG from './src/progress.svg';
import ReceiptSVG from './src/receipt.svg';
import RedoCheckSVG from './src/redo-check.svg';
import RefreshSVG from './src/refresh.svg';
import ReloadSVG from './src/reload.svg';
import RetrySVG from './src/retry.svg';
import RevertArrowSVG from './src/revert-arrow.svg';
import ScheduleSVG from './src/schedule.svg';
import ScimKeySVG from './src/scim-key.svg';
import ScimSVG from './src/scim.svg';
import SearchSVG from './src/search.svg';
import SendSVG from './src/send.svg';
import ServerRackSVG from './src/server-rack.svg';
import ShieldSVG from './src/shield.svg';
import StarSVG from './src/star.svg';
import StopwatchSVG from './src/stopwatch.svg';
import StripeCardSVG from './src/stripe-card.svg';
import SuccessSVG from './src/success.svg';
import TagSVG from './src/tag.svg';
import TeamSVG from './src/team.svg';
import TitleSVG from './src/title.svg';
import TrafficSVG from './src/traffic.svg';
import TrashSVG from './src/trash.svg';
import UnionpayCardSVG from './src/unionpay-card.svg';
import UploadSVG from './src/upload.svg';
import UserHollowSVG from './src/user-hollow.svg';
import UserSVG from './src/user.svg';
import VisaCardSVG from './src/visa-card.svg';
import WarningSignSVG from './src/warning-sign.svg';
import WarningSVG from './src/warning.svg';
import WarpSVG from './src/warp.svg';
import WebSVG from './src/web.svg';

const styles = {
  width: '1em',
  height: '1em',
  '[stroke^="#"]': {
    fill: 'transparent',
    stroke: 'currentColor'
  },
  '[fill^="#"]': {
    fill: 'currentColor',
    stroke: 'transparent'
  }
};

export const adminRoles = styled(AdminRolesSVG, styles);
export const anonymous = styled(AnonymousSVG, styles);
export const apiKey = styled(ApiKeySVG, styles);
export const arrow = styled(ArrowSVG, styles);
export const arrowLeft = styled(ArrowLeftSVG, styles);
export const ban = styled(BanSVG, styles);
export const bill = styled(BillSVG, styles);
export const billFilled = styled(BillFilledSVG, styles);
export const browser = styled(BrowserSVG, styles);
export const bulb = styled(BulbSVG, styles);
export const caret = styled(CaretSVG, styles);
export const chainLink = styled(ChainLinkSVG, styles);
export const check = styled(CheckSVG, styles);
export const checkedShield = styled(CheckedShieldSVG, styles);
export const checkFilled = styled(CheckFilledSVG, styles);
export const circle = styled(CircleSVG, styles);
export const clock = styled(ClockSVG, styles);
export const closedEye = styled(ClosedEyeSVG, styles);
export const cloudDownload = styled(CloudDownloadSVG, styles);
export const cloudUpload = styled(CloudUploadSVG, styles);
export const computer = styled(ComputerSVG, styles);
export const computerIn = styled(ComputerInSVG, styles);
export const computerKey = styled(ComputerKeySVG, styles);
export const computerOut = styled(ComputerOutSVG, styles);
export const copy = styled(CopySVG, styles);
export const copyRound = styled(CopyRoundSVG, styles);
export const credit = styled(CreditSVG, styles);
export const dash = styled(DashSVG, styles);
export const domain = styled(DomainSVG, styles);
export const ellipsis = styled(EllipsisSVG, styles);
export const email = styled(EmailSVG, styles);
export const ex = styled(ExSVG, styles);
export const exBox = styled(ExBoxSVG, styles);
export const exCircle = styled(ExCircleSVG, styles);
export const eye = styled(EyeSVG, styles);
export const filter = styled(FilterSVG, styles);
export const flag = styled(FlagSVG, styles);
export const gamepad = styled(GamepadSVG, styles);
export const gavel = styled(GavelSVG, styles);
export const gear = styled(GearSVG, styles);
export const group = styled(GroupSVG, styles);
export const groupManagement = styled(GroupManagementSVG, styles);
export const groupShapes = styled(GroupShapesSVG, styles);
export const help = styled(HelpSVG, styles);
export const hourglass = styled(HourglassSVG, styles);
export const info = styled(InfoSVG, styles);
export const infoFilled = styled(InfoFilledSVG, styles);
export const key = styled(KeySVG, styles);
export const keyboard = styled(KeyboardSVG, styles);
export const kick = styled(KickSVG, styles);
export const laptop = styled(LaptopSVG, styles);
export const law = styled(LawSVG, styles);
export const leave = styled(LeaveSVG, styles);
export const list = styled(ListSVG, styles);
export const loader = styled(LoaderSVG, styles);
export const lock = styled(LockSVG, styles);
export const lockDonut = styled(LockDonutSVG, styles);
export const lockOpen = styled(LockOpenSVG, styles);
export const member = styled(MemberSVG, styles);
export const menu = styled(MenuSVG, styles);
export const message = styled(MessageSVG, styles);
export const minus = styled(MinusSVG, styles);
export const mouse = styled(MouseSVG, styles);
export const pauseCircle = styled(PauseCircleSVG, styles);
export const pencil = styled(PencilSVG, styles);
export const permission = styled(PermissionSVG, styles);
export const pin = styled(PinSVG, styles);
export const plus = styled(PlusSVG, styles);
export const privacySettings = styled(PrivacySettingsSVG, styles);
export const progress = styled(ProgressSVG, styles);
export const receipt = styled(ReceiptSVG, styles);
export const refresh = styled(RefreshSVG, styles);
export const redoCheck = styled(RedoCheckSVG, styles);
export const reload = styled(ReloadSVG, styles);
export const retry = styled(RetrySVG, styles);
export const revertArrow = styled(RevertArrowSVG, styles);
export const schedule = styled(ScheduleSVG, styles);
export const scim = styled(ScimSVG, styles);
export const scimKey = styled(ScimKeySVG, styles);
export const search = styled(SearchSVG, styles);
export const send = styled(SendSVG, styles);
export const serverRack = styled(ServerRackSVG, styles);
export const shield = styled(ShieldSVG, styles);
export const star = styled(StarSVG, styles);
export const stopwatch = styled(StopwatchSVG, styles);
export const success = styled(SuccessSVG, styles);
export const tag = styled(TagSVG, styles);
export const team = styled(TeamSVG, styles);
export const title = styled(TitleSVG, styles);
export const traffic = styled(TrafficSVG, styles);
export const trash = styled(TrashSVG, styles);
export const upload = styled(UploadSVG, styles);
export const user = styled(UserSVG, styles);
export const userHollow = styled(UserHollowSVG, styles);
export const warning = styled(WarningSVG, styles);
export const warningSign = styled(WarningSignSVG, styles);
export const warp = styled(WarpSVG, styles);
export const web = styled(WebSVG, styles);

// All credit card icons
export const creditCard = styled(CreditCardSVG, styles);
export const amexCard = styled(AmexCardSVG, styles);
export const appleCard = styled(AppleCardSVG, styles);
export const switchCard = styled(CardSwitchSVG, styles);
export const citiCard = styled(CitiCardSVG, styles);
export const discoverCard = styled(DiscoverCardSVG, styles);
export const jcbCard = styled(JcbCardSVG, styles);
export const mastercard = styled(MasterCardSVG, styles);
export const paypalCard = styled(PaypalCardSVG, styles);
export const stripeCard = styled(StripeCardSVG, styles);
export const visaCard = styled(VisaCardSVG, styles);
export const unionpayCard = styled(UnionpayCardSVG, styles);
