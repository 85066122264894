import type { ComponentProps, ReactNode } from 'react';

import { styled } from '@parsec/styles';

import FieldLabelContext, { useFieldLabel } from './FieldLabelContext';

export interface FieldLabelProps {
  children: ReactNode;
  hasError?: boolean;
  isValid?: boolean;
  version?: 'newFont';
}

function FieldLabel(props: FieldLabelProps) {
  const { children, hasError, isValid, version, ...rest } = props;

  return (
    <FieldLabelContext hasError={hasError} isValid={isValid} version={version}>
      <Wrapper {...rest}>{children}</Wrapper>
    </FieldLabelContext>
  );
}

export default FieldLabel;

interface LabelProps extends ComponentProps<typeof BaseLabel> {
  label: string;
  required?: boolean;
  children: ReactNode;
}

const Label = ({ label, required, children, ...rest }: LabelProps) => {
  const { version } = useFieldLabel();
  return (
    <BaseLabel {...rest}>
      {label && (
        <Text required={required} version={version}>
          {label}
        </Text>
      )}
      {children}
    </BaseLabel>
  );
};

interface FieldLabelMessageProps extends ComponentProps<typeof Explanation> {
  children: ReactNode;
  match?:
    | boolean
    | ((args: { hasError: boolean; isValid: boolean }) => boolean);
  hasError?: boolean;
  isValid?: boolean;
  align?: 'left' | 'right';
}

const FieldLabelMessage = ({
  children,
  match = true,
  hasError: hasErrorFromProps,
  isValid: isValidFromProps,
  align = 'left',
  ...rest
}: FieldLabelMessageProps) => {
  const {
    hasError: hasErrorFromCtx,
    isValid: isValidFromCtx,
    version
  } = useFieldLabel();

  const hasError = hasErrorFromProps || hasErrorFromCtx || false;
  const isValid = isValidFromProps || isValidFromCtx || false;

  // match defaults to true so it matches everything, and is therefore always true
  const showMessage =
    typeof match === 'boolean'
      ? match
      : match?.({
          hasError,
          isValid
        });

  return showMessage ? (
    <Explanation
      hasError={hasError}
      isValid={isValid}
      align={align}
      version={version}
      {...rest}
    >
      {children}
    </Explanation>
  ) : null;
};

interface FieldLabelCountProps extends Pick<FieldLabelMessageProps, 'match'> {
  count: number;
  maxCount?: number;
}

const FieldLabelCount = ({
  count,
  maxCount,
  match = true,
  ...rest
}: FieldLabelCountProps) => {
  const { hasError, isValid } = useFieldLabel();
  return (
    <FieldLabelMessage
      hasError={hasError}
      isValid={isValid}
      align="right"
      match={match}
      {...rest}
    >
      {count}/{maxCount}
    </FieldLabelMessage>
  );
};

const HelperTextContainer = ({
  children,
  ...rest
}: {
  children: ReactNode;
}) => {
  return <HelperTextWrapper {...rest}>{children}</HelperTextWrapper>;
};

FieldLabel.Label = Label;
FieldLabel.Message = FieldLabelMessage;
FieldLabel.Count = FieldLabelCount;
FieldLabel.HelperTextContainer = HelperTextContainer;

const Wrapper = styled('div', {
  display: 'grid',
  rowGap: '$medium'
});

const BaseLabel = styled('label', {
  display: 'grid',
  rowGap: '$medium'
});

const Text = styled('span', {
  fontWeight: 'normal',
  height: '1.2rem',
  paddingLeft: '.4rem',
  variants: {
    required: {
      true: {
        '&:after': {
          content: '• ',
          color: '$warning500',
          paddingLeft: '$xsmall'
        }
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.1rem'
      }
    }
  }
});

const HelperTextWrapper = styled('div', {
  display: 'grid',
  columnGap: '.8rem',
  gridTemplateAreas: '"left . right"'
});

const Explanation = styled('span', {
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  overflow: 'wrap',
  paddingLeft: '$small',
  variants: {
    align: {
      left: {
        gridArea: 'left'
      },
      right: {
        gridArea: 'right',
        justifySelf: 'end'
      }
    },
    isValid: {
      true: { color: '$success500' }
    },
    hasError: {
      true: { color: '$error500' }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.1rem'
      }
    }
  }
});
