// libraries
import { forwardRef, ReactNode, Ref, ComponentProps } from 'react';

// @parsec
import { styled, keyframes, type VariantProps } from '@parsec/styles';

import Icon from '../Icon';

// STYLES
const Element = styled('button', {
  position: 'relative',
  display: 'inline-grid',
  gridTemplateRows: '1fr',
  gridAutoFlow: 'column',
  columnGap: '$medium',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '$body',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textShadow: 'none',
  height: '3.6rem',
  lineHeight: '3.6rem',
  padding: '0 1.6rem',
  border: 'none',
  borderRadius: '5.4rem',
  backgroundColor: '$primary500',
  color: '$consoleWhite',
  transition: '0.25s all ease',
  cursor: 'pointer',
  outlineOffset: '-.2rem',

  '&:hover': {
    backgroundColor: '$primary400',
    boxShadow: 'none'
  },
  '&:focus': {
    backgroundColor: '$primary400',
    outlineWidth: '.2rem',
    outlineStyle: 'solid'
  },
  '&:active': {
    backgroundColor: '$primary600',
    boxShadow: 'none'
  },

  '&:disabled, &[disabled]': {
    backgroundColor: '$duskull',
    color: '$nice',
    cursor: 'default',
    boxShadow:
      '0 $space$xxsmall $space$xsmall rgba(0, 0, 0, 0.18), inset 0 $space$xxsmall $space$xsmall rgba(255, 255, 255, 0.07);'
  },
  variants: {
    wide: { true: { padding: '0 4.8rem' } },
    size: {
      small: {
        height: '3.2rem',
        padding: '0 1.4rem',
        lineHeight: '3rem',
        fontSize: '$info'
      },
      medium: {
        height: '3.6rem',
        padding: '0 1.6rem',
        lineHeight: '3.6rem',
        fontSize: '$body'
      },
      large: {
        height: '4.8rem',
        padding: '0 2.4rem',
        lineHeight: '4.6rem',
        fontSize: '$header'
      }
    },

    level: {
      primary: {
        backgroundColor: '$primary500',
        color: '$consoleWhite',

        '&:hover, &:focus': {
          backgroundColor: '$primary400'
        },
        '&:active': {
          backgroundColor: '$primary600'
        }
      },
      secondary: {
        backgroundColor: '$chandelure',
        color: '$consoleWhite',
        boxShadow:
          '0 $space$xxsmall $space$xsmall rgba(0, 0, 0, 0.18), inset 0 $space$xxsmall $space$xsmall rgba(255, 255, 255, 0.07);',
        '&:focus': {
          backgroundColor: '$chandelure',
          color: '$consoleWhite'
        },
        '&:hover': {
          backgroundColor: '$consoleWhite',
          color: '$ultraDark'
        },
        '&:active': {
          backgroundColor: '$consoleWhite',
          color: '$ultraDark'
        },
        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      },
      link: {
        backgroundColor: 'transparent',

        '&:hover, &:focus, &:active': {
          backgroundColor: 'transparent',
          color: '$primary400'
        },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$duskull'
        }
      }
    },
    kind: {
      brand: {
        backgroundColor: '$brand500',
        color: '$consoleWhite',
        '&:hover, &:focus': { backgroundColor: '$brand400' },
        '&:active': { backgroundColor: '$brand600' },

        '&:disabled, &[disabled]': { backgroundColor: '$duskull' }
      },
      primary: {
        backgroundColor: '$primary500',
        color: '$consoleWhite',
        '&:hover, &:focus': { backgroundColor: '$primary400' },
        '&:active': { backgroundColor: '$primary600' },

        '&:disabled, &[disabled]': { backgroundColor: '$duskull' }
      },
      neutral: {
        backgroundColor: '$primary500',
        color: '$consoleWhite',
        '&:hover, &:focus': { backgroundColor: '$primary400' },
        '&:active': { backgroundColor: '$primary600' },
        '&:disabled, &[disabled]': { backgroundColor: '$duskull' }
      },
      success: {
        backgroundColor: '$success500',
        color: '$consoleWhite',
        '&:hover, &:focus': { backgroundColor: '$success400' },
        '&:active': { backgroundColor: '$success600' },

        '&:disabled, &[disabled]': { backgroundColor: '$duskull' }
      },
      error: {
        backgroundColor: '$error500',
        color: '$consoleWhite',

        '&:hover, &:focus': { backgroundColor: '$error400' },
        '&:active': { backgroundColor: '$error600' },

        '&:disabled, &[disabled]': { backgroundColor: '$duskull' }
      },
      warning: {
        backgroundColor: '$warning500',
        color: '$ultraDark',

        '&:hover, &:focus': { backgroundColor: '$warning400' },
        '&:active': { backgroundColor: '$warning600' },

        '&:disabled, &[disabled]': { backgroundColor: '$duskull' }
      },
      dark: {
        backgroundColor: '$ultraDark',
        color: '$consoleWhite',

        '&:hover, &:focus, &:active': {
          backgroundColor: '$consoleWhite',
          color: '$ultraDark'
        },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$ultraDark'
        }
      },
      ghost: {
        background: 'transparent',
        border: '.1rem solid ',
        '&:hover, &:focus': { backgroundColor: '$ultraDark' },
        '&:active': { backgroundColor: '$carkol' },
        '&:disabled, &[disabled]': { backgroundColor: 'transparent' }
      },
      white: {
        color: '$ultraDark',
        backgroundColor: '$consoleWhite',
        boxShadow: '0 0.1rem 0 0 #FFFFFF12 inset, 0 0.1rem 0.2rem 0 #0000002E',
        '&:focus': {
          outlineColor: '$ultraDark',
          outlineWidth: '0.2rem',
          backgroundColor: '$consoleWhite',
          color: '$ultraDark'
        },
        '&:hover': { backgroundColor: '$ultraDark', color: '$consoleWhite' },
        '&:disabled, &[disabled]': {
          backgroundColor: '$samehada',
          color: '$nice'
        }
      }
    }
  },
  compoundVariants: [
    { wide: true, size: 'small', css: { padding: '0 4.8rem' } },
    { wide: true, size: 'medium', css: { padding: '0 6rem' } },
    { wide: true, size: 'large', css: { padding: '0 7.2rem' } },
    {
      kind: 'brand',
      level: 'secondary',
      css: {
        backgroundColor: '$chandelure',
        color: '$brand500',
        '&:hover, &:focus': { backgroundColor: '$pancham', color: '$brand500' },
        '&:active': { backgroundColor: '$computerBlack', color: '$brand500' },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      }
    },
    {
      kind: 'primary',
      level: 'secondary',
      css: {
        backgroundColor: '$chandelure',
        color: '$primary500',
        '&:hover, &:focus': {
          backgroundColor: '$pancham',
          color: '$primary500'
        },
        '&:active': {
          backgroundColor: '$computerBlack',
          color: '$primary500'
        },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      }
    },
    {
      kind: 'neutral',
      level: 'secondary',
      css: {
        backgroundColor: '$chandelure',

        color: '$consoleWhite',
        '&:focus': {
          backgroundColor: '$chandelure',
          color: '$consoleWhite'
        },
        '&:hover': {
          backgroundColor: '$consoleWhite',
          color: '$ultraDark'
        },

        '&:active': {
          backgroundColor: '$consoleWhite',
          color: '$ultraDark'
        },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      }
    },
    {
      kind: 'success',
      level: 'secondary',
      css: {
        backgroundColor: '$zekrom',
        color: '$success500',
        '&:hover, &:focus': {
          backgroundColor: '$success500',
          color: '$consoleWhite'
        },
        '&:active': {
          backgroundColor: '$computerBlack',
          color: '$success500'
        },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      }
    },
    {
      kind: 'error',
      level: 'secondary',
      css: {
        backgroundColor: '$chandelure',
        color: '$error500',
        '&:hover, &:focus': {
          backgroundColor: '$error500',
          color: '$consoleWhite'
        },
        '&:active': { backgroundColor: '$computerBlack', color: '$error500' },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      }
    },
    {
      kind: 'warning',
      level: 'secondary',
      css: {
        backgroundColor: '$chandelure',
        color: '$warning500',
        '&:hover, &:focus': {
          backgroundColor: '$warning500',
          color: '$ultraDark'
        },
        '&:active': { backgroundColor: '$computerBlack', color: '$warning500' },

        '&:disabled, &[disabled]': {
          backgroundColor: '$duskull',
          color: '$nice'
        }
      }
    },
    {
      kind: 'brand',
      level: 'link',
      css: {
        backgroundColor: 'transparent',
        color: '$brand500',

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          color: '$brand400'
        },
        '&:active': { backgroundColor: 'transparent', color: '$brand600' },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$nice'
        }
      }
    },
    {
      kind: 'primary',
      level: 'link',
      css: {
        backgroundColor: 'transparent',
        color: '$primary500',

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          color: '$primary400'
        },
        '&:active': { backgroundColor: 'transparent', color: '$primary600' },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$nice'
        }
      }
    },
    {
      kind: 'success',
      level: 'link',
      css: {
        backgroundColor: 'transparent',
        color: '$success500',

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          color: '$success400'
        },
        '&:active': { backgroundColor: 'transparent', color: '$success600' },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$nice'
        }
      }
    },
    {
      kind: 'error',
      level: 'link',
      css: {
        backgroundColor: 'transparent',
        color: '$error500',

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          color: '$error400'
        },
        '&:active': { backgroundColor: 'transparent', color: '$error600' },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$nice'
        }
      }
    },
    {
      kind: 'warning',
      level: 'link',
      css: {
        backgroundColor: 'transparent',
        color: '$warning500',

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          color: '$warning400'
        },
        '&:active': { backgroundColor: 'transparent', color: '$warning600' },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$nice'
        }
      }
    },
    {
      kind: 'ghost',
      level: 'secondary',
      css: {
        backgroundColor: 'transparent',
        color: '$ultraDark',
        border: '.1rem solid $ultraDark',
        '&:hover, &:focus': {
          backgroundColor: '$ultraDark',
          color: '$consoleWhite'
        },
        '&:active': { backgroundColor: '$carkol', color: '$consoleWhite' },

        '&:disabled, &[disabled]': {
          backgroundColor: 'transparent',
          color: '$ultraDark'
        }
      }
    }
  ]
});

const Content = styled('span', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  transition: 'opacity 125ms ease',
  variants: {
    loading: {
      true: { opacity: 0 },
      false: { opacity: 1 }
    }
  }
});

const spin = keyframes({
  '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
  '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' }
});

const Loader = styled(Icon, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '2.4rem',
  height: '2.4rem',
  transform: 'translate(-50%, -50%)',
  animation: `${spin} linear 2s infinite`,
  fontSize: '$title'
});

const IconWrapper = styled('div', {
  margin: 0,
  variants: {
    position: {
      left: {
        gridColumn: '1'
      },
      right: {
        gridColumn: '2'
      }
    },
    size: {
      small: {
        marginLeft: '$xlarge',
        marginRight: '-$small',
        '&::before': { right: '3.2rem' }
      },
      medium: {
        marginLeft: '$xxlarge',
        marginRight: '-$small',
        '&::before': { right: '4rem' }
      },
      large: {
        marginLeft: '$xxxlarge',
        marginRight: '-$large',
        '&::before': { right: '4.8rem' }
      }
    }
  }
});

// COMPONENT
export type ButtonProps = ComponentProps<typeof Element> & {
  className?: string;
  loading?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  ref?: Ref<HTMLButtonElement>;
  href?: string;
  as?: string;
  target?: HTMLAnchorElement['target'];
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function (props, ref) {
    const {
      className,
      children,
      loading,
      disabled,
      icon,
      iconPosition = 'right',
      size: sizeFromProps,
      href,
      as,
      target: targetFromProps,
      ...rest
    } = props;

    const size = iconPosition === 'left' ? undefined : sizeFromProps;

    const link = href
      ? {
          href,
          as: 'a',
          target: targetFromProps ?? '_blank', // This will allow us to keep the default behavior and specify the target if we're also passing in an href
          rel: 'noreferrer noopener'
        }
      : {};

    return (
      <Element
        className={className}
        as={as}
        {...link}
        {...rest}
        size={size}
        disabled={disabled || loading}
        ref={ref}
      >
        <Content loading={loading}>{children}</Content>
        {icon && (
          <IconWrapper size={size} position={iconPosition}>
            {icon}
          </IconWrapper>
        )}
        {loading && <Loader name="loader" />}
      </Element>
    );
  }
);

Button.displayName = 'Button';

export default Button;

export type ButtonVariants = VariantProps<typeof Element>;
