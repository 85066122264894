import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { Team, apiKeyData, data, team, APIKey } from './schema';

/******************************************************************************
 * Create Team
 ******************************************************************************/

export interface CreateTeamReq {
  name: string;
  standard_seats: number;
  plan_id: string;
  token: string;
  type: 'active' | 'trial';
  customer: {
    email: string;
    company?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    tax_id?: string;
    billing_address: {
      line1: string;
      line2?: string;
      city: string;
      state: string;
      zip?: string;
      country: string;
    };
  };
  coupon?: string;
}

export const createTeam = (options: ApiOptions) => (body: CreateTeamReq) =>
  request({
    type: Method.POST,
    url: `${options.kessel}/teams`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(team),
    body
  });

/******************************************************************************
 * Get Team
 ******************************************************************************/

export interface GetTeamReq {
  id: string;
}

export const getTeam =
  (options: ApiOptions) =>
  async ({ id }: GetTeamReq) => {
    return request<{ data: Team }>({
      type: Method.GET,
      url: `${options.kessel}/teams/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
      // validator: data(team)
    });
  };

/******************************************************************************
 * Update Team
 ******************************************************************************/

export interface UpdateTeamNameReq {
  id: string;
  name: string;
}

export const updateTeamName =
  (options: ApiOptions) =>
  ({ id, ...body }: UpdateTeamNameReq) =>
    request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body,
      validator: data(team)
    });

/******************************************************************************
 * Delete Team
 ******************************************************************************/

export interface DeleteTeamReq {
  id: string;
}

export const deleteTeam =
  (options: ApiOptions) =>
  ({ id }: DeleteTeamReq) =>
    request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });

/******************************************************************************
 * Generate API Key
 ******************************************************************************/

export interface GenerateAPIKeyReq {
  team_id: string;
  name: string;
  actions?: APIKey['actions'];
}

export const generateAPIKey =
  (options: ApiOptions) =>
  /**
   * Generates an API key.
   */
  (req: GenerateAPIKeyReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/api-keys`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: apiKeyData,
      middleware: options.middleware,
      body
    });
  };
