import type { StaticImageData } from 'next/image';
import type { ReactNode } from 'react';

import Head from 'next/head';

// @parsec
import { handleFormSubmit } from '@parsec/form';
import { styled, CSS } from '@parsec/styles';

interface Props<T> {
  id?: string;
  className?: string;
  hero?: StaticImageData;
  title?: string;
  onSubmit?(values: T): void | Promise<void>;
  children: ReactNode;
  width?: 'standard' | 'large' | 'largeAlways' | 'xlarge';
  alignTitle?: 'left' | 'right' | 'center';
  css?: CSS;
}

export default function Form<T = unknown>(props: Props<T>) {
  const {
    id,
    className,
    hero,
    title,
    onSubmit,
    children,
    width = 'standard',
    alignTitle = 'left',
    css
  } = props;
  const isForm = Boolean(onSubmit);

  return (
    <Wrapper
      id={id}
      className={className}
      onSubmit={onSubmit && handleFormSubmit<T>(onSubmit)}
      method={isForm ? 'post' : undefined}
      // prevents TS from yelling about unknown prop "method" on div element
      as={(isForm ? 'form' : 'div') as 'form'}
      css={css}
    >
      {title ? (
        <Head>
          <title>{`${title} | Parsec`}</title>
        </Head>
      ) : null}
      {hero ? (
        <Hero>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={hero.src} alt="" width={250} height={125} />
        </Hero>
      ) : null}
      {title ? <Title align={alignTitle}>{title}</Title> : null}
      <Body width={width}>{children}</Body>
    </Wrapper>
  );
}

const Wrapper = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge',
  transition: '0.25s width ease-in-out'
});

const Hero = styled('div', {
  margin: '0 auto'
});

const Title = styled('h2', {
  fontSize: '$title',
  lineHeight: '$title',
  variants: {
    align: {
      left: {
        textAlign: 'left'
      },
      center: {
        textAlign: 'center'
      },
      right: {
        textAlign: 'right'
      }
    }
  }
});

const Body = styled('div', {
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',

  variants: {
    width: {
      standard: {
        '@large': {
          width: '38rem'
        }
      },
      large: {
        '@large': {
          width: '47rem'
        }
      },
      largeAlways: {
        width: '47rem'
      },
      xlarge: {
        '@large': {
          width: '56rem'
        }
      }
    }
  }
});
