// libraries
import { useCallback, useState, FormEvent, ChangeEvent } from 'react';

// @parsec
import {
  styled,
  ErrorMessage,
  FieldLabel,
  Input,
  Modal
} from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import { useDeleteAccount } from '@parsec/queries';

interface Props {
  email: string;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

export default function DeleteAccountModal(props: Props) {
  const { isOpen, onClose, onAfterClose } = props;

  const [email, setEmail] = useState('');

  const deleteAccount = useDeleteAccount(),
    mutateAsync = deleteAccount.mutateAsync;

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      handleFormSubmit<{ email: string }>(async values => {
        if (values.email !== email) return;
        await mutateAsync();
      })(e);
    },
    [email, mutateAsync]
  );

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    []
  );

  return (
    <Modal
      title="Delete Account"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      actions={[
        {
          text: 'Delete Account',
          form: 'delete_account',
          kind: 'error',
          disabled: email !== props.email
        },
        { text: 'Nevermind, Go Back', level: 'secondary', onClick: onClose }
      ]}
    >
      <Form id="delete_account" onSubmit={handleSubmit}>
        <P>
          We&apos;re sorry to see you go (╥&#xfe4f;╥) if you have time, please{' '}
          <a
            href="https://iteratehq.com/parsec/5a286e28fb200e0001008089"
            target="_blank"
            rel="noopener noreferrer"
          >
            let us know why you&apos;re leaving Parsec
          </a>{' '}
          so we can make it better.
        </P>

        <H2>If you delete your account…</H2>
        <P>
          Your account will immediately be permanently deleted. You will no
          longer be able to log in or use Parsec. All of your personal
          information will be deleted from Parsec&apos;s servers.
        </P>

        <H2>Enter your email to confirm:</H2>
        <FieldLabel>
          <FieldLabel.Label label="Email">
            <Input
              name="email"
              required
              autoFocus
              value={email}
              placeholder={props.email}
              onChange={handleInput}
            />
          </FieldLabel.Label>
        </FieldLabel>

        {deleteAccount.error ? (
          <ErrorMessage>{deleteAccount.error.error}</ErrorMessage>
        ) : null}
      </Form>
    </Modal>
  );
}

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$medium'
});

const H2 = styled('h2', {
  fontFamily: '$default',
  fontSize: '$attribution',
  lineHeight: '$attribution'
});

const P = styled('p', {
  marginBottom: '$xxxlarge'
});
