import {
  ReactNode,
  forwardRef,
  ComponentProps,
  type ComponentPropsWithRef
} from 'react';

import { styled } from '@parsec/styles';

import { useFieldLabel } from '../FieldLabel/FieldLabelContext';
import Icon, { IconNames } from '../Icon';

export type HTMLInputProps = ComponentPropsWithRef<typeof StyledInput>;

export interface InputProps extends HTMLInputProps {
  icon?: IconNames | ReactNode;
  accessory?: IconNames | ReactNode;
  withDropdown?: boolean;
  hasError?: boolean;
  isValid?: boolean;
  version?: 'newFont';
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const {
      className,
      icon,
      accessory,
      disabled,
      withDropdown,
      hasError,
      isValid,
      version,
      ...rest
    } = props;

    const iconPadding =
      icon && accessory
        ? 'both'
        : (icon && 'icon') || (accessory && 'accessory') || undefined;

    const renderIcon = () => {
      if (icon) {
        if (typeof icon === 'string') {
          return (
            <InputIcon
              name={icon as IconNames}
              withDropdown={withDropdown}
              hasError={hasError}
              isValid={isValid}
            />
          );
        }
        return icon;
      }
      return null;
    };

    const renderAccessory = () => {
      if (accessory) {
        if (typeof accessory === 'string') {
          return (
            <InputIcon
              name={accessory as IconNames}
              withDropdown={withDropdown}
              hasError={hasError}
              isValid={isValid}
              type="accessory"
              title={accessory}
            />
          );
        }
        return accessory;
      }
      return null;
    };

    return (
      <InputWrapper className={className}>
        <StyledInput
          {...rest}
          ref={ref}
          iconPadding={iconPadding}
          disabled={disabled}
          withDropdown={withDropdown}
          autoComplete="off"
          version={version}
        />
        {renderIcon()}
        {renderAccessory()}
      </InputWrapper>
    );
  }
);

export interface InputIconProps
  extends Omit<ComponentProps<typeof StyledIcon>, 'name'> {
  name:
    | IconNames
    | ((args: { hasError?: boolean; isValid?: boolean }) => IconNames);
  hasError?: boolean;
  isValid?: boolean;
  withDropdown?: boolean;
}

const InputIcon = ({
  hasError: hasErrorFromProps,
  isValid: isValidFromProps,
  name,
  withDropdown = false,
  type,
  ...rest
}: InputIconProps) => {
  const { hasError: hasErrorFromCtx, isValid: isValidFromCtx } =
    useFieldLabel();

  const hasError = hasErrorFromProps || hasErrorFromCtx;
  const isValid = isValidFromProps || isValidFromCtx;

  return (
    <StyledIcon
      name={typeof name === 'string' ? name : name({ hasError, isValid })}
      hasError={hasError}
      isValid={isValid}
      withDropdown={withDropdown}
      type={type}
      {...rest}
    />
  );
};

const CompoundInput = Object.assign({}, Input, {
  Icon: InputIcon
});

export default CompoundInput;

const InputWrapper = styled('div', {
  position: 'relative'
});

const StyledIcon = styled(Icon, {
  position: 'absolute',
  top: '50%',
  left: '$medium',
  transform: 'translateY(-50%)',
  width: '$xxlarge',

  variants: {
    type: {
      accessory: {
        right: '$medium',
        left: 'unset'
      }
    },
    hasError: {
      true: {
        color: '$error500 !important'
      }
    },
    isValid: {
      true: {
        color: '$success500 !important'
      }
    },
    withDropdown: {
      true: {
        left: '14rem'
      }
    }
  }
});

const StyledInput = styled('input', {
  width: '100%',
  height: '3.6rem',
  backgroundColor: '$cereza',
  borderRadius: '$medium',
  transition: '125ms box-shadow ease',
  boxShadow: '0rem $space$xxsmall 0 rgba(255, 255, 255, 0.1)',
  padding: '.6rem 1rem',
  color: '$consoleWhite',
  fontSize: '1.4rem',
  lineHeight: '$attribution',
  textAlign: 'inherit',
  '& ~ svg': {
    color: '$nice'
  },
  '&::placeholder': {
    color: '$rhyhorn'
  },

  '&[type="number"]': {
    MozAppearance: 'textfield'
  },

  '&:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },
  '&:-webkit-autofill': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },
  '&:hover': {
    boxShadow:
      'inset 0 0 0 $space$xxsmall $colors$pangoro, 0px $space$xxsmall 0px rgba(255,255,255,0.1)'
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 $space$xsmall $colors$primary500',
    color: '$consoleWhite',
    border: 'none',
    outlineStyle: 'none',
    '& ~ svg': {
      color: '$consoleWhite '
    }
  },
  '&:disabled': {
    color: '$nice',
    backgroundColor: '$duskull',
    '&::placeholder': {
      color: '$nice'
    },
    '& ~ svg': {
      color: '$nice'
    }
  },

  '&:invalid': {
    boxShadow: 'inset 0 0 0 $space$xxsmall $colors$error500'
  },

  '&::-moz-focus-inner': {
    border: 'none'
  },

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },

  variants: {
    iconPadding: {
      both: {
        paddingLeft: '3.6rem',
        paddingRight: '3.2rem'
      },
      icon: {
        paddingLeft: '3.6rem'
      },
      accessory: {
        paddingRight: '3.2rem'
      }
    },
    withDropdown: {
      true: {
        padding: '$medium $medium .7rem 16.7rem',
        boxShadow: 'none',
        borderRadius: '4rem'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$info'
      }
    }
  }
});
