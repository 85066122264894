import { styled, LegacyModal, Loading, Button } from '@parsec/components';
import {
  useGetWarpBillingDetails,
  useUpdateWarpBillingDetails
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { WarpLogo, useAlert } from 'components';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';

import { BillingInfoForm, Customer, CustomerData } from './Billing';

interface Props {
  isOpen: boolean;
  onAfterClose(): void;
  onClose(): void;
}

export default function EditBillingDetailsModal(props: Props) {
  const { isOpen, onAfterClose, onClose } = props;

  const warpBilling = useGetWarpBillingDetails();
  const updateWarpBilling = useUpdateWarpBillingDetails();
  const [alert, setAlert] = useAlert();

  const onSubmit = async (data: CustomerData) => {
    try {
      await updateWarpBilling.mutateAsync({
        ...data,
        company: data.company_name,
        email: data.billing_email,
        billing_address: {
          line1: data.address_1,
          line2: data.address_2,
          city: data.city,
          state: data.state ?? '',
          zip: data.postal_code,
          country: data.country
        }
      });
      setAlert({
        kind: 'success',
        title: 'Success',
        message: 'Successfully updated billing details.'
      });
    } catch (err) {
      const error = parseError(err);
      setAlert({
        kind: 'error',
        title: 'Error',
        message: error.error
      });
    }
  };

  return (
    <LegacyModal
      type="brand"
      padding={true}
      size="xlarge"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      title="Update Billing Details"
      description={
        <>
          <WarpLogo />
          <p>Update your Warp billing details.</p>
        </>
      }
    >
      {alert}
      <BillingInfoSection>
        <Loading loading={warpBilling.isLoading}>
          {() => (
            <BillingInfoForm<CustomerData>
              id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
              onSubmit={onSubmit}
            >
              <Customer
                customer={{
                  ...warpBilling.data,
                  email: warpBilling.data?.email,
                  billing_address: {
                    line1: warpBilling.data?.billing_address.line1 ?? '',
                    line2: warpBilling.data?.billing_address.line2,
                    city: warpBilling.data?.billing_address.city ?? '',
                    state: warpBilling.data?.billing_address.state ?? '',
                    zip: warpBilling.data?.billing_address.zip,
                    country: warpBilling.data?.billing_address.country ?? ''
                  }
                }}
                disabled={updateWarpBilling.isLoading}
                validateName={(_, formValues) => {
                  return (
                    Boolean(formValues.company_name) ||
                    (Boolean(formValues.first_name) &&
                      Boolean(formValues.last_name)) ||
                    'Require company name OR first AND last name.'
                  );
                }}
                requireCompanyName={false}
              />
              <LegacyModal.Actions>
                <Button
                  type="submit"
                  id="submit_update_customer"
                  form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                  loading={updateWarpBilling.isLoading}
                  level="primary"
                >
                  Update Account Info
                </Button>
                <Button onClick={onClose} level="secondary">
                  Cancel
                </Button>
              </LegacyModal.Actions>
            </BillingInfoForm>
          )}
        </Loading>
      </BillingInfoSection>
    </LegacyModal>
  );
}

export const FormWrapper = styled('div', {
  backgroundColor: '$carkol',
  borderRadius: '$medium',
  display: 'grid',
  alignItems: 'center',
  minHeight: '10rem',
  variants: {
    padding: {
      true: {
        padding: '$xxlarge'
      }
    }
  }
});

export const Subtitle = styled('h4', {
  fontSize: '$header',
  lineHeight: '$header',
  textTransform: 'uppercase'
});

export const Actions = styled('div', {
  marginTop: '1rem',
  marginBottom: '1rem',
  height: '4rem',
  stack: {
    gap: '$xxlarge',
    hdistribute: 'start',
    valign: 'center'
  }
});

export const Section = styled('section', {
  stack: {
    direction: 'vertical',
    gap: '$xlarge'
  },
  '&:not(:first-of-type)': {
    marginTop: '4.8rem',
    paddingTop: '4.8rem',
    boxShadow: 'inset 0 0.1rem 0 $colors$pancham'
  },
  variants: {
    border: {
      true: {
        paddingTop: '4.8rem',
        boxShadow: 'inset 0 0.1rem 0 $colors$pancham'
      },
      false: {}
    },
    borderless: {
      true: {
        paddingTop: '0 !important',
        boxShadow: 'none !important'
      },
      false: {}
    }
  }
});

const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});
