import { ReactNode } from 'react';

//components
import { styled } from '@parsec/components';

import { WarpLogo } from 'components';

//assets
import successHero from 'lib/images/plan/success.svg';

interface PurchaseSuccessProps {
  headline?: string;
  description: ReactNode;
}

export default function PurchaseSuccess(props: PurchaseSuccessProps) {
  const { headline, description } = props;
  return (
    <PurchaseSuccessWrapper data-testid="success">
      <SuccessHero />
      <WarpLogo />
      {headline && <h3>{headline}</h3>}
      {description}
    </PurchaseSuccessWrapper>
  );
}

const SuccessHero = styled(successHero, {
  marginTop: '7.2rem',
  marginBottom: '$xxxlarge'
});

const PurchaseSuccessWrapper = styled('div', {
  backgroundColor: '$brand500',
  padding: '$xxlarge',
  display: 'grid',
  gap: '1rem',

  '&::selection': {
    background: '$perfectGray'
  }
});
