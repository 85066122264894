// libraries
import { useMemo, ReactNode } from 'react';

// @parsec
import AmplitudeClient from '@parsec/analytics/src/utils/AmplitudeClient';

import { useExperimentContext } from '@parsec/amplitude';
import {
  styled,
  global,
  Icon,
  Navigation,
  NavigationItem
} from '@parsec/components';
import { useCookie } from '@parsec/cookie';
import { useLogOut } from '@parsec/queries';

import { MARKETING_URL } from 'lib/config';
import { clearStickyState } from 'lib/util/storageAvailable';

import Footer from './Footer';

interface Props {
  children: ReactNode;
  color?: 'light' | 'dark';
}

export default function JoinLayout(props: Props) {
  const { children, color = 'dark' } = props;

  if (color === 'light') lightColors();
  else darkColors();

  const text = color === 'light' ? 'dark' : 'light';

  const logOut = useLogOut(),
    logOutMutate = logOut.mutate;

  const { token } = useCookie();

  const { experiment } = useExperimentContext();

  const logInNavItem = useMemo<NavigationItem>(() => {
    const handleLogout = () => {
      clearStickyState(`isInSignupFlow`);
      AmplitudeClient().reset();
      experiment.clear();
      logOutMutate();
    };

    return token
      ? { text: 'Log Out', onClick: handleLogout }
      : { text: 'Log In', href: '/login' };
  }, [token, experiment, logOutMutate]);

  const items = useMemo<NavigationItem[]>(
    () => [
      productNavItem,
      pricingNavItem,
      solutionsNavItem,
      companyNavItem,
      logInNavItem,
      downloadNavItem
    ],
    [logInNavItem]
  );

  return (
    <Wrapper>
      <Navigation items={items} color={text} />
      {children}
      <Reassure>
        <Icon name="shield" />
        <p>
          None of your private information will be shared with anyone else. Read
          our <a href={`${MARKETING_URL}/privacy`}>privacy policy</a> for more
          info.
        </p>
      </Reassure>
      <Footer color={text} />
    </Wrapper>
  );
}

const productNavItem: NavigationItem = {
  text: 'Product',
  children: [
    {
      title: 'Parsec For',
      items: [
        { text: 'Work', href: '/teams' },
        { text: 'Gaming', href: '/features' },
        { text: 'Pros', href: '/warp' },
        { text: 'Integrations', href: '/licensing' }
      ]
    }
  ]
};

const pricingNavItem: NavigationItem = { text: 'Pricing', href: '/pricing' };

const solutionsNavItem: NavigationItem = {
  text: 'Solutions',
  anchorRight: true,
  children: [
    {
      title: 'Games',
      items: [
        {
          text: 'Game Development',
          href: 'https://parsec.app/solutions/game-development'
        },
        {
          text: 'User Research & Testing',
          href: 'https://parsec.app/solutions/user-research-playtesting-quality-assurance'
        },
        {
          text: 'Demos & Events',
          href: 'https://parsec.app/solutions/demos-press-events'
        }
      ]
    },
    {
      title: 'AEC',
      items: [
        {
          text: 'Architecture',
          href: 'https://parsec.app/solutions/architecture-construction-industrial-design'
        },
        {
          text: 'Engineering',
          href: 'https://parsec.app/solutions/engineering-software-development'
        }
      ]
    },
    {
      title: 'Media & Entertainment',
      items: [
        {
          text: 'Production & Post-Production',
          href: 'https://parsec.app/solutions/production-post-production'
        },
        {
          text: 'VFX & Editing',
          href: 'https://parsec.app/solutions/vfx-editing'
        },
        { text: 'Broadcast', href: 'https://parsec.app/solutions/broadcast' },
        { text: 'Animation', href: 'https://parsec.app/solutions/animation' }
      ]
    }
  ]
};

const companyNavItem: NavigationItem = {
  text: 'Company',
  children: [
    {
      items: [
        { text: 'About', href: '/about' },
        { text: 'Careers', href: '/careers' }
      ]
    }
  ]
};

const downloadNavItem: NavigationItem = {
  text: 'Download',
  href: `${MARKETING_URL}/downloads`,
  style: 'button'
};

const lightColors = global({
  body: {
    color: '$computerBlack',
    backgroundColor: '$superLight'
  }
});

const darkColors = global({
  body: {
    color: '$rhyhorn',
    backgroundColor: '$computerBlack'
  }
});

const Wrapper = styled('div', {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '$xlarge $xxxlarge $xxxlarge'
});

const Reassure = styled('div', {
  marginTop: '$xxlarge',
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  gridAutoFlow: 'column',
  columnGap: '$medium',
  fontSize: '$info',
  lineHeight: '$info'
});
