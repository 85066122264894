/**
 * TODO
 * refactor to use react-hook-form
 * https://jira.unity3d.com/browse/PARSEC-1968
 */

import { Bread, Input, styled, Time } from '@parsec/components';
import { CreateTeamReq } from '@parsec/kessel';

import { Form } from 'components';
import {
  BILLING_SUMMARY_FORM_IDS,
  type BillingSummaryId
} from 'lib/constants/formIds';
import { getSeatUnitPrice } from 'lib/util/payment';
import { INTERVAL_MONTHLY, INTERVAL_YEARLY } from 'lib/util/subscription';

interface Props {
  onSubmit(): void;
  req: {
    seats: number;
    teamName: string;
    interval: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY;
    // subtotal is the price before any discounts and tax
    // i.e. seats * unit price
    subtotal: number;
    discount: number;
    tax: number;
    // total is price after tax & discounts
    // i.e. subtotal - discount + tax
    total: number;
    type: 'active' | 'trial';
    coupon?: string;
    trialEnd: Date;
  };
  updateReq(args: Partial<CreateTeamReq>): void;
  error: string;
  formId?: BillingSummaryId;
}

export default function Confirmation(props: Props) {
  const {
    onSubmit,
    req,
    error,
    updateReq,
    formId = BILLING_SUMMARY_FORM_IDS.DEFAULT_ID
  } = props;

  const { seats, interval, subtotal, tax, type, discount, total, trialEnd } =
    req;

  const unitPrice = getSeatUnitPrice(interval);
  const trial = type === 'trial';

  const hasCoupon = Boolean(req.coupon);
  const hasDiscount = discount > 0;
  const preTax = subtotal - discount;

  return (
    <Form
      title="You're almost done!"
      onSubmit={onSubmit}
      id={formId}
      width="xlarge"
      alignTitle="center"
      css={{ width: 'auto', marginTop: '$xlarge' }}
    >
      <p>
        Thanks for sticking with us so far. You’re right at the finish line.
        Let’s review your selection before you begin{trial && ' your trial'}.
      </p>

      <Subscription>Parsec for Teams ({interval}ly)</Subscription>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <CheckoutLine>
        <Seats>
          <Input
            name="seats"
            type="number"
            defaultValue={seats ?? 5}
            min={5}
            onChange={e => {
              updateReq({ standard_seats: parseInt(e.target.value) });
            }}
            required
            css={{ width: '5.2rem' }}
          />
          Seats
        </Seats>
        <Value css={{ gridColumnStart: 3 }}>
          <Bread decimal={0}>{unitPrice}</Bread>
        </Value>
        <Value css={{ gridColumnStart: 4 }}>
          {hasDiscount && (
            <DiscountedCss>
              <Bread>{subtotal}</Bread>
            </DiscountedCss>
          )}
          <Bread>{preTax}</Bread>
        </Value>
      </CheckoutLine>
      <CheckoutLine>
        Tax
        <Value css={{ gridColumnStart: 4 }}>
          <Bread>{tax}</Bread>
        </Value>
      </CheckoutLine>
      {hasCoupon && (
        <SummaryItem border>
          <Bold>Coupon Code</Bold>
          <CouponCode>{req.coupon}</CouponCode>
        </SummaryItem>
      )}
      <SummaryItem border={!hasCoupon}>
        <strong>Grand Total</strong>
        {trial ? (
          <SubText>
            {interval} total (billed starting{' '}
            <Bold>
              <Time date={trialEnd} />
            </Bold>
            )
          </SubText>
        ) : (
          <Value strong css={{ gridColumnStart: 3 }}>
            billed today
          </Value>
        )}
        <Value css={{ gridColumnStart: 4 }} strong={!trial}>
          <Bread>{total}</Bread>
        </Value>
      </SummaryItem>
      {trial && (
        <SummaryItem>
          <Value strong css={{ gridColumnStart: 3 }}>
            billed today
          </Value>
          <Total>
            <Bread>{0}</Bread>
          </Total>
        </SummaryItem>
      )}
      {trial && (
        <Disclaimer>
          You will be charged when your trial converts to a paid subscription
        </Disclaimer>
      )}
    </Form>
  );
}

const Seats = styled('span', {
  display: 'grid',
  gridAutoFlow: 'column',
  columnGap: '1rem',
  alignItems: 'center'
});

const ErrorMessage = styled('p', {
  marginTop: '$medium',
  color: '$error500'
});

const CheckoutLine = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'max-content auto 4rem 10rem',
  alignItems: 'center',
  justifyContent: 'unset',
  columnGap: '$xxxlarge'
});

const SubText = styled('span', {
  color: '$rhyhorn',
  gridColumnStart: 3,
  textAlign: 'right',
  display: 'none',
  '@medium': {
    display: 'block'
  }
});

const SummaryItem = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'max-content auto max-content 7rem',
  alignItems: 'center',
  justifyContent: 'unset',
  columnGap: '$xxxlarge',
  marginBottom: '$xlarge',

  variants: {
    border: {
      true: {
        boxShadow: 'inset 0 0.1rem 0 $colors$pancham',
        paddingTop: '$xxlarge'
      }
    }
  }
});

const Total = styled('strong', {
  fontSize: '$subtitle',
  gridColumnStart: 4,
  textAlign: 'right'
});

const Subscription = styled('strong', {
  fontSize: '$attribution',
  textTransform: 'capitalize',
  marginTop: '$xxxxlarge'
});

const Disclaimer = styled('span', {
  color: '#FFAD11',
  marginBottom: '$xlarge',
  boxShadow: 'inset 0 0.1rem 0 $colors$pancham',
  paddingTop: '$large'
});

const Value = styled('span', {
  textAlign: 'right',
  variants: {
    strong: {
      true: {
        fontWeight: 'bold'
      }
    }
  }
});

const Bold = styled('span', { fontWeight: '$bold' });

const CouponCode = styled('p', {
  color: '$success500',
  gridColumnStart: '3'
});

const DiscountedCss = styled('span', {
  textDecoration: 'line-through',
  color: `#f9fafc26`, //no token
  marginRight: '$small'
});
