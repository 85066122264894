import { useEffect } from 'react';

import Head from 'next/head';
import Image from 'next/image';
import { useForm } from 'react-hook-form';

import { pushDlSignUp } from '@parsec/analytics';
import { Button, ErrorMessage, styled } from '@parsec/components';
import { useAddTeamMember } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { Actions, Wrapper, SignUpDisclaimer } from 'components';
import { useSignup } from 'lib/hooks';

import signupHero from 'lib/images/signup@2x.png';

const ContentWrapper = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  rowGap: '3.6rem',
  maxWidth: '38rem'
});

const Title = styled('h1', {
  fontSize: '$title',
  lineHeight: '$title'
});

const StyledForm = styled('form', {
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',
  '@large': {
    width: '38rem'
  }
});

// COMPONENT
export default function JoinTeam() {
  const {
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid }
  } = useForm();

  const { send, data } = useSignup();

  const { email, teamName, teamId, hash, teamData } = data;

  const addTeamMember = useAddTeamMember();

  const onSubmit = handleSubmit(async () => {
    try {
      pushDlSignUp({ step: '1.9.2', isTeam: true, ...teamData }); // join-team submit

      await addTeamMember.mutateAsync({
        team_id: teamId,
        token: hash
      });

      send({
        type: 'JOIN_TEAM'
      });
    } catch {
      // noop, error captured on mutation result;
      return;
    }
  });

  useEffect(() => {
    pushDlSignUp({ step: '1.9.1', isTeam: true, ...teamData }); // join-team view

    if (addTeamMember.error) {
      const error = parseError(addTeamMember.error, {
        error: 'Could not add to team.'
      });

      setError('api_error', {
        type: 'api',
        message: error.error
      });
    }
  }, [addTeamMember.error, teamData, setError]);

  const title = `Join the ${teamName} Team on Parsec`;

  return (
    <Wrapper layout="spread" spacer="top">
      <Head>
        <title>{`${title} | Parsec`}</title>
        <meta name="robots" key="robots" content="noindex, nofollow" />
      </Head>
      <ContentWrapper>
        <Image src={signupHero.src} alt="" width={250} height={125} />

        <Title>{title}</Title>

        <StyledForm onSubmit={onSubmit}>
          <p>
            Your account <strong>{email}</strong> has been invited to join the
            team <strong>{teamName}</strong>. Click the button below to confirm.
          </p>

          {errors.api_error ? (
            <ErrorMessage>{errors.api_error.message?.toString()}</ErrorMessage>
          ) : null}

          <Actions>
            <Button disabled={isSubmitting || !isValid} wide>
              Join Team
            </Button>
          </Actions>
        </StyledForm>
      </ContentWrapper>
      <SignUpDisclaimer />
    </Wrapper>
  );
}
