import { useCallback, useEffect, useState } from 'react';

import Identify from '@parsec/analytics/src/utils/Identify';

import {
  Button,
  LegacyModal,
  BillingCardPreview as BaseBillingCardPreview,
  styled
} from '@parsec/components';
import { ERRORS } from '@parsec/constants';
import { WarpSubscription, WarpPlanID, Card } from '@parsec/kessel';
import { useGetWarpBillingCard } from '@parsec/queries';

import { WarpLogo } from 'components';
import {
  BillingInfoForm,
  NewCard as BaseNewCard,
  NewCardData,
  CouponData,
  Coupon
} from 'components/Billing';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';
import { useUpdateModalState } from 'lib/hooks';

import { UPDATE_HEADER_COPY, title } from '../copy-constants';
import PurchaseError from '../PurchaseError';
import PurchaseSuccess from '../PurchaseSuccess';
import { Wrapper, Description, HeadBack } from '../shared-components';
import ConfirmationForm from '../WarpConfirmPurchase';
import WarpPricing from '../WarpPricing';

// Utils

interface Props {
  isOpen: boolean;
  subscription?: WarpSubscription;
  onClose(): void;
  subscriptionStep?: string;
  onAfterClose?(): void;
}

type BillingInfoInput = NewCardData & CouponData;

export default function UpdateModal(props: Props) {
  const { isOpen, onClose, subscription, onAfterClose = () => {} } = props;

  const {
    subtotal,
    estimate,
    error,
    step,
    req,
    currentPlanId,
    loading,
    onUpdateSubscription,
    setShouldUpdateCard,
    updateReq,
    setError,
    setStep
  } = useUpdateModalState({ subscription });

  const isTrial = subscription?.status === 'in_trial';

  const onCloseHandler = useCallback(() => {
    onClose();
    updateReq({ coupon: '' });
  }, [onClose, updateReq]);

  const card = useGetWarpBillingCard();
  const hasCard = Boolean(card.data);

  const [isEditing, setIsEditing] = useState(true);
  useEffect(() => {
    setIsEditing(!hasCard);
  }, [hasCard]);

  const hasCoupon = Boolean(req.coupon);
  const couponIsValid = hasCoupon && Boolean(estimate.data);

  const couponError =
    step === 'billing' &&
    hasCoupon &&
    estimate.error?.error === ERRORS.COUPON_ERROR
      ? ERRORS.COUPON_ERROR
      : '';

  const renderSteps = (): JSX.Element | null => {
    switch (step) {
      case 'pricing':
        return (
          <>
            <WarpPricing
              currentPlanId={currentPlanId}
              selectedPlanId={req.plan_id as WarpPlanID}
              subtotal={subtotal}
              updateReq={updateReq}
            />
            <LegacyModal.Actions>
              <Button onClick={() => setStep('billing')}>Next</Button>
              <Button level="secondary" type="button" onClick={onClose}>
                Cancel
              </Button>
            </LegacyModal.Actions>
          </>
        );
      case 'billing':
        return (
          <BillingInfoSection>
            <BillingInfoForm<BillingInfoInput>
              id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
              onSubmit={data => {
                updateReq({
                  coupon: data.coupon,
                  token: data.token
                });
                setShouldUpdateCard(Boolean(data.token));
                setStep('confirmation');
              }}
            >
              {card.data && !isEditing ? (
                <ExistingCard
                  card={card.data}
                  onEditClick={() => setIsEditing(true)}
                />
              ) : (
                <NewCard onClick={() => setIsEditing(false)} />
              )}
              <Coupon
                defaultValue={req.coupon}
                apply={coupon => updateReq({ coupon })}
                isValid={couponIsValid}
                errorMsg={couponError}
              />
              <LegacyModal.Actions>
                <Button
                  type="submit"
                  kind="success"
                  id="submit_update_warp"
                  form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                >
                  Review Purchase
                </Button>
                <Button
                  level="secondary"
                  type="button"
                  onClick={() => setStep('pricing')}
                  aria-label="Back to Pricing"
                >
                  Back
                </Button>
              </LegacyModal.Actions>
            </BillingInfoForm>
          </BillingInfoSection>
        );
      case 'confirmation':
        return (
          <>
            <ConfirmationForm
              selectedPlanId={req.plan_id}
              subscription={subscription}
              estimate={estimate.data}
              onConfirm={onUpdateSubscription}
              onSubscribe={() => {
                setStep('success');
                Identify({ warpSubscription: req.plan_id });
              }}
              onError={setError}
              coupon={Boolean(req.coupon) ? req.coupon : subscription?.coupon}
            />
            <LegacyModal.Actions>
              <Button
                loading={loading}
                kind="primary"
                form="subscription_confirmation_form"
              >
                Confirm and Submit
              </Button>
              <Button
                level="secondary"
                onClick={() => setStep('billing')}
                aria-label="Back to Billing"
              >
                Back
              </Button>
            </LegacyModal.Actions>
          </>
        );
      case 'success':
        return (
          <>
            <PurchaseSuccess
              headline={
                !isTrial
                  ? "Feel that? That's the feeling of an even better Parsec."
                  : undefined
              }
              description={
                isTrial ? (
                  <>
                    <p>
                      You have successfully updated your trial subscription
                      type.
                    </p>
                    <p>We hope you continue to enjoy your 14 day Warp Trial!</p>
                  </>
                ) : (
                  <>
                    <p>
                      Thanks for upgrading to an annual subscription. Your
                      subscription lets us continue making Parsec awesome.
                    </p>
                    <p>
                      Now enough mushy stuff &mdash; go enjoy your upgraded
                      perks.
                    </p>
                  </>
                )
              }
            />
            <LegacyModal.Actions>
              <HeadBack onClick={onClose}>Head Back to Parsec</HeadBack>
            </LegacyModal.Actions>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <LegacyModal
      type="brand"
      padding={false}
      size="xlarge"
      isOpen={isOpen}
      onClose={onCloseHandler}
      onAfterClose={onAfterClose}
      title={
        error
          ? 'Uh oh!'
          : step === 'success'
            ? undefined
            : UPDATE_HEADER_COPY['title'][step as keyof title]
      }
      description={
        step === 'success' ? undefined : (
          <>
            <WarpLogo />
            <Description>{UPDATE_HEADER_COPY.description}</Description>
          </>
        )
      }
    >
      <Wrapper>
        {error ? (
          <PurchaseError
            error={error}
            onStartOver={() => {
              setError('');
              setStep('pricing');
            }}
          />
        ) : (
          renderSteps()
        )}
      </Wrapper>
    </LegacyModal>
  );
}

function ExistingCard(props: { card: Card; onEditClick?(): void }) {
  const { card, onEditClick } = props;
  return (
    <ExistingCardSection>
      <StyledP>Payment Method</StyledP>
      <EditButton onClick={onEditClick}>Edit</EditButton>
      <BillingCardPreview card={card} />
    </ExistingCardSection>
  );
}

function NewCard(props: { onClick(): void }) {
  const { onClick } = props;
  return (
    <>
      <CancelButton onClick={onClick}>Cancel</CancelButton>
      <BaseNewCard />
    </>
  );
}

const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});

const ExistingCardSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 10rem',
  gridTemplateRows: '$space$xxlarge auto',
  paddingBottom: '$xxxlarge'
});

const BillingCardPreview = styled(BaseBillingCardPreview, {
  gridColumnStart: 1,
  gridColumnEnd: 3
});

const StyledP = styled('p', {
  fontWeight: '$bold',
  fontSize: '$body'
});

const EditButton = styled('button', {
  background: 'transparent',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold',
  color: '$primary500',
  padding: '0',
  justifySelf: 'right',
  alignSelf: 'center',
  cursor: 'pointer'
});

const CancelButton = styled(EditButton, {
  padding: '0',
  position: 'absolute',
  right: '$xlarge'
});
