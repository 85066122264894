import type { Item } from './types';

// libraries
import { useCallback, useState } from 'react';

// @parsec
import { styled } from '@parsec/styles';

import Icon from '../Icon';
import { withClientOnly } from '../withClientOnly';

import LogoSvg from './logo.svg';
import SubMenu from './SubMenu';

export type NavigationItem = Item;

export interface NavigationProps {
  className?: string;
  items: Item[];
  color?: 'dark' | 'light';
}

const Navigation = (props: NavigationProps) => {
  const { className, items, color = 'light' } = props;

  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);

  const [openChild, setOpenChild] = useState(-1);

  return (
    <Wrapper className={className} color={color}>
      <LogoLink href="/">
        <Logo />
      </LogoLink>
      <Bun onClick={handleOpen}>
        <Icon name="menu" title="Menu" />
      </Bun>
      <Nav open={open}>
        <CloseLi>
          <CloseButton onClick={handleClose}>
            <Icon name="ex" title="Close" />
          </CloseButton>
        </CloseLi>
        {items.map((item, i) => (
          <NavItem key={item.text}>
            {'children' in item ? (
              <SubMenu
                groups={item.children}
                open={openChild === i}
                anchorRight={item.anchorRight}
                onToggle={open => setOpenChild(open ? i : -1)}
              >
                <NavLink as="span">{item.text}</NavLink>
              </SubMenu>
            ) : 'href' in item ? (
              <NavLink style={item.style} href={item.href}>
                {item.text}
              </NavLink>
            ) : (
              <NavLink
                as="button"
                style={item.style}
                color={color}
                onClick={item.onClick}
              >
                {item.text}
              </NavLink>
            )}
          </NavItem>
        ))}
      </Nav>
    </Wrapper>
  );
};

export default withClientOnly(Navigation);

const Wrapper = styled('nav', {
  height: '7rem',
  padding: '$xxlarge 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@large': {
    padding: 0
  },
  variants: {
    color: {
      dark: { color: '$computerBlack' },
      light: { color: '$consoleWhite' }
    }
  }
});

const LogoLink = styled('a', {
  padding: '$xxlarge 0',
  color: 'inherit'
});

const Logo = styled(LogoSvg, {
  display: 'block',
  height: '3rem',
  color: 'inherit'
});

const Nav = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  transform: 'translateX(0)',
  position: 'fixed',
  left: '100%',
  right: 0,
  top: 0,
  width: '42rem',
  maxWidth: '80vw',
  height: '100vh',
  padding: '3rem',
  backgroundColor: '#ffffff',
  overflow: 'auto',
  transition: 'transform 500ms ease-in-out',
  color: '$computerBlack',
  zIndex: 1000,

  '@large': {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: '3.6rem',
    alignItems: 'center',
    left: 'initial',
    overflow: 'visible',
    transform: 'none',
    position: 'relative',
    width: 'auto',
    height: 'inherit',
    padding: 0,
    color: 'inherit',
    backgroundColor: 'transparent'
  },

  variants: {
    open: {
      true: {
        transform: 'translateX(-100%)',
        '@large': { transform: 'none' }
      }
    }
  }
});

const NavItem = styled('li', {
  display: 'flex',
  alignItems: 'center'
});

const Bun = styled('button', {
  display: 'block',
  cursor: 'pointer',
  color: 'inherit',
  '@large': { display: 'none' }
});

const CloseLi = styled('li', {
  position: 'absolute',
  top: '3rem',
  right: '3rem',
  '@large': { display: 'none' }
});

const CloseButton = styled('button', {
  display: 'block',
  cursor: 'pointer'
});

const NavLink = styled('a', {
  display: 'inline-block',
  whiteSpace: 'nowrap',
  fontFamily: '$heading',
  fontSize: '$attribution',
  fontWeight: '$normal',
  padding: '$xxlarge 0',
  color: '$computerBlack',
  cursor: 'pointer',
  '@large': {
    padding: '$xxxlarge 0',
    color: 'inherit'
  },
  variants: {
    style: {
      link: {},
      button: {
        margin: '$xxlarge 0',
        padding: '1.4rem 4rem',
        borderRadius: '2.6rem',
        border: '0.2rem solid currentColor',
        '@large': { margin: 0 }
      }
    }
  }
});
