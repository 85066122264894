import { useCallback, useState } from 'react';

//components
import Identify from '@parsec/analytics/src/utils/Identify';

import {
  Button,
  LegacyModal,
  BillingCardPreview as BaseBillingCardPreview,
  styled
} from '@parsec/components';
import { WarpPlanID, WarpSubscription, Card } from '@parsec/kessel';
import { useGetWarpBillingCard } from '@parsec/queries';

import { WarpLogo } from 'components';
import {
  BillingInfoForm,
  NewCard as BaseNewCard,
  NewCardData
} from 'components/Billing';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';
import { useResumeModalState } from 'lib/hooks';

import PurchaseError from '../PurchaseError';
import PurchaseSuccess from '../PurchaseSuccess';
import { Wrapper, HeadBack, Description } from '../shared-components';
import ConfirmationForm from '../WarpConfirmPurchase';
import WarpPricing from '../WarpPricing';

//kessel

//hooks

interface ResumeModalProps {
  isOpen: boolean;
  subscription?: WarpSubscription;
  onClose(): void;
  onAfterClose?(): void;
}

const successMessage = {
  resume: {
    title: "Feel that? That's the feeling of an even better Parsec.",
    description: (
      <>
        <p>
          Thanks for resuming your Warp subscription. Your subscription lets us
          continue making Parsec awesome.
        </p>
        <p> Now enough mushy stuff — go enjoy all the perks.</p>
      </>
    )
  },
  resubscribe: {
    title: "Welcome back! That's the feeling of an even better Parsec.",
    description: (
      <>
        <p>
          We&apos;re happy to have you back! Your subscription lets us continue
          making Parsec awesome.
        </p>
        <p>Now enough mushy stuff — go enjoy all the perks.</p>
      </>
    )
  }
};

export default function ResumeModal(props: ResumeModalProps) {
  const { isOpen, onClose, subscription, onAfterClose = () => {} } = props;

  const isResubscribe = subscription?.status === 'cancelled';

  const {
    error,
    req,
    subtotal,
    loading,
    step,
    estimate,
    setStep,
    setError,
    updateReq,
    handleResume,
    setShouldUpdateCard
  } = useResumeModalState({
    subscription
  });

  const onCloseHandler = useCallback(() => {
    onClose();
    updateReq({ coupon: '' });
  }, [onClose, updateReq]);

  const card = useGetWarpBillingCard();
  const hasCard = Boolean(card.data);

  const [isEditing, setIsEditing] = useState(!hasCard);

  const title = ((): string | undefined => {
    if (error) {
      return 'Uh oh!';
    } else if (step === 'success') {
      return undefined;
    } else if (isResubscribe) {
      return 'Resubscribe to Warp';
    } else {
      return 'Resume your subscription';
    }
  })();

  const renderSteps = (): JSX.Element | null => {
    switch (step) {
      case 'pricing':
        return (
          <>
            <WarpPricing
              selectedPlanId={req.plan_id as WarpPlanID}
              subtotal={subtotal}
              updateReq={updateReq}
            />
            <LegacyModal.Actions>
              <Button onClick={() => setStep('billing')}>Next</Button>
              <Button
                level="secondary"
                type="button"
                onClick={onClose}
                aria-label="Close Modal"
              >
                Cancel
              </Button>
            </LegacyModal.Actions>
          </>
        );
      case 'billing':
        return (
          <BillingInfoSection>
            <BillingInfoForm<NewCardData>
              id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
              onSubmit={data => {
                updateReq({
                  token: data.token
                });
                setShouldUpdateCard(Boolean(data.token));
                setStep('confirmation');
              }}
            >
              {card.data && !isEditing ? (
                <ExistingCard
                  card={card.data}
                  onEditClick={() => setIsEditing(true)}
                />
              ) : (
                <NewCard onCancelClick={() => setIsEditing(false)} />
              )}
              <LegacyModal.Actions>
                <Button
                  type="submit"
                  kind="success"
                  id="submit_resume_subscription"
                  form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                >
                  Review Purchase
                </Button>
                <Button
                  level="secondary"
                  type="button"
                  aria-label={isResubscribe ? 'Back to Pricing' : 'Close Modal'}
                  onClick={isResubscribe ? () => setStep('pricing') : onClose}
                >
                  {isResubscribe ? 'Back' : 'Close'}
                </Button>
              </LegacyModal.Actions>
            </BillingInfoForm>
          </BillingInfoSection>
        );
      case 'confirmation':
        return (
          <>
            <ConfirmationForm
              selectedPlanId={req.plan_id}
              subscription={subscription}
              estimate={estimate.data}
              onConfirm={handleResume}
              onSubscribe={() => {
                setStep('success');
                Identify({ warpSubscription: req.plan_id });
              }}
              onError={setError}
              isResume={!isResubscribe}
              coupon={subscription?.coupon}
            />
            <LegacyModal.Actions>
              <Button
                loading={loading}
                kind="primary"
                form="subscription_confirmation_form"
              >
                Confirm and Submit
              </Button>
              <Button
                level="secondary"
                aria-label="Back to Billing"
                onClick={() => {
                  setStep('billing');
                }}
              >
                Back
              </Button>
            </LegacyModal.Actions>
          </>
        );
      case 'success':
        return (
          <>
            <PurchaseSuccess
              headline={
                isResubscribe
                  ? successMessage.resubscribe.title
                  : successMessage.resume.title
              }
              description={
                isResubscribe
                  ? successMessage.resubscribe.description
                  : successMessage.resume.description
              }
            />
            <LegacyModal.Actions>
              <HeadBack onClick={onClose}>Head Back to Parsec</HeadBack>
            </LegacyModal.Actions>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <LegacyModal
      type="brand"
      padding={false}
      size="xlarge"
      isOpen={isOpen}
      onClose={onCloseHandler}
      onAfterClose={onAfterClose}
      title={title}
      description={
        step === 'success' ? undefined : (
          <>
            <WarpLogo />
            <Description>
              Enhance the ways you game, work and whatever else.
            </Description>
          </>
        )
      }
    >
      <Wrapper>
        {error ? (
          <PurchaseError
            error={error}
            onStartOver={() => {
              setError('');
              setStep(isResubscribe ? 'pricing' : 'billing');
            }}
          />
        ) : (
          renderSteps()
        )}
      </Wrapper>
    </LegacyModal>
  );
}

function ExistingCard(props: { card: Card; onEditClick?(): void }) {
  const { card, onEditClick } = props;
  return (
    <ExistingCardSection>
      <StyledP>Payment Method</StyledP>
      <EditButton onClick={onEditClick}>Edit</EditButton>
      <BillingCardPreview card={card} />
    </ExistingCardSection>
  );
}

function NewCard(props: { onCancelClick(): void }) {
  const { onCancelClick } = props;
  return (
    <>
      <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
      <BaseNewCard />
    </>
  );
}

const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});

const ExistingCardSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 10rem',
  gridTemplateRows: '$space$xxlarge auto',
  paddingBottom: '$xxxlarge'
});

const BillingCardPreview = styled(BaseBillingCardPreview, {
  gridColumnStart: 1,
  gridColumnEnd: 3
});

const StyledP = styled('p', {
  fontWeight: '$bold',
  fontSize: '$body'
});

const EditButton = styled('button', {
  background: 'transparent',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold',
  color: '$primary500',
  padding: '0',
  justifySelf: 'right',
  alignSelf: 'center',
  cursor: 'pointer'
});

const CancelButton = styled(EditButton, {
  padding: '0',
  position: 'absolute',
  right: '$xlarge'
});
