import {
  forwardRef,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  Ref
} from 'react';

import { CSS, styled } from '@parsec/styles';

type HTMLTextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type TextAreaProps = HTMLTextAreaProps & {
  ref?: Ref<HTMLTextAreaElement>;
  css?: CSS;
  alignText?: 'left' | 'right' | 'center';
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    const { className, alignText = 'left', css, ...rest } = props;

    return (
      <TextAreaWrapper className={className}>
        <StyledTextArea {...rest} alignText={alignText} ref={ref} css={css} />
      </TextAreaWrapper>
    );
  }
);

export default TextArea;

const TextAreaWrapper = styled('div', {
  position: 'relative'
});

const StyledTextArea = styled('textarea', {
  width: '100%',
  height: 'auto',
  backgroundColor: '$cereza',
  borderRadius: '$medium',
  transition: '125ms box-shadow ease',
  boxShadow: '0rem $space$xsmall 0 rgba(255, 255, 255, 0.1)',
  padding: '.6rem 1rem',
  color: '$consoleWhite',
  resize: 'none',
  '&::placeholder': {
    color: '$rhyhorn'
  },

  '&:disabled': {
    color: '$nice',
    backgroundColor: '$duskull',
    '&::placeholder': {
      color: '$nice'
    },
    '& + svg': {
      color: '$nice'
    }
  },

  '&:invalid': {
    boxShadow: 'inset 0 0 0 $xsmall $colors$error500'
  },

  '&:not(:disabled)': {
    '&:hover': {
      boxShadow:
        'inset 0 0 0 $space$xsmall $colors$pangoro, 0px $space$xsmall 0px rgba(255,255,255,0.1)'
    },
    '&:focus': {
      boxShadow: 'inset 0 0 0 .2rem $colors$primary500',
      color: '$consoleWhite',
      border: 'none',
      outlineStyle: 'none',
      '& ~ svg': {
        color: '$consoleWhite '
      }
    },

    '&::-moz-focus-inner': {
      border: 'none'
    }
  },

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },

  variants: {
    alignText: {
      left: {
        textAlign: 'left'
      },
      right: {
        textAlign: 'right'
      },
      center: {
        textAlign: 'center'
      }
    },
    iconPadding: {
      left: {
        paddingLeft: '3.6rem'
      },
      right: {
        paddingRight: '3.2rem'
      },
      none: {}
    }
  }
});
