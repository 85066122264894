// libraries
import { useMemo, ReactNode } from 'react';

// @parsec
import AmplitudeClient from '@parsec/analytics/src/utils/AmplitudeClient';

import { useExperimentContext } from '@parsec/amplitude';
import {
  styled,
  Loading,
  Navigation,
  NavigationItem,
  IncidentNotificationBanner as BaseIncidentBanner,
  useIncidentNotification
} from '@parsec/components';
import { useGetMe, useGetTeam, useLogOut } from '@parsec/queries';

import { APP_URL, MARKETING_URL } from 'lib/config';
import { clearStickyState } from 'lib/util/storageAvailable';

import Header from './Header';
import Tabs from './Tabs';

// STYLES
const Outside = styled('div', {
  minWidth: '42rem',
  maxWidth: '100rem',
  margin: '0 auto',
  padding: '$xxxlarge',
  paddingTop: '$large'
});

const IncidentBanner = styled(BaseIncidentBanner, {
  marginTop: '$xxlarge',
  marginBottom: '$large'
});

// COMPONENT
export default function AuthWrapper(props: { children: ReactNode }) {
  const me = useGetMe();
  const team = useGetTeam();

  //Incident notification
  const { incidentStatus, incidentText, incidentTitle, showIncidentBanner } =
    useIncidentNotification();

  const logOut = useLogOut(),
    logOutMutateAsync = logOut.mutateAsync;

  const { experiment } = useExperimentContext();

  const navItems = useMemo<NavigationItem[]>(() => {
    const handleLogout = async () => {
      clearStickyState(`isInSignupFlow`);
      AmplitudeClient().reset();
      experiment.clear();
      await logOutMutateAsync();
      window.location.assign(`/login`);
    };

    return [
      {
        text: 'Log Out',
        onClick: handleLogout
      },
      { text: 'Launch App', href: APP_URL },
      {
        text: 'Download',
        style: 'button',
        href: `${MARKETING_URL}/downloads`
      }
    ];
  }, [logOutMutateAsync, experiment]);

  return (
    <Outside>
      <Navigation items={navItems} />
      {/* // only show the incident banner in this dom order if there is no data (aka likely no banner siblings) */}
      {showIncidentBanner && !me.data ? (
        <IncidentBanner status={incidentStatus} text={incidentText} />
      ) : null}

      <Loading loading={!me.data}>
        {() =>
          me.data ? (
            <main>
              <Header user={me.data} teamName={team.data?.name}>
                {/* // otherwise show banner in header to ensure sibling css selectors work correctly */}
                {showIncidentBanner ? (
                  <IncidentBanner
                    status={incidentStatus}
                    text={incidentText}
                    title={incidentTitle}
                  />
                ) : null}
              </Header>
              <Tabs />
              {props.children}
            </main>
          ) : null
        }
      </Loading>
    </Outside>
  );
}
