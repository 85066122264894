//kessel
import { Button, LegacyModal, Time } from '@parsec/components';
import { WarpSubscription } from '@parsec/kessel';

//components
import { WarpLogo } from 'components';
import { useResumeTrialModalState } from 'lib/hooks';

import PurchaseError from '../PurchaseError';
import PurchaseSuccess from '../PurchaseSuccess';
import { Wrapper, Description, Section, HeadBack } from '../shared-components';

//hooks

interface ResumeTrialModalProps {
  isOpen: boolean;
  subscription?: WarpSubscription;
  onClose(): void;
  onAfterClose?(): void;
}

export default function ResumeTrialModal(props: ResumeTrialModalProps) {
  const { isOpen, onClose, subscription, onAfterClose = () => {} } = props;

  const { error, step, setStep, setError, handleResume } =
    useResumeTrialModalState();

  return (
    <LegacyModal
      type="brand"
      padding={false}
      size="xlarge"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      title={
        error ? 'Uh oh!' : step === 'success' ? undefined : `Resume your trial`
      }
      description={
        step === 'success' ? undefined : (
          <>
            <WarpLogo />
            <Description>
              Enhance the ways you game, work and whatever else.
            </Description>
          </>
        )
      }
    >
      <Wrapper>
        {error ? (
          <PurchaseError
            error={error}
            onStartOver={() => {
              setError('');
              setStep('trial');
            }}
          />
        ) : step === 'trial' ? (
          <Trial
            onSubmit={async () => {
              await handleResume();
              setStep('success');
            }}
            interval={
              subscription?.plan_id === 'warp-yearly' ? 'annual' : 'month'
            }
            onClose={onClose}
            trialEnd={subscription?.trial_end}
          />
        ) : (
          <>
            <PurchaseSuccess
              headline="Feel that? That's the feeling of an even better Parsec."
              description={
                <>
                  Thanks for resuming your Warp trial. Your sub lets us continue
                  making Parsec awesome.
                  <br />
                  Now enough mushy stuff — go enjoy all the perks.
                </>
              }
            />
            <LegacyModal.Actions>
              <HeadBack onClick={onClose}>Head Back to Parsec</HeadBack>
            </LegacyModal.Actions>
          </>
        )}
      </Wrapper>
    </LegacyModal>
  );
}

export function Trial(props: {
  onSubmit(): void;
  interval: 'annual' | 'month';
  onClose(): void;
  trialEnd?: string | null;
}) {
  const { onSubmit, interval, onClose, trialEnd } = props;

  const trialEndDate = trialEnd ? new Date(trialEnd) : undefined;

  return (
    <>
      <Section data-testid="verify">
        <p>
          By hitting resume, your trial will auto&ndash;renew. We will charge
          you once your trial period ends{' '}
          {trialEndDate && (
            <>
              (
              <strong>
                <Time date={trialEndDate} />
              </strong>
              )
            </>
          )}{' '}
          and we will keep charging you <strong>{interval}ly</strong> until you
          cancel the subscription. You can cancel at any time.
        </p>

        <p>
          If you would like to update your billing information or card on file
          you may do so from the dashboard.
        </p>
      </Section>
      <LegacyModal.Actions>
        <Button onClick={onSubmit}>Resume Trial</Button>
        <Button level="secondary" onClick={onClose}>
          Cancel
        </Button>
      </LegacyModal.Actions>
    </>
  );
}
