import type { ExperimentClient } from '@amplitude/experiment-js-client';

import { type ReactNode, createContext, useContext } from 'react';

const Context = createContext<
  | {
      experiment: ExperimentClient;
      loaded: boolean;
    }
  | undefined
>(undefined);

// Check if the value provided to the context is defined or throw an error
const useExperimentContext = () => {
  const contextIsDefined = useContext(Context);
  if (!contextIsDefined) {
    throw new Error(
      'useExperimentContext must be used within a <ExperimentProvider>'
    );
  }
  return contextIsDefined;
};

interface ExperimentProviderProps {
  children: ReactNode;
  experiment: ExperimentClient;
  loaded: boolean;
}

function ExperimentProvider({
  children,
  experiment,
  loaded
}: ExperimentProviderProps) {
  return (
    <Context.Provider value={{ experiment, loaded }}>
      {children}
    </Context.Provider>
  );
}

export { useExperimentContext, ExperimentProvider };
