import { ReactNode, useRef } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

const Wrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateColumns: '1.6rem 2fr 1fr 4rem 4.4rem 3.6rem',
  columnGap: '$xlarge',
  alignItems: 'center'
});

const RowIcon = styled(Icon, {
  width: '1.6rem',
  height: 'auto',
  color: '$rhyhorn',
  variants: {
    small: {
      true: {
        width: '1.2rem'
      }
    }
  }
});

const Name = styled('p', {
  fontWeight: '$bold',
  fontSize: '1.4rem',
  lineHeight: '$attribution',
  maxWidth: '21rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize'
});

const ConnectionText = styled('p', {
  fontWeight: '$bold',
  fontSize: '$info',
  lineHeight: '$info'
});

const ConnectionWrapper = styled('div', {
  display: 'flex',
  columnGap: '$medium',
  width: 'fit-content',
  color: '$consoleWhite',
  variants: {
    disabled: {
      true: {
        color: '$nice'
      }
    }
  }
});

const InfoWrapper = styled('div', {
  display: 'flex',
  columnGap: '$xlarge'
});

const SubInfoWrapper = styled('div', {
  display: 'flex',
  columnGap: '$small',
  alignItems: 'center',
  fontSize: '1rem',
  lineHeight: '$info',
  color: '$rhyhorn'
});

const tooltipStyle = {
  backgroundColor: '$chandelure',
  boxShadow: '$none $xsmall $small $none rgba(0, 0, 0, 0.2)'
};

const connectionTooltipStyle = {
  ...tooltipStyle,
  width: '19rem',
  fontSize: '1rem',
  lineHeight: '$info'
};

interface ManageGroupsRowProps {
  allowedGroupName: string;
  machinesCount: number;
  membersCount: number;
  isConnIncoming: boolean;
  isConnOutgoing: boolean;
  onRemove: () => void;
  currentGroupName: string;
  children: ReactNode;
}

export function ManageGroupsRow(props: ManageGroupsRowProps) {
  const {
    children,
    allowedGroupName,
    machinesCount,
    membersCount,
    isConnIncoming,
    isConnOutgoing,
    onRemove,
    currentGroupName
  } = props;
  const nameRef = useRef<HTMLParagraphElement>(null);
  return (
    <Wrapper>
      <RowIcon name="groupShapes" />
      <InfoWrapper>
        <Tooltip
          tooltipText={allowedGroupName}
          showOnOverflow={true}
          triggerRef={nameRef}
          wrapperStyle={tooltipStyle}
          version="newFont"
        >
          <Name ref={nameRef}>{allowedGroupName}</Name>
        </Tooltip>
        <SubInfoWrapper>
          <RowIcon small name="laptop" />
          <p>{machinesCount}</p>
        </SubInfoWrapper>
        <SubInfoWrapper>
          <RowIcon small name="member" />
          <p>{membersCount}</p>
        </SubInfoWrapper>
      </InfoWrapper>
      {children}
      <Tooltip
        placement="top"
        tooltipText={`Group "${currentGroupName}" can receive connections from group "${allowedGroupName}"`}
        wrapperStyle={connectionTooltipStyle}
        disabled={!isConnIncoming}
        version="newFont"
      >
        <ConnectionWrapper disabled={!isConnIncoming}>
          <Icon name="computerIn" />
          <ConnectionText>In</ConnectionText>
        </ConnectionWrapper>
      </Tooltip>
      <Tooltip
        placement="top"
        tooltipText={`Group "${allowedGroupName}" can receive connections from group "${currentGroupName}"`}
        wrapperStyle={connectionTooltipStyle}
        disabled={!isConnOutgoing}
        version="newFont"
      >
        <ConnectionWrapper disabled={!isConnOutgoing}>
          <Icon name="computerOut" />
          <ConnectionText>Out</ConnectionText>
        </ConnectionWrapper>
      </Tooltip>
      <IconButton
        title="remove group connection"
        icon="ex"
        onClick={onRemove}
        css={{ color: '$error500' }}
      />
    </Wrapper>
  );
}
