// libraries
import { SetStateAction, useState } from 'react';

import Head from 'next/head';
import Image from 'next/image';
import {
  FieldValues,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import { type BoundTurnstileObject } from 'react-turnstile';

import {
  Button,
  CaptchaActions,
  CaptchaWidget,
  ErrorMessage,
  FieldLabel,
  Input,
  styled
} from '@parsec/components';
import { useLogIn, useSendTFAResetEmail } from '@parsec/queries';

import { TURNSTILE_SITE_KEY } from 'lib/config';

import Actions from '../Actions';

import hero from 'lib/images/login@2x.png';

//STYLES

const StyledForm = styled('form', {
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',
  '@large': {
    width: '38rem'
  }
});

const Wrapper = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge',
  transition: '0.25s width ease-in-out'
});

const Hero = styled('div', {
  margin: '0 auto'
});

const Title = styled('h2', {
  fontSize: '$title',
  lineHeight: '$title',
  textAlign: 'left'
});

const LinkButton = styled('button', {
  color: '$primary500',
  fontWeight: 'bold',
  cursor: 'pointer',
  fontSize: 'inherit',
  variants: {
    color: {
      blue: { color: '$primary500' },
      white: { color: '$consoleWhite' }
    }
  }
});

const Recover = styled('p', {
  fontSize: '$info',
  textAlign: 'center'
});

////

interface TfaProps {
  email: string;
  password: string;
  onSuccess(): void;
  onBack(): void;
  id: string;
}

interface FormValues extends FieldValues {
  tfa: string;
}

enum Screen {
  Prompt,
  Reset,
  ConfirmedReset
}

export default function Tfa(props: TfaProps) {
  const { email, password, onSuccess, onBack, id } = props;

  const [screen, setScreen] = useState(Screen.Prompt);

  // CAPTCHA
  // TODO: We're disabling Captcha verification on the login endpoints until
  // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
  // are resolved.
  // const [captchaToken, setCaptchaToken] = useState('');
  // const [captchaBound, setCaptchaBound] = useState<BoundTurnstileObject>();

  const [captchaResetToken, setCaptchaResetToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [captchaResetBound, setCaptchaResetBound] =
    useState<BoundTurnstileObject>();

  // Form methods
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const logIn = useLogIn();
  const sendTfaResetEmail = useSendTFAResetEmail();

  function reset() {
    logIn.reset();
    sendTfaResetEmail.reset();
  }

  const error = logIn.error || sendTfaResetEmail.error;

  return (
    <Wrapper>
      <Head>
        <title>Log in to Parsec | Parsec</title>
      </Head>
      <Hero>
        <Image src={hero.src} alt="" width={250} height={125} />
      </Hero>
      <Title>Log in to Parsec</Title>
      <FormProvider {...methods}>
        <StyledForm
          id={id}
          method="post"
          onSubmit={methods.handleSubmit(async ({ tfa }) => {
            try {
              reset();
              // TODO: We're disabling Captcha verification on the login endpoints until
              // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
              // are resolved.
              //   await logIn.mutateAsync({
              //     email,
              //     password,
              //     tfa,
              //     captcha_token: captchaToken
              //   });
              await logIn.mutateAsync({
                email,
                password,
                tfa,
                // Since we aren't validating the captcha_token, set it to an empty string to satisfy
                // the schema requirements
                captcha_token: ''
              });
              onSuccess();
            } finally {
              // Reset the captcha widget so we are granted a new token
              // TODO: We're disabling Captcha verification on the login endpoints until
              // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
              // are resolved.
              //  setCaptchaToken('');
              //  captchaBound?.reset();
            }
          })}
        >
          {screen === Screen.Prompt ? (
            <TfaForm
              error={error?.error}
              onReset={() => {
                reset();
                setScreen(Screen.Reset);
              }}
              onBack={onBack}
              // TODO: We're disabling Captcha verification on the login endpoints until
              // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
              // are resolved.
              //   setCaptchaToken={setCaptchaToken}
              // setCaptchaBound={setCaptchaBound}
            />
          ) : screen === Screen.Reset ? (
            <TFAResetEmail
              onBack={() => setScreen(Screen.Prompt)}
              onCaptchaVerify={(token: string) => {
                setCaptchaResetToken(token);
              }}
              onCaptchaLoad={(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                _: any,
                bound: SetStateAction<BoundTurnstileObject | undefined>
              ) => {
                setCaptchaResetBound(bound);
              }}
              error={error?.error ?? ''}
              isDisabled={isSubmitting || !captchaResetToken}
              onConfirm={async () => {
                try {
                  setIsSubmitting(true);
                  reset();
                  await sendTfaResetEmail.mutateAsync({
                    email,
                    password,
                    captcha_token: captchaResetToken
                  });
                  setScreen(Screen.ConfirmedReset);
                } finally {
                  setCaptchaResetToken('');
                  captchaResetBound?.reset();
                  setIsSubmitting(false);
                }
              }}
            />
          ) : (
            <TfaResetConfirmed onBack={onBack} />
          )}
        </StyledForm>
      </FormProvider>
    </Wrapper>
  );
}

interface TFAResetEmailProps {
  error: string;
  isDisabled: boolean;
  onCaptchaVerify(token: string): void;
  onCaptchaLoad(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _: any,
    bound: SetStateAction<BoundTurnstileObject | undefined>
  ): void;
  onBack(): void;
  onConfirm(): void;
}

function TFAResetEmail(props: TFAResetEmailProps) {
  const {
    error,
    isDisabled,
    onCaptchaVerify,
    onCaptchaLoad,
    onBack,
    onConfirm
  } = props;
  return (
    <>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <p>An email with recovery instructions will be sent to you.</p>
      <CaptchaWidget
        sitekey={TURNSTILE_SITE_KEY}
        onCaptchaVerify={onCaptchaVerify}
        onCaptchaLoad={onCaptchaLoad}
        action={CaptchaActions.ResetTFA}
      />
      <Actions>
        <LinkButton color="white" type="button" onClick={onBack}>
          Back
        </LinkButton>
        <Button wide type="button" onClick={onConfirm} disabled={isDisabled}>
          Confirm
        </Button>
      </Actions>
    </>
  );
}

interface TfaFormProps {
  error?: string;
  onReset(): void;
  onBack(): void;
  // TODO: We're disabling Captcha verification on the login endpoints until
  // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
  // setCaptchaToken: Dispatch<SetStateAction<string>>;
  // setCaptchaBound: Dispatch<SetStateAction<BoundTurnstileObject>>;
}

function TfaForm(props: TfaFormProps) {
  const { error, onBack, onReset } = props;
  const { register } = useFormContext();

  return (
    <>
      <p>Enter your authenticator app code or a backup code.</p>
      <FieldLabel hasError={Boolean(error)}>
        <FieldLabel.Label label="Code">
          <Input
            {...register('tfa')}
            name="tfa"
            type="numeric"
            placeholder="123456"
            autoFocus
          />
        </FieldLabel.Label>
      </FieldLabel>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}

      {/* 
        TODO: We're disabling Captcha verification on the login endpoints until
          all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
          are resolved.
      <CaptchaWidget
        sitekey={TURNSTILE_SITE_KEY}
        onCaptchaVerify={(token: string) => {
          setCaptchaToken(token);
        }}
        onCaptchaLoad={(_, bound) => {
          setCaptchaBound(bound);
        }}
        action={CaptchaActions.Login}
      /> */}

      <Actions>
        <LinkButton color="white" type="button" onClick={onBack}>
          Back
        </LinkButton>
        <Button wide id="submit_login_tfa">
          Log In
        </Button>
      </Actions>

      <Recover>
        Can&apos;t access your two-factor authentication codes?{' '}
        <LinkButton type="button" onClick={onReset}>
          Send a reset link to your recovery email
        </LinkButton>
        .
      </Recover>
    </>
  );
}

interface TfaResetConfirmedProps {
  onBack(): void;
}

function TfaResetConfirmed(props: TfaResetConfirmedProps) {
  const { onBack } = props;

  return (
    <div>
      <p>Check your recovery email inbox and follow the instructions.</p>
      <Actions css={{ marginTop: '$xlarge' }}>
        <LinkButton color="white" type="button" onClick={onBack}>
          Back
        </LinkButton>
      </Actions>
    </div>
  );
}
