import { Experiment, ExperimentConfig } from '@amplitude/experiment-js-client';

export type { Variant, Variants } from '@amplitude/experiment-js-client';
export { ExperimentClient } from '@amplitude/experiment-js-client';

export * from './src/ExperimentProvider';

const AMPLITUDE_REVERSE_PROXY = process.env.NEXT_PUBLIC_DOMAIN
  ? `https://xp.${process.env.NEXT_PUBLIC_DOMAIN}`
  : undefined;

interface AmpExperimentConfig {
  deployKey: string;
  options?: ExperimentConfig;
}

const defaultExperimentOpts: ExperimentConfig = {
  debug: false,
  automaticFetchOnAmplitudeIdentityChange: true,
  retryFetchOnFailure: true
};

if (AMPLITUDE_REVERSE_PROXY) {
  defaultExperimentOpts.serverUrl = AMPLITUDE_REVERSE_PROXY;
}

export function initExperiment({
  deployKey,
  options = {}
}: AmpExperimentConfig) {
  if (
    process.env.NEXT_PUBLIC_ENV === 'development' ||
    process.env.NODE_ENV === 'development'
  ) {
    options.debug = true;
  }

  const allOptions = { ...defaultExperimentOpts, ...options };

  // Amplitude Analytics should already be loaded via GTM
  const experiment = Experiment.initializeWithAmplitudeAnalytics(
    deployKey,
    allOptions
  );

  return experiment;
}
