import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { PaginatedTeamMemberData, TeamMember, TeamMemberData } from './schema';

/******************************************************************************
 * Get TeamMember
 ******************************************************************************/

export interface GetTeamMemberReq {
  team_id: string;
  user_id: number;
}
export interface GetTeamMemberRes {
  data: TeamMember;
}
export const getTeamMember =
  (options: ApiOptions) =>
  /**
   * Gets a TeamMember by their Team ID and User ID.
   */
  (req: GetTeamMemberReq) => {
    const { user_id, team_id } = req;
    return request<TeamMemberData>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Get TeamMembers
 ******************************************************************************/

export interface GetTeamMembersReq {
  team_id: string;
  offset: number;
  limit: number;
  email?: string;
  group_id?: number;
  team_app_rule_id?: string;
  exclude_team_app_rule?: boolean;
  tag?: string;
  role_id?: string;
  has_role?: boolean;
  is_saml?: boolean;
  is_scim?: boolean;
  is_in_group?: boolean;
}
export const getTeamMembers =
  (options: ApiOptions) =>
  /**
   * Gets a paginated list of TeamMembers for a Team.
   */
  async (req: GetTeamMembersReq) => {
    const {
      team_id,
      offset,
      limit,
      email,
      group_id,
      team_app_rule_id,
      exclude_team_app_rule,
      tag,
      role_id,
      has_role,
      is_saml,
      is_scim,
      is_in_group
    } = req;
    let params = `offset=${offset}&limit=${limit}`;
    if (email) params += `&email=${encodeURIComponent(email)}`;
    if (group_id) params += `&group_id=${group_id}`;
    if (team_app_rule_id) params += `&team_app_rule_id=${team_app_rule_id}`;
    if (tag) params += `&tag=${encodeURIComponent(tag)}`;
    if (role_id) params += `&role_id=${encodeURIComponent(role_id)}`;
    if (exclude_team_app_rule)
      params += `&exclude_team_app_rule=${exclude_team_app_rule}`;
    if (typeof has_role === 'boolean') params += `&has_role=${has_role}`;
    if (typeof is_saml === 'boolean') params += `&is_saml=${is_saml}`;
    if (typeof is_scim === 'boolean') params += `&is_scim=${is_scim}`;
    if (typeof is_in_group === 'boolean')
      params += `&is_in_group=${is_in_group}`;

    const res = await request<PaginatedTeamMemberData>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/members?${params}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
    if (!res.body.data) res.body.data = []; // TODO FIXME
    return res;
  };

/******************************************************************************
 * Get TeamMembersToRemove
 ******************************************************************************/

export interface GetTeamMemberToRemoveReq {
  team_id: string;
}
export interface GetTeamMembersToRemoveRes {
  count: number;
  data: TeamMember[];
}
export const getTeamMembersToRemove =
  (options: ApiOptions) => (req: GetTeamMemberToRemoveReq) => {
    const { team_id } = req;
    return request<GetTeamMembersToRemoveRes>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/members-to-remove`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Update TeamMember
 ******************************************************************************/

export interface UpdateTeamMemberReq {
  team_id: string;
  user_id: number;
  tag: string;
}
export interface UpdateTeamMemberRes {
  data: TeamMember;
}
export const updateTeamMember =
  (options: ApiOptions) =>
  /**
   * Updates a TeamMember by their Team ID and User ID.
   */
  (req: UpdateTeamMemberReq) => {
    const { team_id, user_id, ...body } = req;
    return request<UpdateTeamMemberRes>({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Add TeamMember Roles
 ******************************************************************************/

export interface AddTeamMemberRolesReq {
  team_id: string;
  user_id: number;
  role_ids: string[];
}
export const addTeamMemberRoles =
  (options: ApiOptions) =>
  /**
   * Adds roles to a team member
   */
  (req: AddTeamMemberRolesReq) => {
    const { team_id, user_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}/roles`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Remove TeamMember Roles
 ******************************************************************************/

export interface RemoveTeamMemberRolesReq {
  team_id: string;
  user_id: number;
  role_ids: string[];
}
export const removeTeamMemberRoles =
  (options: ApiOptions) =>
  /**
   * Removes roles to a team member
   */
  (req: RemoveTeamMemberRolesReq) => {
    const { team_id, user_id, ...body } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}/roles`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Add TeamMember
 ******************************************************************************/

export interface AddTeamMemberReq {
  team_id: string;
  token: string;
}
export const addTeamMember =
  (options: ApiOptions) =>
  /**
   * Adds a new TeamMember to a Team.
   */
  (req: AddTeamMemberReq) => {
    const { team_id, token } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/members`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { token }
    });
  };

/******************************************************************************
 * Remove TeamMember
 ******************************************************************************/

export interface RemoveTeamMemberReq {
  team_id: string;
  user_id: number;
}
export const removeTeamMember =
  (options: ApiOptions) =>
  /**
   * Removes an existing TeamMember from a Team.
   */
  (req: RemoveTeamMemberReq) => {
    const { team_id, user_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}`,
      headers: { Authorization: `Bearer ${options.token()}` }
    });
  };

/******************************************************************************
 * Send TFA reset email to TeamMember
 ******************************************************************************/

export interface ResetTeamMemberTFAReq {
  team_id: string;
  user_id: number;
}
export const resetTeamMemberTFA =
  (options: ApiOptions) =>
  /**
   * Sends a TFA reset email to a team member's primary email.
   */
  (req: ResetTeamMemberTFAReq) => {
    const { team_id, user_id } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}/send-tfa-reset-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Update a TeamMember's groups
 ******************************************************************************/

export interface UpdateTeamMemberGroupAssignmentsReq {
  team_id: string;
  user_id: number;
  group_ids: number[] | null;
}

export const updateTeamMemberGroupAssignments =
  (options: ApiOptions) =>
  (
    /**
     * Replaces Team Member's Team Group IDs with the values in group_ids.
     * To unassign all groups, set the value to null.
     */
    req: UpdateTeamMemberGroupAssignmentsReq
  ) => {
    const { team_id, user_id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}/groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Resend confirmation email to TeamMember
 ******************************************************************************/

export interface ResendTeamMemberConfirmationEmailReq {
  team_id: string;
  user_id: number;
}
export const resendTeamMemberConfirmationEmail =
  (options: ApiOptions) =>
  /**
   * Resend confirmation email to a team member's primary email.
   */
  (req: ResendTeamMemberConfirmationEmailReq) => {
    const { team_id, user_id } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}/resend-confirmation-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
