import { useState } from 'react';

import { styled } from '@parsec/styles';

import BaseButton from '../Button';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: '5.6rem',
  alignItems: 'center',
  padding: '$xlarge $xxlarge',
  borderRadius: '$large',
  backgroundColor: '$warning500',
  color: '$ultraDark'
});

const P = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '1.4rem',
  fontWeight: '$normal',
  lineHeight: '$attribution'
});

const Button = styled(BaseButton, { minWidth: '20rem' });

const Heading = styled('h3', {
  fontFamily: '$newDefault',
  fontSize: '$body',
  fontWeight: '$bold',
  lineHeight: '$attribution'
});

interface PageAlertProps {
  title: string;
  message: string | JSX.Element;
  onDismiss?: () => void;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
}

export function PageAlert({
  title,
  message,
  visible: controlledVisible,
  onVisibleChange,
  onDismiss,
  ...rest
}: PageAlertProps) {
  const [visible, setVisible] = useState(controlledVisible ?? true);

  const handleVisibleChange = () => {
    const nextVisibleValue = !(controlledVisible ?? visible);
    onVisibleChange?.(nextVisibleValue);
    setVisible(nextVisibleValue);

    // we're dismissing the alert
    if (!nextVisibleValue) {
      onDismiss?.();
    }
  };

  if (!(controlledVisible ?? visible)) {
    return null;
  }

  return (
    <Wrapper aria-live="polite" {...rest}>
      <div>
        <Heading>{title}</Heading>
        <P>{message}</P>
      </div>
      <Button level="secondary" onClick={handleVisibleChange}>
        Dismiss
      </Button>
    </Wrapper>
  );
}
