// @parsec
import { styled, ErrorMessage, Modal } from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import { useRemoveAvatar } from '@parsec/queries';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

export default function RemoveAvatarModal(props: Props) {
  const { isOpen, onClose, onAfterClose } = props;

  const removeAvatar = useRemoveAvatar();

  return (
    <Modal
      title="Remove Profile Picture"
      size="small"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      actions={[
        { text: 'Remove', kind: 'error', form: 'remove_avatar' },
        { text: 'Cancel', level: 'secondary', onClick: onClose }
      ]}
    >
      <Form
        id="remove_avatar"
        onSubmit={handleFormSubmit(async () => {
          await removeAvatar.mutateAsync();
          onClose();
        })}
      >
        <p>Are you sure you want to remove your profile picture?</p>
        {removeAvatar.error ? (
          <ErrorMessage>{removeAvatar.error.error}</ErrorMessage>
        ) : null}
      </Form>
    </Modal>
  );
}

const Form = styled('form', {
  display: 'grid',
  rowGap: '$medium',
  justifyItems: 'center'
});
