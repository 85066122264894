import type { ComponentPropsWithRef } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

// Pre-styled table elements to use within SubscriptionSummary
const BaseTh = styled('th', {
  '& + &': {
    padding: '$small $xxxlarge 0'
  },
  '&:first-child': {
    textAlign: 'left'
  },
  [`&[scope="col"]`]: {
    paddingTop: 0,
    '&:last-child': {
      paddingRight: 0
    }
  },
  [`&[scope="row"]`]: {
    fontWeight: '$normal'
  },
  variants: {
    disabled: {
      true: {
        color: '$nice'
      }
    },
    warning: {
      true: {
        display: 'grid',
        gridTemplateColumns: '1.4rem auto',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [`&[scope="col"]`]: {
          paddingRight: '$xxlarge'
        }
      }
    }
  }
});

const Tr = styled('tr', {
  '& > th:first-child': {
    paddingRight: '7rem'
  }
});

const Td = styled('td', {
  padding: '$small $xxxlarge 0',
  '&:last-child': {
    paddingRight: 0
  },

  variants: {
    disabled: {
      true: {
        color: '$nice'
      }
    },
    warning: {
      true: { color: '$warning500' }
    }
  }
});

const BaseTable = styled('table', {
  fontFamily: '$newDefault',
  width: '100%',
  fontSize: '1.4rem',
  lineHeight: '$attribution',
  textAlign: 'right'
});

// These exist to keep the API consistent so devs aren't confused what's a subcomponent and what isn't
const Thead = styled('thead', {});
const Tfoot = styled('tfoot', {});
const Tbody = styled('tbody', {});

const WarningIcon = styled(Icon, {
  color: '$warning500',
  height: '1rem',
  width: '1rem'
});

export interface ThProps extends ComponentPropsWithRef<typeof BaseTh> {
  warning?: boolean;
}

function Th({ children, warning = false, ...rest }: ThProps) {
  return (
    <BaseTh {...rest} warning={warning}>
      {warning ? <WarningIcon name="warning" /> : null}
      {children}
    </BaseTh>
  );
}

const getDisabledColStyles = (col: number) => {
  if (col === 0) {
    return {
      '& th:nth-of-type(1)': {
        color: '$nice'
      }
    };
  }

  return {
    [`& th[scope="col"]:nth-of-type(${col + 1}), & td:nth-of-type(${col})`]: {
      color: '$nice'
    }
  };
};

export interface TableProps extends ComponentPropsWithRef<typeof BaseTable> {
  disabledCols?: number | number[];
}

export function Table({ disabledCols, ...rest }: TableProps) {
  // Lets us style multiple column font color
  let disabledColStyles = disabledCols ? {} : undefined;
  if (disabledCols) {
    if (Array.isArray(disabledCols)) {
      disabledColStyles = disabledCols.reduce((acc, currColNum: number) => {
        const style = getDisabledColStyles(currColNum);
        acc = { ...acc, ...style };
        return acc;
      }, {});
    } else {
      disabledColStyles = getDisabledColStyles(disabledCols);
    }
  }
  return (
    <BaseTable
      {...(disabledColStyles ? { css: disabledColStyles } : {})}
      {...rest}
    />
  );
}

Table.ChangesIcon = <WarningIcon name="warning" />;
Table.Thead = Thead;
Table.Tfoot = Tfoot;
Table.Tbody = Tbody;
Table.Tr = Tr;
Table.Td = Td;
Table.Th = Th;
