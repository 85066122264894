import { useCallback, useEffect, useState } from 'react';

import Identify from '@parsec/analytics/src/utils/Identify';
import Head from 'next/head';
import Image from 'next/image';

import { pushToDl, pushDlSignUpV3, pushDlSignUp } from '@parsec/analytics';
import { Button, styled } from '@parsec/components';

import { SignUpDisclaimer, Wrapper } from 'components';
import { EMAIL_VERIFIED_ID } from 'lib/constants/signupFlowIds';
import { useIsInSignupFlow, useSignup } from 'lib/hooks';
import { getJourneyTrigger } from 'lib/util/journey-util';

import { SignupCtaFlow } from './interfaces/types';

import passwordHero from 'lib/images/password@2x.png';

// STYLES
const ContentWrapper = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  rowGap: '3.6rem',
  maxWidth: '38rem'
});

const Title = styled('h1', {
  fontSize: '$title',
  lineHeight: '$title'
});

const title = 'Email Verified!';

// COMPONENT
export default function EmailVerified() {
  const { setIsInSignupFlow } = useIsInSignupFlow();
  const { send, userData, teamData, savedUrlParams } = useSignup();
  const { isTeam, isTeamAdmin } = teamData;

  const [timer, setTimer] = useState(5);
  const trigger = getJourneyTrigger(savedUrlParams);

  const onNext = useCallback(() => {
    pushToDl({
      event: 'userEvent',
      event_name: 'login_action',
      properties: {
        login_action: 'user_create',
        login_method: 'email'
      }
    });

    // CTA param flow
    if (savedUrlParams) {
      switch (savedUrlParams['cta']) {
        case SignupCtaFlow.Teams: {
          send({ type: 'TEAM_SELECTED' });
          break;
        }
        case SignupCtaFlow.Warp: {
          send({ type: 'WARP_SELECTED' });
          break;
        }
        case SignupCtaFlow.Standard:
        default:
          send({ type: 'EMAIL_VERIFIED_CONTINUE' });
      }
    } else {
      send({ type: 'EMAIL_VERIFIED_CONTINUE' });
    }
  }, [savedUrlParams, send]);

  const handleNextClick = useCallback(() => {
    if (isTeam || isTeamAdmin) {
      pushDlSignUp({ step: '1.7.2.1', isTeam, isTeamAdmin, trigger });
    } else {
      pushDlSignUpV3({ step: '1.8.1', trigger });
    }
    onNext();
  }, [isTeam, isTeamAdmin, onNext, trigger]);

  // dataLayer push viewed
  useEffect(() => {
    if (isTeam || isTeamAdmin) {
      pushDlSignUp({
        step: '1.7.1',
        isTeam,
        isTeamAdmin,
        isConversion: true,
        trigger
      });
    } else {
      pushDlSignUpV3({ step: '1.7', isConversion: true, trigger });
    }
    Identify({
      userId: userData.userId,
      marketingOptIn: userData.marketing_opt_in,
      signedUp: true,
      userEmail: userData.email
    });
  }, [
    userData.userId,
    userData.marketing_opt_in,
    userData.email,
    isTeam,
    isTeamAdmin,
    trigger
  ]);

  useEffect(() => {
    // Set boolean in local storage to check if the user is in the signup flow
    setIsInSignupFlow();

    const counter = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }

      if (timer - 1 <= 0) {
        if (isTeam || isTeamAdmin) {
          pushDlSignUp({ step: '1.7.2.2', isTeam, isTeamAdmin, trigger });
        } else {
          pushDlSignUpV3({ step: '1.8.2', trigger });
        }
        onNext();
      }
    }, 1000);

    return () => {
      clearInterval(counter);
    };
  }, [
    setTimer,
    timer,
    setIsInSignupFlow,
    onNext,
    isTeam,
    isTeamAdmin,
    trigger
  ]);

  return (
    <Wrapper layout="spread" spacer="top">
      <Head>
        <title>{`${title} | Parsec`}</title>
        <meta name="robots" key="robots" content="noindex, nofollow" />
      </Head>
      <ContentWrapper id={EMAIL_VERIFIED_ID}>
        <Image src={passwordHero.src} alt="" width={250} height={125} />

        <Title>{title}</Title>

        <p id="continue-button">
          You&apos;re good to go! You may now access your account. This page
          will automatically advance in a moment, but you may click the button
          below to advance if needed.
        </p>

        <Button
          aria-describedby="continue-button"
          wide
          onClick={handleNextClick}
        >
          Continuing in {timer}
        </Button>
      </ContentWrapper>
      <SignUpDisclaimer />
    </Wrapper>
  );
}
