import { ReactNode } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { captureException } from '@parsec/sentry';

import { shouldLoadStripe, STRIPE_KEY } from 'lib/config';

const promise = shouldLoadStripe
  ? loadStripe(STRIPE_KEY).catch(err => {
      captureException(err);
      return null;
    })
  : null;

const stripeOptions = {
  fonts: [
    {
      src: `url(/fonts/sourcesanspro-regular.woff)`,
      family: 'Source Sans Pro'
    }
  ]
};

interface Props {
  children: ReactNode;
}

export default function StripeProvider(props: Props) {
  const { children } = props;

  return (
    <Elements stripe={promise} options={stripeOptions}>
      {children}
    </Elements>
  );
}
