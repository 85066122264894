import { forwardRef, ComponentProps } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

type BaseOptionProps = JSX.IntrinsicElements['option'];

export interface SelectOption extends BaseOptionProps {
  value: string | number;
  name: string;
}

export type SelectProps = ComponentProps<typeof StyledSelect> & {
  options: SelectOption[];
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props: SelectProps, ref) => {
    const { className, options, ...rest } = props;
    return (
      <Wrapper className={className}>
        <StyledIcon name="caret" />
        <StyledSelect ref={ref} {...rest}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </StyledSelect>
      </Wrapper>
    );
  }
);
Select.displayName = 'Select';

export default Select;

const Wrapper = styled('div', {
  position: 'relative'
});

const StyledIcon = styled(Icon, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '$large',
  pointerEvents: 'none',
  zIndex: 1
});

const StyledSelect = styled('select', {
  appearance: 'none',
  position: 'relative',
  width: '100%',
  height: '3.6rem',
  fontSize: '1.4rem',
  lineHeight: '$attribution',
  boxShadow:
    '0px $space$xxsmall $space$xsmall rgba(0, 0, 0, 0.18), inset 0px $space$xxsmall 0px rgba(255, 255, 255, 0.07)',
  border: 'none',
  borderRadius: '$medium',
  color: '$consoleWhite',
  backgroundColor: '$chandelure',
  textOverflow: 'ellipsis',
  padding: '0 calc($large * 2 + 1.6rem) 0 $large',
  '& ~ svg': {
    color: '$nice'
  },
  '&::placeholder': {
    color: '$rhyhorn'
  },

  '&:disabled': {
    color: '$nice',
    backgroundColor: '$duskull',
    '&::placeholder': {
      color: '$nice'
    },
    '& ~ svg': {
      color: '$nice'
    }
  },
  '&:not(:disabled)': {
    '&:-webkit-autofill:focus': {
      transition: 'background-color 600000s 0s, color 600000s 0s'
    },
    '&:-webkit-autofill': {
      transition: 'background-color 600000s 0s, color 600000s 0s'
    },
    '&:hover': {
      outline: '0',
      boxShadow:
        'inset 0 0 0 $space$xxsmall $colors$pangoro, 0px $space$xxsmall 0px rgba(255,255,255,0.1)'
    },
    '&:focus': {
      boxShadow: 'inset 0 0 0 $space$xsmall $colors$primary500',
      color: '$consoleWhite',
      border: 'none',
      outlineStyle: 'none',
      '& ~ svg': {
        color: '$consoleWhite '
      }
    },

    '&::-moz-focus-inner': {
      border: 'none'
    },

    '&:invalid': {
      boxShadow: 'inset 0 0 0 $space$xsmall $colors$error500 !important'
    }
  }
});
