import { NextRouter } from 'next/router';

export function param(param?: string | string[]) {
  if (!param) return '';
  return Array.isArray(param) ? param[0] : param;
}

export function MockRouter(router: Partial<NextRouter>): NextRouter {
  return {
    basePath: '',
    pathname: '',
    route: '',
    query: {},
    asPath: '',
    back: jest.fn(),
    forward: jest.fn(),
    beforePopState: jest.fn(),
    prefetch: jest.fn(() => Promise.resolve()),
    push: jest.fn(),
    reload: jest.fn(),
    replace: jest.fn(),
    events: {
      on: jest.fn(),
      off: jest.fn(),
      emit: jest.fn()
    },
    isFallback: false,
    isLocaleDomain: false,
    isReady: true,
    defaultLocale: 'en',
    domainLocales: [],
    isPreview: false,
    ...router
  };
}
