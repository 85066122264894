// libraries
import { useCallback, useState, FormEvent } from 'react';

// @parsec
import {
  styled,
  ErrorMessage,
  FieldLabel,
  Input,
  Modal
} from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import { useUpdateMe } from '@parsec/queries';

interface Props {
  id: number;
  name: string;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

export default function AccountModal(props: Props) {
  const { id, name: prevName, isOpen, onClose, onAfterClose } = props;

  const [name, setName] = useState(prevName);

  const updateMe = useUpdateMe(),
    updateMeMutateAsync = updateMe.mutateAsync;

  const inputMaxLength = 255;

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) =>
      handleFormSubmit<{
        name: string;
        password?: string;
        tfa?: string;
      }>(async values => {
        if (values.name === prevName) return;

        if (values.name !== prevName) {
          await updateMeMutateAsync({ name: values.name });
        }

        onClose();
      })(e),
    [prevName, updateMeMutateAsync, onClose]
  );

  return (
    <Modal
      title="Edit Account"
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      actions={[
        { text: 'Confirm & Save', form: 'edit_account' },
        {
          text: 'Cancel',
          level: 'secondary',
          onClick: onClose
        }
      ]}
    >
      <Form id="edit_account" onSubmit={handleSubmit}>
        <FieldLabel>
          <FieldLabel.Label label="Username">
            <Input
              name="name"
              defaultValue={name}
              onChange={e => setName(e.target.value)}
              maxLength={inputMaxLength}
            />
            <InfoWrapper>
              <Info>#{id}</Info>
              <Info>{name.length}/255</Info>
            </InfoWrapper>
          </FieldLabel.Label>
        </FieldLabel>

        {updateMe.error ? (
          <ErrorMessage>{updateMe.error.error}</ErrorMessage>
        ) : null}
      </Form>
    </Modal>
  );
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$large'
});

const Info = styled('span', {
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  textAlign: 'right'
});

const InfoWrapper = styled('div', {
  justifySelf: 'end',
  '& span:first-child': {
    marginRight: '$xxlarge'
  }
});
