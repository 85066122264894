import { useState } from 'react';

import { FieldValues, ValidateResult, useFormContext } from 'react-hook-form';

import {
  ErrorMessage as BaseErrorMessage,
  FieldLabel,
  Input,
  Select
} from '@parsec/components';
import { COUNTRY_CODES, STATE_CODES } from '@parsec/constants';
import { Customer as KesselCustomer } from '@parsec/kessel';
import { styled } from '@parsec/styles';

// Input captures the fields available in the Customer component
export interface CustomerData {
  company_name?: string;
  billing_email: string;
  phone?: string;
  tax_id?: string;
  first_name?: string;
  last_name?: string;
  address_1: string;
  address_2?: string;
  country: string;
  state?: string;
  city: string;
  postal_code?: string;
}

interface CustomerProps {
  customer: Partial<KesselCustomer>;
  disabled?: boolean;
  validateName(value: string, formValues: FieldValues): ValidateResult;
  requireCompanyName: boolean;
}

export function Customer(props: CustomerProps) {
  const { customer, disabled, validateName, requireCompanyName } = props;

  const {
    register,
    formState: { errors }
  } = useFormContext();

  const [requireZip, setRequireZip] = useState(
    customer?.billing_address?.country === 'US'
  );

  const [requireState, setRequireState] = useState(
    customer?.billing_address?.country === 'US'
  );

  return (
    <FieldSet>
      <Label>
        <FieldLabel.Label label="Company Name" required={requireCompanyName}>
          <Input
            {...register('company_name', {
              validate: validateName
            })}
            name="company_name"
            placeholder="Parsec"
            defaultValue={customer?.company}
            required={requireCompanyName}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>

      <Label>
        <FieldLabel.Label label="Billing Email" required>
          <Input
            {...register('billing_email')}
            type="email"
            pattern='^(([^\<\>\(\)\[\]\\.,;:\s@"]+(\.[^\<\>\(\)\[\]\\.,;:\s@]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            name="billing_email"
            placeholder="sam.oak@parsec.app"
            defaultValue={customer?.email}
            required
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>

      <Label>
        <FieldLabel.Label label="Phone">
          <Input
            {...register('phone')}
            name="phone"
            placeholder="XXX-XXX-XXXX"
            defaultValue={customer?.phone}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>

      <Label>
        <FieldLabel.Label label="Tax ID">
          <Input
            {...register('tax_id')}
            name="tax_id"
            placeholder="ID"
            defaultValue={customer?.tax_id}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>
      <Label>
        <FieldLabel.Label label="First Name">
          <Input
            {...register('first_name')}
            name="first_name"
            placeholder="Samuel"
            defaultValue={customer?.first_name}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>
      <Label>
        <FieldLabel.Label label="Last Name">
          <Input
            {...register('last_name')}
            name="last_name"
            placeholder="Oak"
            defaultValue={customer?.last_name}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>

      <Label>
        <FieldLabel.Label label="Address Line 1" required>
          <Input
            {...register('address_1')}
            name="address_1"
            placeholder="123 Place St"
            defaultValue={customer?.billing_address?.line1}
            required
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>
      <Label>
        <FieldLabel.Label label="Address Line 2">
          <Input
            {...register('address_2')}
            name="address_2"
            placeholder="Suite #1"
            defaultValue={customer?.billing_address?.line2}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>

      <Label>
        <FieldLabel.Label label="Country" required>
          <Select
            {...register('country')}
            name="country"
            options={countryCodes}
            defaultValue={customer?.billing_address?.country ?? ''}
            onChange={event => {
              setRequireZip(event.target.value === 'US');
              setRequireState(event.target.value === 'US');
            }}
            required
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>
      {requireState ? (
        <Label>
          <FieldLabel.Label label="State" required>
            <Select
              {...register('state')}
              name="state"
              options={stateCodes}
              defaultValue={customer?.billing_address?.state}
              disabled={disabled}
              required
            />
          </FieldLabel.Label>
        </Label>
      ) : (
        <Label>
          <FieldLabel.Label label="State">
            <Input
              {...register('state')}
              name="state"
              placeholder="state/province"
              defaultValue={customer?.billing_address?.state}
              disabled={disabled}
            />
          </FieldLabel.Label>
        </Label>
      )}
      <Label>
        <FieldLabel.Label required label="City">
          <Input
            {...register('city')}
            name="city"
            placeholder="Pallet Town"
            defaultValue={customer?.billing_address?.city}
            required
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>
      <Label>
        <FieldLabel.Label label="Zip / Postal Code" required={requireZip}>
          <Input
            {...register('postal_code')}
            name="postal_code"
            placeholder="00000"
            defaultValue={customer?.billing_address?.zip}
            required={requireZip}
            disabled={disabled}
          />
        </FieldLabel.Label>
      </Label>
      {errors.company_name && (
        <ErrorMessage>{errors.company_name.message?.toString()}</ErrorMessage>
      )}
    </FieldSet>
  );
}

const FieldSet = styled('fieldset', {
  padding: '$xxlarge 0',
  display: 'grid',
  rowGap: '$large',
  columnGap: '2.2rem',
  maxWidth: '100%',
  gridTemplateColumns: 'repeat(2, 1fr)'
});

const Label = styled(FieldLabel, {
  fontSize: '$info'
});

const countryCodes = [
  { value: '', name: '-- select country --' },
  ...COUNTRY_CODES
];

const stateCodes = [{ value: '', name: '-- select state --' }, ...STATE_CODES];

const ErrorMessage = styled(BaseErrorMessage, {
  marginBottom: '$small'
});
