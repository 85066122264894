import { useState } from 'react';

import { useResumeWarpSubscription } from '@parsec/queries';
import { parseError } from '@parsec/request';

export default function useResumeTrialModalState() {
  const [step, setStep] = useState('trial');
  const [error, setError] = useState('');

  const resumeWarpSubscription = useResumeWarpSubscription();
  const loading = resumeWarpSubscription.isLoading;

  const resumeSubscription = async () => {
    try {
      await resumeWarpSubscription.mutateAsync();
    } catch (err) {
      const error = parseError(err);
      setError(
        error.error ??
          'There was an error resuming your Warp Trial. Please try again.'
      );
    }
  };

  return {
    error,
    step,
    loading,
    setStep,
    setError,
    handleResume: resumeSubscription
  };
}
