import { createContext, useContext, useState, ReactNode } from 'react';

import { createPortal } from 'react-dom';
import ReactModal from 'react-modal';

import { styled } from '@parsec/styles';

import Button from './Button';

export interface IntroModalAction {
  text: string;
  onClick?(): void;
  level?: 'primary' | 'secondary' | 'link';
  kind?: 'neutral' | 'success' | 'error';
  form?: string;
}

export interface IntroModalProps {
  title?: string;
  description?: string;
  hero?: ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onOpen?(): void;
  onClose(): void;
  onAfterClose?(): void;
  action?: IntroModalAction;
  padding?: boolean;
  version?: 'newFont';
}

const ModalContext = createContext<HTMLElement | null>(null);

function IntroModal(props: IntroModalProps) {
  const {
    title,
    description,
    hero,
    isOpen,
    onOpen,
    onAfterClose,
    onClose,
    action,
    version
  } = props;

  const [portal, setPortal] = useState<HTMLElement | null>(null);

  return (
    <ModalContext.Provider value={portal}>
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={250}
        contentLabel={title}
        onAfterOpen={() => onOpen?.()}
        onRequestClose={() => onClose()}
        onAfterClose={onAfterClose}
      >
        <Wrapper>
          <Main>
            <Title version={version}>{title}</Title>
            <Description version={version}>{description}</Description>
            {action && (
              <div ref={setPortal}>
                <ModalBtn
                  onClick={action.onClick}
                  level={action.level}
                  kind={action.kind}
                  form={action.form}
                  version={version}
                >
                  {action.text}
                </ModalBtn>
              </div>
            )}
          </Main>
          {hero && <Hero>{hero}</Hero>}
        </Wrapper>
      </ReactModal>
    </ModalContext.Provider>
  );
}

function Actions(props: { children: ReactNode }) {
  const { children } = props;
  const portal = useContext(ModalContext);

  return portal ? createPortal(children, portal) : null;
}

export function setIntroModalAppElement(el: string | HTMLElement) {
  ReactModal.setAppElement(el);
}

IntroModal.Actions = Actions;

ReactModal.defaultStyles = {};

export default IntroModal;

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '40% auto',
  border: '$space$xxsmall solid $pangoro',
  justifyItems: 'end',
  color: '$consoleWhite',
  backgroundColor: '$carkol',
  borderRadius: '$large',
  overflow: 'hidden',
  width: '91rem',
  maxHeight: '100vh'
});

const Main = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto 100%',
  gridRowGap: '$large',
  padding: '4rem'
});

const Title = styled('h1', {
  textTransform: 'capitalize',
  fontWeight: 'bold',
  fontSize: '$body',
  color: '$consoleWhite',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        lineHeight: '2.2rem'
      }
    }
  }
});

const Description = styled('div', {
  fontSize: '$info',
  lineHeight: '$info',
  color: '$consoleWhite',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        lineHeight: '$body'
      }
    }
  }
});

const ModalBtn = styled(Button, {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.4rem'
      }
    }
  }
});

const Hero = styled('div', {
  justifyContent: 'right',
  alignSelf: 'end',
  padding: '4rem 4.5rem 4rem',
  overflow: 'auto',
  backgroundColor: '$carkol'
});
