import { useEffect } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';

import { pushDlSelectProduct } from '@parsec/analytics';
import { styled } from '@parsec/components';
import { useUpdateMarketingAttrData } from '@parsec/queries';

import { PathLink, Wrapper, SignUpDisclaimer } from 'components';
import { SELECT_PRODUCT_ID } from 'lib/constants/signupFlowIds';
import { useSignup } from 'lib/hooks/useSignup';

// STYLES
const ContentWrapper = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  rowGap: '4rem',
  width: '46rem'
});

const Title = styled('h1', {
  fontSize: '$title',
  lineHeight: '$title',
  marginBottom: '$xlarge'
});

const Text = styled('p', {
  textAlign: 'center'
});

const LinkList = styled('ul', {
  display: 'grid',
  rowGap: '$xxlarge',
  width: '100%'
});

// TODO update import once we upgrade to React 18
const BackToLogInModal = dynamic(
  () => import('../SelectProduct/BackToLogInModal'),
  { ssr: false }
);

export default function SelectProduct() {
  const { mutateAsync: updateMarketingAttrData } = useUpdateMarketingAttrData();

  const { send } = useSignup();

  const handlePathSelection = async (path: 'me' | 'teams') => {
    if (path === 'me') {
      pushDlSelectProduct({ step: '2.2.1' });
    } else {
      // teams
      pushDlSelectProduct({ step: '2.2.2' });
    }
    await updateMarketingAttrData({
      attribution_data: {
        sku_first_interest: path
      }
    });
  };

  useEffect(() => {
    // from select-product.page.tsx
    // user is supposed to view this page
    pushDlSelectProduct({ step: '2.1' });
  }, []);

  return (
    <Wrapper layout="spread" spacer="top">
      <Head>
        <title>Select product | Parsec</title>
        <meta name="robots" key="robots" content="noindex, nofollow" />
      </Head>

      <ContentWrapper id={SELECT_PRODUCT_ID}>
        <div>
          <Title>Welcome to Parsec</Title>
          <Text id="choice-description">I want to use Parsec for...</Text>
        </div>
        <LinkList aria-describedby="choice-description">
          <li>
            <PathLink
              href="#"
              onClick={e => {
                e.preventDefault();
                handlePathSelection('me');
                send({ type: 'MYSELF_SELECTED' });
              }}
            >
              <PathLink.Content>
                <PathLink.Title>Myself</PathLink.Title>
                <p>Download the Parsec desktop app and get connected.</p>
              </PathLink.Content>
            </PathLink>
          </li>
          <li>
            <PathLink
              href="#"
              isTeam
              onClick={e => {
                e.preventDefault();
                handlePathSelection('teams');
                send({ type: 'TEAM_SELECTED' });
              }}
            >
              <PathLink.Content>
                <PathLink.Title>My Team</PathLink.Title>
                <p>
                  Set up your team and get started right away with a free Parsec
                  For Teams Trial.
                </p>
              </PathLink.Content>
            </PathLink>
          </li>
        </LinkList>
        <BackToLogInModal />
      </ContentWrapper>
      <SignUpDisclaimer />
    </Wrapper>
  );
}
