// libraries
import type { TeamSubscription, User, WarpSubscription } from '@parsec/kessel';

import { useCallback, useMemo, useState } from 'react';

// @parsec
import { styled, Avatar, AlertBanner, Button, Icon } from '@parsec/components';
import {
  useModals,
  useTeamSubscriptionCost,
  useWarpSubscriptionCost
} from '@parsec/hooks';
import {
  useGetTFA,
  useGetWarpSubscription,
  useGetTeamSubscription,
  useGetTeamRolePermissionSummary,
  useGetTeam,
  useGetMe
} from '@parsec/queries';

import { TEAMS_URL } from 'lib/config';

import EnableTFAModal from './EnableTFAModal';
import { Plan } from './subscription';

interface Props {
  user: User;
  teamName?: string;
  children?: ReactNode;
}

const getTermEnd = (subscription: WarpSubscription | TeamSubscription) => {
  return (
    subscription.cancelled_at ??
    subscription.current_term_end ??
    subscription.trial_end
  );
};

export default function Header(props: Props) {
  const { user, teamName, children } = props;

  const { open: openModal, dom: modalDom } = useModals({
    enableTfa: EnableTFAModal
  });

  const openTfaModal = useCallback(() => {
    openModal('enableTfa', {});
  }, [openModal]);

  const tfa = useGetTFA();
  const permissions = useGetTeamRolePermissionSummary();
  const teamEstimate = useTeamSubscriptionCost();
  const warpEstimate = useWarpSubscriptionCost();

  const warpSubscription = useGetWarpSubscription(),
    warpSub = warpSubscription.data && {
      isMonthly: warpSubscription.data.billing_interval === 'month',
      termEnd: getTermEnd(warpSubscription.data),

      status:
        warpSubscription.data.status === 'in_trial' &&
        warpSubscription.data.cancelled_at //non_renewing trial
          ? 'non_renewing'
          : warpSubscription.data?.status,
      estimate: warpEstimate.total,
      trial: warpSubscription.data.status === 'in_trial'
    };

  const teamSubscription = useGetTeamSubscription();

  const team = useGetTeam();

  // Using useMemo here instead of the same format as above because waiting for more than one query.
  const teamSub = useMemo(() => {
    if (!teamSubscription.data || !team.data) return;

    const isTrial = teamSubscription.data?.status === 'in_trial';

    const nonRenewingTrial = isTrial && teamSubscription.data?.cancelled_at;

    return {
      isAdmin: true as const,
      isMonthly: teamSubscription.data?.billing_interval === 'month',
      termEnd: getTermEnd(teamSubscription.data),
      isTrial: isTrial,
      status: nonRenewingTrial ? 'non_renewing' : teamSubscription.data?.status,
      estimate: teamEstimate.total,
      isSelfServeTrial:
        isTrial && teamSubscription?.data?.trial_end_activation_enabled,
      isSelfServe: !team.data?.capabilities.features.is_self_serve_enabled
    };
  }, [team, teamSubscription?.data, teamEstimate]);

  const teamLoading =
    teamSubscription.isLoading &&
    !teamSubscription.isFetched &&
    team.isLoading &&
    !team.isFetched;

  const warpLoading = warpSubscription.isLoading && !warpSubscription.isFetched;

  const teamSubLoading = team.data && !teamSub;

  const loading = Boolean(warpLoading || teamLoading || teamSubLoading);

  const hasAdminPanelAccess = useMemo(() => {
    if (!permissions.data) return false;

    const hasAnyTeamPerms = Object.values(permissions.data?.team ?? {}).some(
      Boolean
    );

    const hasAnyGroupPerms =
      Object.keys(permissions.data?.groups ?? {}).length > 0;

    return hasAnyTeamPerms || hasAnyGroupPerms;
  }, [permissions.data]);

  const me = useGetMe();

  const [showOutOfComplianceBanner, setShowOutOfComplianceBanner] = useState(
    me.data?.is_out_of_compliance ?? false
  );

  return (
    <header>
      {modalDom}
      {children}
      {showOutOfComplianceBanner ? (
        <AlertBanner
          level="warn"
          onClose={() => setShowOutOfComplianceBanner(false)}
        >
          <AlertBanner.Title>
            Parsec for Teams: Contact your admin
          </AlertBanner.Title>
          <AlertBanner.Description>
            Your email domain has been verified as a Parsec for Teams account.
            Please reach out to your internal IT team for Parsec access. You
            will not be able to make connections until access is granted.
          </AlertBanner.Description>
        </AlertBanner>
      ) : null}

      <H1>Account</H1>
      <Content>
        <Picture userId={user.id} nonce={user.avatar_nonce} size={160} />
        <Info>
          <Handle>
            <Name>{user.name}</Name>
            <Id>#{user.id}</Id>
          </Handle>
          <SubInfo>
            {teamName ? <TeamName>{teamName}</TeamName> : null}
            <Email>{user.email}</Email>
          </SubInfo>
          <Actions>
            {!tfa.isFetched ? null : tfa.data ? (
              <TfaBadge>
                <Icon name="shield" /> 2FA is enabled
              </TfaBadge>
            ) : (
              <Button onClick={openTfaModal}>Enable 2FA</Button>
            )}
            {user.team_id && hasAdminPanelAccess ? (
              <Button href={TEAMS_URL}>Go to Admin Panel</Button>
            ) : null}
          </Actions>
        </Info>
        {loading ? null : (
          <StyledPlan
            teams={teamSub || (user.team_id ? { isAdmin: false } : undefined)}
            warp={warpSub}
          />
        )}
      </Content>
    </header>
  );
}

const H1 = styled('h1', {
  fontSize: '4.8rem',
  lineHeight: '5.8rem',
  fontWeight: 'normal',
  padding: '$medium 0',
  boxShadow: '0 0.1rem 0 $colors$pangoro'
});

const Content = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: '3.6rem',
  padding: '$xxxlarge 0',
  '@large': {
    gridTemplateColumns: 'auto 1fr min-content'
  }
});

const Info = styled('div', {
  overflow: 'hidden'
});

const Picture = styled(Avatar, {
  width: '8rem',
  height: '8rem',
  '@large': {
    width: '16rem',
    height: '16rem'
  }
});

const Handle = styled('span', {
  display: 'flex',
  fontFamily: '$heading',
  overflow: 'hidden',
  fontSize: '$title',
  lineHeight: '$title',
  '@large': {
    fontSize: '4rem',
    lineHeight: '4.8rem'
  }
});

const Name = styled('span', {
  display: 'block',
  fontWeight: 'bolder',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const Id = styled('span', {
  color: '$rhyhorn'
});

const SubInfo = styled('div', {
  display: 'flex',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  '@large': {
    fontSize: '$subtitle',
    lineHeight: '$subtitle'
  }
});

const Email = styled('span', {
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const TeamName = styled('span', {
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  '&::after': {
    content: '•',
    margin: '0 $medium'
  }
});

const Actions = styled('div', {
  display: 'grid',
  justifyContent: 'start',
  gridAutoFlow: 'column',
  columnGap: '$xlarge',
  marginTop: '$xxxlarge'
});

const TfaBadge = styled('div', {
  display: 'inline-grid',
  gridAutoFlow: 'column',
  columnGap: '$medium',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  padding: '0 $xlarge',
  lineHeight: '3.6rem',
  backgroundColor: '$success900',
  borderRadius: '1.8rem',
  color: '$success500'
});

const StyledPlan = styled(Plan, {
  gridRow: 2,
  gridColumn: '1 / span 2',
  minWidth: '30rem',
  '@large': {
    gridRow: 1,
    gridColumn: 3
  }
});
