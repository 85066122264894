import { ComponentProps, forwardRef, ReactNode, Ref } from 'react';

import { styled } from '@parsec/styles';

import Button from './Button';

export type ClearButtonProps = ComponentProps<typeof StyledButton> & {
  loading?: boolean;
  icon?: ReactNode;
  ref?: Ref<HTMLButtonElement>;
};

export const ClearButton = forwardRef<HTMLButtonElement, ClearButtonProps>(
  function (props, ref) {
    const { children, ...rest } = props;
    return (
      <StyledButton ref={ref} {...rest}>
        {children}
      </StyledButton>
    );
  }
);

ClearButton.displayName = 'ClearButton';

export default ClearButton;

const StyledButton = styled(Button, {
  backgroundColor: 'transparent',
  '&:hover, &:focus, &:active': {
    backgroundColor: 'transparent'
  },
  border: '1px solid $consoleWhite'
});
