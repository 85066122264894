/*
Help Icon with optional tooltip
*/

import { styled } from '@parsec/styles';

import IconButton, { IconButtonProps } from '../IconButton';
import Tooltip from '../Tooltip';

const StyledIconButton = styled(IconButton, {
  display: 'block',
  width: 'unset',
  height: 'unset',
  color: '$rhyhorn',
  transition: '0.25s all ease',
  '&:hover, &:focus': {
    color: '$dewgon'
  }
});

export type HelpIconProps = Omit<IconButtonProps, 'icon'> & {
  toolTip?: string;
};

const ToolTipped = ({
  text,
  children
}: {
  text?: string;
  children: JSX.Element;
}): JSX.Element =>
  text ? (
    <Tooltip tooltipText={text} placement="top">
      {children}
    </Tooltip>
  ) : (
    children
  );

const HelpIcon = ({ toolTip, ...props }: HelpIconProps) => (
  <ToolTipped text={toolTip}>
    <StyledIconButton icon="help" {...props} css={{ color: '$rhyhorn' }} />
  </ToolTipped>
);

export default HelpIcon;
