import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

export function useIsReady(onIsReady?: () => void): boolean {
  const router = useRouter();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!router.isReady) return;
    onIsReady?.();
    setReady(true);
  }, [router, onIsReady]);

  return ready;
}
