import type { CreateTeamReq } from '@parsec/kessel';

import { Button, styled } from '@parsec/components';
import { useGetMe } from '@parsec/queries';

import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';

import Actions from '../Actions';

import { BillingInfoForm } from './BillingInfoForm';
import { CouponData, Coupon } from './Coupon';
import { CustomerData, Customer } from './Customer';
import { NewCardData, NewCard } from './NewCard';

// STYLES
const BillingInfoSection = styled('div', {
  width: '56rem',
  paddingBottom: '2rem'
});

const StepTitle = styled('h2', {
  fontSize: '$title',
  lineHeight: '$title',
  textAlign: 'center',
  paddingTop: '$xxxlarge'
});

const Description = styled('p', {
  paddingTop: '$large',
  paddingBottom: '$large',
  fontSize: '$body'
});

const TrialDisclaimer = styled('p', {
  marginBottom: '$xlarge',
  color: '#f9fafc4c', //no token
  fontSize: '$info',
  lineHeight: '$info'
});

const CreateActions = styled(Actions, {
  width: '100%',
  justifyContent: 'right',
  marginTop: '$xxlarge'
});

// TYPES
type BillingInfoInput = CustomerData & NewCardData & CouponData;

interface BillingInfoStepProps {
  createTeamReq: CreateTeamReq;
  isTrial: boolean;
  onFormSubmit(data: BillingInfoInput): void;
  onBack(): void;
  onCouponApply(coupon: string): void;
  isCouponValid: boolean;
  couponErrorMsg?: string;
}

// COMPONENT
const BillingInfoStep = ({
  isTrial,
  createTeamReq,
  onBack,
  onFormSubmit,
  onCouponApply,
  isCouponValid,
  couponErrorMsg
}: BillingInfoStepProps) => {
  const email = useGetMe().data?.email;

  return (
    <BillingInfoSection>
      <StepTitle>Billing Information</StepTitle>
      <Description>
        We accept several forms of payment, such as credit cards or gold rings
        harvested by blue hedgehogs.
      </Description>
      <BillingInfoForm<BillingInfoInput>
        id={BILLING_INFO_FORM_IDS.NEW_SIGNUP_ID}
        onSubmit={onFormSubmit}
      >
        <Customer
          customer={{
            ...createTeamReq.customer,
            email:
              createTeamReq.customer.email === ''
                ? email
                : createTeamReq.customer.email,
            billing_address: {
              ...createTeamReq.customer.billing_address,
              line2: createTeamReq.customer.billing_address.line2,
              zip: createTeamReq.customer.billing_address.zip
            }
          }}
          validateName={(_, formValues) => {
            return Boolean(formValues.company_name) || 'Require company name';
          }}
          requireCompanyName
        />
        {isTrial && (
          <TrialDisclaimer>
            You will not be charged for the trial if you choose to cancel before
            the trial ends.
          </TrialDisclaimer>
        )}
        <NewCard />
        <Coupon
          apply={onCouponApply}
          defaultValue={createTeamReq.coupon}
          isValid={isCouponValid}
          errorMsg={couponErrorMsg}
        />
        <CreateActions>
          <Button level="secondary" type="button" onClick={onBack}>
            Go Back
          </Button>
          <Button type="submit" kind="success" id="submit_create_billing_info">
            View Summary
          </Button>
        </CreateActions>
      </BillingInfoForm>
    </BillingInfoSection>
  );
};

export default BillingInfoStep;
