//Components
import { Icon, styled, Button, Bread, Time } from '@parsec/components';
import { useWarpSubscriptionCost } from '@parsec/hooks';
import { WarpSubscription } from '@parsec/kessel';

import { WarpLogo } from 'components';
import { MARKETING_URL } from 'lib/config';

export default function YourWarp(props: {
  warpSub: WarpSubscription;
  showUpdateModal?: () => void;
  showResumeTrialModal?: () => void;
  showResumeSubscriptionModal?: () => void;
  showCCModal?: () => void;
  showUpdateBillingModal?: () => void;
  showUnsubscribeModal?: () => void;
  onError(errorMsg: string): void;
}) {
  const {
    warpSub,
    showUpdateModal,
    showResumeTrialModal,
    showResumeSubscriptionModal,
    showCCModal,
    showUnsubscribeModal,
    showUpdateBillingModal
  } = props;

  const {
    current_term_end,
    plan_amount_cents,
    status,
    billing_interval,
    trial_end,
    cancelled_at,
    trial_end_activation_enabled
  } = warpSub;

  const warpEstimate = useWarpSubscriptionCost();
  const totalWithCoupon = warpEstimate.total;

  const allowRenewal =
    status === 'in_trial' &&
    trial_end_activation_enabled &&
    Boolean(cancelled_at);

  const allowCancellation = !Boolean(cancelled_at);

  const renewalDate = current_term_end
    ? new Date(current_term_end)
    : trial_end
      ? new Date(trial_end)
      : undefined;

  const active =
    status === 'active' ||
    status === 'future' ||
    (status === 'in_trial' && !Boolean(cancelled_at));

  const inTrial = trial_end && status === 'in_trial';

  const allowEditing = status === 'in_trial' || billing_interval === 'month';

  return (
    <Column>
      <Wrapper>
        <WarpInfo>
          <div>
            {active ? (
              <Confirmation>
                You&apos;ve Got Warp
                <CheckedShield name="shield" />
              </Confirmation>
            ) : (
              <Confirmation> </Confirmation>
            )}

            <WarpLogo css={{ width: '13.4rem' }} />
          </div>
          <Body>
            {inTrial ? (
              <>We hope you are enjoying your Warp trial</>
            ) : (
              <>
                {' '}
                You have all the Warp perks for{' '}
                <strong>
                  <Bread>{totalWithCoupon ?? plan_amount_cents}</Bread>
                </strong>
                .
              </>
            )}

            <br />
            {!renewalDate ? null : !active ? (
              <>
                <p>
                  You&apos;ll keep all your Warp perks until{' '}
                  <strong>
                    <Time date={renewalDate} />,{' '}
                  </strong>
                  the end of your {inTrial ? 'trial' : 'current billing period'}
                  .
                </p>
                <p>
                  After that, you&apos;ll lose your Warp features, and you
                  won&apos;t be charged{!inTrial && ' again'}.
                </p>
              </>
            ) : !inTrial ? (
              <>
                You will be charged automatically on{' '}
                <strong>
                  <Time date={renewalDate} />.
                </strong>
              </>
            ) : (
              <>
                Your trial will automatically convert on{' '}
                <strong>
                  <Time date={renewalDate} />
                </strong>
                . You will be charged 
                <strong>
                  <Bread>{totalWithCoupon ?? plan_amount_cents}</Bread>
                </strong>{' '}
                upon trial conversion.
              </>
            )}
          </Body>
          <Actions>
            {active && allowEditing && (
              <WarpButton onClick={showUpdateModal} kind="brand">
                <Subscribe>
                  <Icon name="warp" />
                  {status === 'in_trial'
                    ? 'Edit Subscription'
                    : 'Switch to Annual'}
                </Subscribe>
              </WarpButton>
            )}
            <WarpButton
              leave
              level="secondary"
              onClick={
                active
                  ? showUnsubscribeModal
                  : allowRenewal && inTrial
                    ? showResumeTrialModal
                    : showResumeSubscriptionModal
              }
            >
              {inTrial && allowCancellation
                ? 'Cancel Trial'
                : active
                  ? 'Unsubscribe'
                  : allowRenewal && inTrial
                    ? 'Resume Trial'
                    : 'Resume'}
            </WarpButton>
            <WarpButton level="secondary" onClick={showCCModal}>
              Update Credit Card
            </WarpButton>

            <WarpButton level="secondary" onClick={showUpdateBillingModal}>
              Update Billing Info
            </WarpButton>
          </Actions>
        </WarpInfo>
        <ReadMore>
          Read more about <a href={`${MARKETING_URL}/warp`}>Warp</a>
        </ReadMore>
      </Wrapper>
    </Column>
  );
}

const WarpInfo = styled('div', {
  background: '$computerBlack',
  borderRadius: '$large',
  width: '30rem',
  gap: '2.5rem',
  display: 'grid',
  justifyItems: 'center',
  padding: '1.8rem $xxlarge',
  marginTop: '$xxxlarge'
});

const Confirmation = styled('div', {
  display: 'grid',
  fontSize: '$body',
  lineHeight: '$subtitle',
  color: '$consoleWhite',
  fontWeight: 'bold',
  alignItems: 'center',
  gap: '.8rem',
  gridAutoFlow: 'column',
  height: '3.2rem'
});

const CheckedShield = styled(Icon, {
  color: '$success500',
  display: 'grid',
  alignItems: 'center'
});

const Body = styled('div', {
  fontSize: '$info',
  lineHeight: '$info'
});

const WarpButton = styled(Button, {
  display: 'grid',
  justifyContent: 'center',

  variants: {
    leave: {
      true: {
        color: '$error500'
      }
    }
  }
});

const Subscribe = styled('div', {
  display: 'flex',
  gridAutoFlow: 'column',
  gap: '.7rem',
  alignItems: 'center'
});

const Actions = styled('div', {
  display: 'grid',
  gap: '1.6rem',
  width: '100%'
});

const Wrapper = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  width: '30rem'
});

const Column = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end'
});

const ReadMore = styled('p', {
  color: '$rhyhorn',
  lineHeight: '$info',
  fontSize: '1rem'
});
