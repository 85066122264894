import { ParsedUrlQuery } from 'querystring';

import { isOfTypeJourneyTrigger } from '@parsec/analytics';

/**
 * Helper to get journey_trigger value if matches journey_trigger type
 * @param savedUrlParams ParsedUrlQuery
 * @returns matching journey_trigger
 */
export const getJourneyTrigger = (
  savedUrlParams: ParsedUrlQuery | undefined
) =>
  savedUrlParams &&
  typeof savedUrlParams['journey'] === 'string' &&
  isOfTypeJourneyTrigger(savedUrlParams['journey'])
    ? savedUrlParams['journey']
    : undefined;
