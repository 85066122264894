/**
 * Those Ids are for signup flow pages without a form tag
 */
export const SIGNUP_CONFIRMATION_IDS = {
  MYSELF_PATH: 'form_signup_mewarppromo2_step3_confirmation',
  TEAM_PATH: 'form_signup_teamtrial2_step7_confirmation'
};

export const SELECT_PRODUCT_ID = 'form_signup_account2_step3_select_product';

export const EMAIL_VERIFIED_ID = 'form_signup_account2_step2_email_verified';
