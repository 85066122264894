import Link from 'next/link';

import { styled } from '@parsec/components';

import mySelfHand1x from './signup-hand-1-bw.png';
import mySelfHand2x from './signup-hand-1-bw@2x.png';
import mySelfHandColor1x from './signup-hand-1-color.png';
import mySelfHandColor2x from './signup-hand-1-color@2x.png';
import myTeamHand1x from './signup-hand-2-bw.png';
import myTeamHand2x from './signup-hand-2-bw@2x.png';
import myTeamHandColor1x from './signup-hand-2-color.png';
import myTeamHandColor2x from './signup-hand-2-color@2x.png';

// STYLES
const PathLinkAnchor = styled(Link, {
  height: '16.4rem',
  display: 'grid',
  alignItems: 'center',
  padding: '$xxlarge $xxxxlarge $xxlarge 18.1rem',
  color: '$consoleWhite',
  border: '1px solid $lynel',
  borderRadius: '1.6rem',
  backgroundColor: '$cereza',
  backgroundImage: `url(${mySelfHand1x.src})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top 2rem left 3rem',
  backgroundSize: '12.5rem',
  '&:focus, &:hover': {
    outline: 'none',
    backgroundColor: '$samehada',
    borderColor: '$primary500',
    backgroundImage: `url(${mySelfHandColor1x.src})`,
    '@media (-webkit-min-device-pixel-ratio: 2)': {
      backgroundImage: `url(${mySelfHandColor2x.src})`
    }
  },

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    backgroundImage: `url(${mySelfHand2x.src})`
  },

  variants: {
    isTeam: {
      true: {
        backgroundImage: `url(${myTeamHand1x.src})`,
        '@media (-webkit-min-device-pixel-ratio: 2)': {
          backgroundImage: `url(${myTeamHand2x.src})`
        },
        '&:focus, &:hover': {
          backgroundImage: `url(${myTeamHandColor1x.src})`,
          '@media (-webkit-min-device-pixel-ratio: 2)': {
            backgroundImage: `url(${myTeamHandColor2x.src})`
          }
        }
      }
    }
  }
});

const PathLinkContent = styled('div', {
  display: 'grid',
  rowGap: '0.4rem'
});

const PathLinkTitle = styled('h2', {
  fontSize: '$header',
  fontWeight: '$bold'
});

// COMPONENT
/**
 * PathLink base component
 */
export interface PathLinkProps {
  children: ReactNode;
  href: string;
  isTeam?: boolean;
  onClick(e: React.MouseEvent<HTMLAnchorElement>): void;
}

export default function PathLink(props: PathLinkProps) {
  const { children, href, isTeam = false, onClick } = props;

  return (
    <PathLinkAnchor
      href={href}
      target="_self"
      isTeam={isTeam}
      onClick={onClick}
    >
      {children}
    </PathLinkAnchor>
  );
}

/**
 * Content
 */
interface ContentProps {
  children: ReactNode;
}

const Content = (props: ContentProps) => {
  const { children } = props;
  return <PathLinkContent>{children}</PathLinkContent>;
};

// EXPORTS
PathLink.Content = Content;
PathLink.Title = PathLinkTitle;
