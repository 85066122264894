import { CreateWarpSubscriptionReq } from '@parsec/kessel/src/warpSubscription';

import { useEstimateWarpSubscription } from '@parsec/queries';

export default function useWarpPricingState(props: {
  req: CreateWarpSubscriptionReq;
}) {
  const { req } = props;

  const estimate = useEstimateWarpSubscription({
    coupon: req.coupon,
    plan_id: req.plan_id,
    country: req.customer?.billing_address.country,
    zip: req.customer.billing_address.zip
  });

  const unitPrice = estimate.data?.line_items[0].amount_cents ?? NaN;
  const subtotal = estimate.data?.subtotal_amount_cents ?? NaN;
  const discount = estimate.data?.discount_amount_cents ?? NaN;

  return {
    estimate,
    unitPrice,
    subtotal,
    discount
  };
}
