export const CREATE_HEADER_COPY = {
  title: {
    pricing: 'Choose your plan & payment',
    billing: 'Enter your Billing Info',
    confirmation: "You're almost there! Confirm your purchase"
  },
  description: 'Enhance the ways you game, work and whatever else.'
};
export const UPDATE_HEADER_COPY = {
  title: {
    pricing: 'Choose your plan & payment',
    billing: 'Confirm your Billing Info',
    confirmation: "You're almost there! Confirm your purchase"
  },
  description: 'Enhance the ways you game, work and whatever else.'
};

export type title = {
  pricing: string;
  billing: string;
  confirmation: string;
};
