import Image from 'next/image';

import { Button } from '@parsec/components';
import { ELOQUA_LINKS } from '@parsec/constants';

import { MARKETING_URL, TEAMS_URL } from 'lib/config';

import {
  Wrapper,
  FeaturesList,
  FeatureDescription,
  FeatureTitle,
  ListItem,
  Banner,
  BannerLink
} from './sharedStyles';

import groupsRoles from 'lib/images/confirmation/groups_and_roles@2x.png';
import inviteMembers from 'lib/images/confirmation/invite_members@2x.png';
import parsecLogo from 'lib/images/confirmation/parsec-logo@2x.png';
import setupMachines from 'lib/images/confirmation/setup_machines@2x.png';

// CONTAINER
interface TeamConfirmationProps {
  onCtaTeamsClick?(): void;
  onEnterpriseClick?(): void;
  onTutorialClick?(): void;
  onCtaDownloadClick?(): void;
}

const TeamConfirmation = ({
  onCtaTeamsClick,
  onEnterpriseClick,
  onTutorialClick,
  onCtaDownloadClick
}: TeamConfirmationProps) => {
  return (
    <Wrapper>
      <Button size="large" href={TEAMS_URL}>
        <span onClick={onCtaTeamsClick}>Manage Team</span>
      </Button>
      <FeaturesList>
        <ListItem>
          <Image
            src={setupMachines.src}
            alt=""
            width={65}
            height={62}
            priority={false}
          />
          <div>
            <FeatureTitle>Setup Team Machines</FeatureTitle>
            <FeatureDescription>
              First, bring your workstations into Parsec. Provision desired
              computers as shared Parsec Team Machines. Learn more.
            </FeatureDescription>
          </div>
        </ListItem>
        <ListItem>
          <Image
            src={groupsRoles.src}
            alt=""
            width={65}
            height={54}
            priority={false}
          />
          <div>
            <FeatureTitle>Add Groups & Roles</FeatureTitle>
            <FeatureDescription>
              Next, enable connections by defining groups, roles, and rules.
              Organize your team by department, function, or any way that works
              for you. Configure other settings to set up secure access.
            </FeatureDescription>
          </div>
        </ListItem>
        <ListItem>
          <Image
            src={inviteMembers.src}
            alt=""
            width={65}
            height={58}
            priority={false}
          />
          <div>
            <FeatureTitle>Invite Team Members</FeatureTitle>
            <FeatureDescription>
              Lastly, invite your team members and assign them to groups so they
              can begin making connections.
            </FeatureDescription>
          </div>
        </ListItem>
      </FeaturesList>
      <a href={ELOQUA_LINKS.CONTACT_US} target="_blank" rel="noreferrer">
        <span onClick={onEnterpriseClick}>
          Looking for Enterprise features?
        </span>
      </a>
      <Banner>
        <Image
          src={parsecLogo.src}
          alt="Parsec Logo"
          width={120}
          height={29}
          priority={false}
        />
        <BannerLink href={`${MARKETING_URL}/downloads`}>
          <span onClick={onCtaDownloadClick}>Download Parsec App</span>
        </BannerLink>
      </Banner>
      <a
        href="https://support.parsec.app/hc/en-us/categories/4420173134861-Parsec-for-Teams"
        target="_blank"
        rel="noreferrer"
      >
        <span onClick={onTutorialClick}>How do I use Parsec?</span>
      </a>
    </Wrapper>
  );
};

export default TeamConfirmation;
