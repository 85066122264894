// libraries
import type { ReactNode } from 'react';

// @parsec
import { styled } from '@parsec/components';

import { EmailVerification } from '../CreateTeam';
import BaseFooter from '../Footer';

import forestLakeDark1x from './forestLakeDark@1x.jpg';
import forestLakeDark2x from './forestLakeDark@2x.jpg';
import LogoSvg from './logo.svg';

interface Props {
  children: ReactNode;
  side?: 'left' | 'right';
  center?: boolean;
  showVerification?: boolean;
  refetchVerification?(): void;
}

export default function SsoDefaultLayout(props: Props) {
  const {
    children,
    side = 'left',
    center = false,
    showVerification,
    refetchVerification
  } = props;

  return (
    <Wrapper>
      <Main side={side} center={center}>
        {children}
        <Footer />
      </Main>
      <Aside>
        {showVerification && refetchVerification && (
          <Verification>
            <EmailVerification onRefetch={refetchVerification} />
          </Verification>
        )}
        <Logo />
      </Aside>
    </Wrapper>
  );
}

const Footer = styled(BaseFooter, { paddingTop: '2rem' });

const Wrapper = styled('div', {
  position: 'relative',
  minHeight: '100vh',
  display: 'grid',
  background: `url(${forestLakeDark1x.src}) right center`,
  backgroundSize: 'cover',
  gridTemplateRows: 'auto 1fr',
  '@medium': {
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr'
  },
  '@media (-webkit-min-device-pixel-ratio: 2)': {
    background: `url(${forestLakeDark2x.src}) right center`,
    backgroundSize: 'cover'
  }
});

const Main = styled('main', {
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$xxxlarge',
  backgroundColor: '$computerBlack',
  gridRow: 2,
  '@medium': {
    gridRow: 1
  },
  variants: {
    side: {
      left: { '@medium': { gridColumn: 1 } },
      right: { '@medium': { gridColumn: 2 } }
    },
    center: { true: { justifyContent: 'center' } }
  }
});

const Aside = styled('aside', {
  position: 'relative',
  minHeight: '9.6rem'
});

const Logo = styled(LogoSvg, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '4.8rem',
  '@medium': {
    height: 'auto'
  }
});

const Verification = styled('div', {
  display: 'none',
  '@medium': {
    display: 'grid',
    position: 'absolute',
    justifyContent: 'center',
    width: '100%'
  }
});
