export const ENV = process.env.NEXT_PUBLIC_ENV || '';
export const KESSEL_URL = process.env.NEXT_PUBLIC_KESSEL_URL || '';
export const DASH_URL = process.env.NEXT_PUBLIC_DASH_URL || '';
export const TEAMS_URL = process.env.NEXT_PUBLIC_TEAMS_URL || '';
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || '';
export const MARKETING_URL = process.env.NEXT_PUBLIC_MARKETING_URL || '';
export const DOWNTIME_URL = process.env.NEXT_PUBLIC_DOWNTIME_URL || '';
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH || '';
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW || '';
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY || '';
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
export const TURNSTILE_SITE_KEY =
  process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY || '';
export const shouldLoadStripe = ENV !== 'testing';
export const shouldLoadGtm = ENV !== 'development';

export const RELEASE = process.env.NEXT_PUBLIC;

export const PARSEC_FACEBOOK_ID =
  process.env.NEXT_PUBLIC_PARSEC_FACEBOOK_ID || '';

export const ONETRUST_ENABLED =
  process.env.NEXT_PUBLIC_ONETRUST_ENABLED === 'true' || false;
export const EXPERIMENT_DEPLOY_KEY = process.env
  .NEXT_PUBLIC_AMPLITUDE_DEPLOY_KEY
  ? process.env.NEXT_PUBLIC_AMPLITUDE_DEPLOY_KEY
  : '';
