import {
  useStripe,
  useElements,
  CardElement as StripeCardElement
} from '@stripe/react-stripe-js';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions
} from '@stripe/stripe-js';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FieldLabel as BaseFieldLabel,
  ErrorMessage as BaseErrorMessage,
  Input
} from '@parsec/components';
import { config, styled } from '@parsec/styles';

// Input captures the fields available in the NewCard component
export interface NewCardData {
  token: string;
}

export function NewCard() {
  const {
    control,
    register,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isSubmitting }
  } = useFormContext();

  const stripe = useStripe();
  const elements = useElements();
  const disabled = isSubmitting;

  const validateCard = async () => {
    const card = elements?.getElement(StripeCardElement);
    if (!stripe || !card) throw new Error("Couldn't load Stripe.");

    const { token, error } = await stripe.createToken(card);
    if (error) {
      setError('card', {
        type: 'custom',
        message: error.message ?? 'Invalid card.'
      });
      return;
    } else if (!token) {
      setError('card', {
        type: 'custom',
        message: 'Could not save token.'
      });
      return;
    }

    // Success!
    clearErrors('card');
    setValue('token', token.id, { shouldValidate: true });
  };

  const onChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      // Card input is not complete / valid
      setError('card', {
        type: 'custom',
        message: event.error.message
      });
      setValue('token', '');
      return;
    }
    validateCard();
  };

  const renderErrMsg = (): JSX.Element | null => {
    if (errors.card) {
      return <ErrorMessage>{errors.card.message?.toString()}</ErrorMessage>;
    } else if (errors.token) {
      return <ErrorMessage>{errors.token.message?.toString()}</ErrorMessage>;
    } else {
      return null;
    }
  };

  const cardCntrlOptions: StripeCardElementOptions = {
    disabled,
    iconStyle: 'solid',
    style: {
      base: {
        lineHeight: '36px',
        fontFamily: 'Source Sans Pro',
        color: config.theme.colors.consoleWhite.value,
        ':disabled': {
          color: config.theme.colors.nice.value,
          backgroundColor: config.theme.colors.duskull.value
        },

        '::placeholder': {
          color: config.theme.colors.rhyhorn.value
        }
      }
    }
  };

  return (
    <InputWrapper>
      <StyledP>Payment Method</StyledP>
      <FieldLabel>
        <BaseFieldLabel.Label label="Card" required>
          {/* Need to use a hidden input since the wrapped controller does not have access to any card details See: https://github.com/stripe/react-stripe-js/issues/171#issuecomment-897564423 */}
          <Input
            {...register('token', { required: 'Valid card is required.' })}
            type="hidden"
          ></Input>
          <Controller
            name="card"
            control={control}
            render={() => (
              <CardElement
                disabled={disabled}
                options={cardCntrlOptions}
                onChange={onChange}
              />
            )}
          />
        </BaseFieldLabel.Label>
      </FieldLabel>
      {renderErrMsg()}
    </InputWrapper>
  );
}

const InputWrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto 3rem'
});

const CardElement = styled(StripeCardElement, {
  width: '100%',
  height: '3.6rem',
  backgroundColor: '$cereza',
  borderRadius: '$medium',
  transition: '125ms box-shadow ease',
  boxShadow: '0rem $space$xxsmall 0 rgba(255, 255, 255, 0.1)',
  padding: '0 1rem',
  color: '$consoleWhite',
  '& ~ svg': {
    color: '$nice'
  },
  '&::placeholder': {
    color: '$rhyhorn'
  },

  '&[type="number"]': {
    MozAppearance: 'textfield'
  },

  '&:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },
  '&:-webkit-autofill': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },

  '&:disabled': {
    color: '$nice',
    backgroundColor: '$duskull',
    '&::placeholder': {
      color: '$nice'
    },
    '& ~ svg': {
      color: '$nice'
    }
  },

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },

  variants: {
    disabled: {
      true: {
        color: '$nice',
        backgroundColor: '$duskull',
        '&::placeholder': {
          color: '$nice'
        },
        '& ~ svg': {
          color: '$nice'
        }
      },
      false: {}
    }
  },

  '&.StripeElement--hover': {
    boxShadow:
      'inset 0 0 0 .1rem $colors$pangoro, 0px .1rem 0px rgba(255,255,255,0.1)'
  },
  '&.StripeElement--focus': {
    boxShadow: 'inset 0 0 0 .2rem $colors$primary500',
    color: '$consoleWhite',
    border: 'none',
    outlineStyle: 'none',
    '& ~ svg': {
      color: '$consoleWhite '
    }
  },
  '&.StripeElement--invalid': {
    boxShadow: 'inset 0 0 0 $space$xmall $colors$error500'
  }
});

const ErrorMessage = styled(BaseErrorMessage, {
  marginBottom: '$small',
  fontSize: '$info'
});

const StyledP = styled('p', {
  fontWeight: '$bold',
  fontSize: '$body'
});

const FieldLabel = styled(BaseFieldLabel, {
  paddingTop: '$xlarge',
  rowGap: '$none'
});
