import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, teamRelay, teamRelayList, teamRelaySecret } from './schema';

/******************************************************************************
 * Get a list of team relays.
 ******************************************************************************/
export interface GetTeamRelaysReq {
  team_id: string;
}

export const getTeamRelays =
  (options: ApiOptions) => (req: GetTeamRelaysReq) => {
    const { team_id } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/relays`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(teamRelayList)
    });
  };

/******************************************************************************
 * Creates a team relay and retrieves the relay secret.
 ******************************************************************************/
export interface CreateTeamRelayReq {
  team_id: string;
  name: string;
}

export const createTeamRelay =
  (options: ApiOptions) => (req: CreateTeamRelayReq) => {
    const { team_id, name = '' } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/relays`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(teamRelaySecret),
      body: { name }
    });
  };

/******************************************************************************
 * Updates a team relay with a new name.
 ******************************************************************************/
export interface RenameTeamRelayReq {
  team_id: string;
  relay_id: string;
  name: string;
}

export const renameTeamRelay =
  (options: ApiOptions) => (req: RenameTeamRelayReq) => {
    const { team_id, relay_id, name } = req;
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/relays/${relay_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(teamRelay),
      body: { name }
    });
  };

/******************************************************************************
 * Deletes a team relay.
 ******************************************************************************/
export interface DeleteTeamRelayReq {
  team_id: string;
  relay_id: string;
}

export const deleteTeamRelay =
  (options: ApiOptions) => (req: DeleteTeamRelayReq) => {
    const { team_id, relay_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/relays/${relay_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
