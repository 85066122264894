import { styled } from '@parsec/components';

import { MARKETING_URL } from 'lib/config';

// STYLE
const Disclaimer = styled('p', {
  textAlign: 'center',
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  paddingTop: '2rem'
});

export default function SignUpDisclaimer() {
  return (
    <Disclaimer>
      Creating an account means you&apos;re okay with our{' '}
      <a href={`${MARKETING_URL}/terms`}>Terms of Service</a> and{' '}
      <a href={`${MARKETING_URL}/privacy`}>Privacy Policy</a>.
    </Disclaimer>
  );
}
